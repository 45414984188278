import Grid from "@mui/material/Unstable_Grid2";
import {TrackVersionInfo} from "./TrackVersionInfo";
import {AppearsOn} from "./AppearsOn";
import {BasePageTemplate} from "../../../components/BasePageTemplate";

export function CombinedTrackVersionInfoAndAppearsOn({data}) {
    const trackVersionComp = <TrackVersionInfo data={data} />
    const appearsOnComp = <AppearsOn data={data.products}/>


    return (<>
            <BasePageTemplate contentComponent={[{comp: trackVersionComp, size: 4}, {comp: appearsOnComp, size: 8}]}/>
        </>
    )


}