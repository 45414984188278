import { MenuDropdown } from "../MenuDropdown";

const menuOptions = [
  { optionName: "Writers", link: "/writers" },
  { optionName: "Songs", link: "/songs" },
  // WIll add more options here
];

export function PublishingDropdown({ menuHandler, menuAnchor, handleClose }) {
  return (
    <MenuDropdown
      menuName="Publishing"
      menuOptions={menuOptions}
      menuHandler={menuHandler}
      menuAnchor={menuAnchor}
      handleClose={handleClose}
    />
  );
}
