import React, { useState } from 'react';
import {
    TextField,
    Button,
    Box,
    MenuItem,
    Grid,
    Chip,
    Stack
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export function FilterMenu({ filterSchema, onApplyFilters, initialFilters }) {
    const [filters, setFilters] = useState(initialFilters);

    const handleFilterChange = (name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    };

    const handleClear = () => {
        onApplyFilters({});
        setFilters({});
    };

    const handleSubmit = () => {
        const cleanedFilters = Object.fromEntries(
            Object.entries(filters).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
        );
        onApplyFilters(cleanedFilters);
    };

    const handleKeyDown = (event) => {
        event.stopPropagation();
    };

    const handleAddName = (name, newValue) => {
        if (newValue.trim() === '') return;
        setFilters((prevFilters) => {
            const updatedValue = prevFilters[name] ? `${prevFilters[name]},${newValue.trim()}` : newValue.trim();
            return {
                ...prevFilters,
                [name]: updatedValue
            };
        });
    };

    const handleDeleteName = (name, index) => {
        setFilters((prevFilters) => {
            const namesArray = prevFilters[name].split(',').filter((_, i) => i !== index);
            return {
                ...prevFilters,
                [name]: namesArray.join(',')
            };
        });
    };

    const renderFilterInput = (filter) => {
        switch (filter.type) {
            case 'text':
                return (
                    <TextField
                        label={filter.label}
                        variant="outlined"
                        name={filter.name}
                        value={filters[filter.name] || ''}
                        onChange={(e) => handleFilterChange(filter.name, e.target.value)}
                        onKeyDown={handleKeyDown}
                        fullWidth
                    />
                );
            case 'dropdown':
                return (
                    <TextField
                        select
                        label={filter.label}
                        value={filters[filter.name] || ''}
                        onChange={(e) => handleFilterChange(filter.name, e.target.value)}
                        onKeyDown={handleKeyDown}
                        fullWidth
                    >
                        {filter.options.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            case 'list':
                return (
                    <Box>
                        <TextField
                            label={filter.label}
                            variant="outlined"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleAddName(filter.name, e.target.value);
                                    e.target.value = '';
                                }
                            }}
                            fullWidth
                        />
                        <Stack direction="row" flexWrap="wrap" gap={1} mt={1}>
                            {filters[filter.name]?.split(',').map((name, index) => (
                                <Chip
                                    key={index}
                                    label={name}
                                    onDelete={() => handleDeleteName(filter.name, index)}
                                    deleteIcon={<DeleteIcon />}
                                />
                            ))}
                        </Stack>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{ height: '100%', padding: '1%' }}>
            <h1>Filters</h1>
            <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', height: '100%' }}>
                <Grid container spacing={2}>
                    {filterSchema.map((filter) => (
                        <Grid item xs={12} key={filter.name}>
                            {renderFilterInput(filter)}
                        </Grid>
                    ))}
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={handleSubmit} color="primary">
                           Search
                        </Button>
                        <Button onClick={handleClear} color="warning">
                            Clear
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
