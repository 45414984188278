// src/components/Context/NavigationContext.js
import React, { createContext, useState } from 'react';

export const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
    const [viewState, setViewState] = useState({});

    const saveViewState = (viewName, state) => {
        setViewState(prevState => ({ ...prevState, [viewName]: state }));
    };

    const getViewState = (viewName, defaultValue = {}) => {
        return viewState[viewName] || defaultValue;
    };

    return (
        <NavigationContext.Provider value={{ saveViewState, getViewState }}>
            {children}
        </NavigationContext.Provider>
    );
};
