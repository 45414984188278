import {Box} from "@mui/material";

export const ItemCardWrapper = ({component}) => {


    return (<Box minHeight={'86vh'} width={'20vw'} justifySelf={'start'}>
            {component}
        </Box>
    )

}

