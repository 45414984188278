import {useState} from "react";

export function useCreate(service) {

    const [status, setStatus] = useState({loading: false, error: null, success: false});

    const create = async (newItem) => {
        setStatus({loading: true, error: null, success: false});
        try {
            const response = await service.create(newItem);
            if (response.status === 201) {
                setStatus({loading: false, error: null, success: true});
            } else {
                throw new Error(`Failed to create item: ${response.status}`);
            }
        } catch (error) {
            setStatus({loading: false, error: error.toString(), success: false});
        }
    };

    return {...status, create};


}