import {parseISO, format} from 'date-fns';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {Controller} from "react-hook-form";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import enGB from 'date-fns/locale/en-GB';

export function DateInputField({name, label, control, defaultValue = null, required = false}) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue ? parseISO(defaultValue) : null}
                render={({field: {ref, onChange, value, ...rest}}) => (
                    <DatePicker
                        label={label}
                        value={value ? parseISO(value) : null}
                        onChange={(newValue) => {
                            onChange(format(new Date(newValue), 'yyyy-MM-dd')); // Format date for backend
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                required={required}
                                ref={ref}
                                // This ensures the displayed date format in dd/MM/yyyy
                                value={value ? format(parseISO(value), 'dd/MM/yyyy', {locale: enGB}) : ''}
                            />
                        )}
                    />
                )}
            />
</LocalizationProvider>
)
    ;
}