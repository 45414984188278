import {TracksService} from "../../Services/TracksService";
import {useState} from "react";

export function useDeleteTrack() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const deleteTrack = async (id) => {
        setLoading(true);
        setError("");
        try {
            const responseData = await TracksService.delete(id);
            if (responseData.status === 202) {
                console.log("Response Data:", responseData);
                setSuccess(true);
            } else {
                console.log("Response Data:", responseData);
                setError(responseData.message || "An unexpected error occurred");
            }

        } catch (error) {
            console.log("ERROR during fetch:", error);
            setError(error.message || "An unexpected error occurred");
            throw error;
        } finally {
            setLoading(false);
        }
    };
    return {
        loading, error, success, deleteTrack
    }
};
