import { ArtistService } from "../../Services/ArtistService";
import { useState } from "react";

export function useDeleteArtist() {
    const [status, setStatus] = useState({ loading: false, error: null, success: false });

    const deleteArtist = async (id) => {
        setStatus({ loading: true, error: null, success: false });
        try {
            const responseData = await ArtistService.delete(id);
            if (responseData.status === 202) {
                console.log("Response Data:", responseData);
                setStatus({ loading: false, error: null, success: true });
            } else {
                console.log("Response Data:", responseData);
                throw new Error(responseData.message || "An unexpected error occurred");
            }
        } catch (error) {
            console.log("ERROR during fetch:", error);
            setStatus({ loading: false, error: error.toString(), success: false });
            throw error;
        }
    };

    return { ...status, deleteArtist };
}
