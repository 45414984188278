import {InfoPage} from "../../../ProductData";
import React from "react";
import {Container} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

export function TrackInfo({data, schema}) {

    return (<>

        <InfoPage data={data}
                  schema={schema}
                  pageType={'Track'}/>


    </>)

}
