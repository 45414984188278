import { useReleases } from "../../../Hooks/ReleaseHooks/useReleases";
import InfoCard from "../../../components/InfoCard";
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import AlbumIcon from '@mui/icons-material/Album';
import Person from '@mui/icons-material/Person';
import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { GridPage } from "../../../components/GridPage";
import React, { useContext, useEffect, useState } from "react";
import { ItemCount } from "../../../components/ItemCount";
import { FilterMenu } from "../../../components/FilterMenu";
import { ActionPanel } from "../../../components/ActionPanel";
import { ReleaseForm } from "../../../components/Forms/ReleaseForm";
import { useModal } from "../../../components/Context/ModalContext";
import { BasePageTemplate } from "../../../components/BasePageTemplate";
import { NavigationContext } from "../../../components/Context/NavigationContext";

export function Releases({ artistId }) {
    const { saveViewState, getViewState } = useContext(NavigationContext);
    const initialPagination = getViewState('releasesPagination') || { limit: 10, offset: 0 };

    const [limit, setLimit] = useState(initialPagination.limit);
    const [offset, setOffset] = useState(initialPagination.offset);
    const [filters, setFilters] = useState({});
    const params = { artistId: artistId, limit: limit, offset: offset, ...filters };

    const { openModal, closeModal } = useModal();
    const { releases, recordCount, loading, error, refetchReleases, updateParams } = useReleases(params);

    const imageKey = "image_url";
    const primaryTextKey = "name";
    const secondaryTextKey = "release_type";
    const releaseTypes = ["Single", "Album", "EP", "Compilation", "Live", "Remix", "Mixtape", "Demo", "Other"];

    const filterSchema = [
        ...(!artistId ? [
            { name: 'artistName', label: 'Artist Name', type: 'text' }
        ] : []),
        { name: 'releaseType', label: 'Release Type', type: 'dropdown', options: releaseTypes },
        { name: 'releaseName', label: 'Release Name', type: 'text' }
    ];

    const schema = {
        imageKey: imageKey,
        primaryTextKey: primaryTextKey,
        primaryIcon: <LibraryMusicIcon sx={{ fontSize: "small" }} />,
        secondaryTextKey: secondaryTextKey,
        secondaryIcon: <AlbumIcon sx={{ fontSize: "small" }} />,
        additionalTextKey: "artist_name",
        additionalIcon: <Person sx={{ fontSize: "small" }} />,
        linkKey: "release_id",
        endpoint: "releases",
    };

    const handleCreateRelease = () => {
        openModal(<ReleaseForm mode="create" closeModal={closeModal} artistId={artistId} />);
    }

    const handleParamsChange = (newParams) => {
        setOffset(newParams.offset);
        setLimit(newParams.limit);
        saveViewState('releasesPagination', { limit: newParams.limit, offset: newParams.offset });
    };

    useEffect(() => {
        updateParams({ artistId: artistId, limit: limit, offset: offset, ...filters });
    }, [limit, offset, filters]);

    const itemCountComp = (
        <ItemCount
            itemCount={recordCount}
            entityType={'releases'}
            background={'lightgray'}
            icon={<AlbumIcon fontSize={'large'} sx={{ color: 'white' }} />}
        />
    );

    const actionPanelComp = (
        <ActionPanel
            handleCreate={handleCreateRelease}
            itemName={'Release'}
        />
    );

    const filterComp = (
        <FilterMenu
            onApplyFilters={setFilters}
            initialFilters={filters}
            filterSchema={filterSchema}
        />
    );

    const releasePage = (
        <GridPage
            schema={schema}
            title={'Releases'}
            data={releases}
            recordCount={recordCount}
            loading={loading}
            error={error}
            handleParamsChange={handleParamsChange}
            initialOffset={offset}
            initialLimit={limit}
        />
    );

    return (
        <>
            <BasePageTemplate
                itemCountComp={itemCountComp}
                actionPanelComp={actionPanelComp}
                filterComp={filterComp}
                contentComponent={[{ comp: releasePage, size: 9 }]}
            />
        </>
    );
}
