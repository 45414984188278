import Grid from "@mui/material/Unstable_Grid2";
import {Controller} from "react-hook-form";
import {TextField} from "@mui/material";

export function SingleInputField({name, label, control, disabled = false, defaultValue = null, required = false}) {


    return (
        <Controller
            name={name}
            // defaultValue={defaultValue}
            disabled={disabled}
            control={control}
            render={({field}) => <TextField {...field}
                                            required={required}
                                            label={label}
                                            variant="outlined"
                                            fullWidth
                                            disabled={disabled}/>}

        />

    )
}

