import {useState, useEffect} from "react";
import {AssetService} from "../../Services/AssetService";

export function useAssets(id) {
    const [data, setData] = useState({
        assets: [],
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const formatResponse = (response) => {
        return {
            assets: response.assets,
        };
    };


    const fetchAssets = async (id) => {
        setLoading(true);
        setError("");
        // await setDelay(1000);

        try {
            const response = await AssetService.getAll(id);

            setData(formatResponse(response.data));
        } catch (error) {
            setError(error.message || "An unexpected error occurred");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAssets(id);
    }, [id]);
    //
    const refetchAssets = (id) => {
        fetchAssets(id)
    };
    //
    // const updateParams = (newParams = {}) => {
    //     setParams(() => ({...newParams}));
    // };

    return {data, loading, error, refetchAssets}
}
