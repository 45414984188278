import React from 'react';
import Paper from '@mui/material/Paper';
// import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import {Box, Divider, Stack, TableCell} from "@mui/material";
import TableCellRenderer from "./components/TableCellRenderer";


export function InfoPage({data, schema, pageType}) {


    return (


        <>
            <Paper elevation={3} sx={{p:'1vw', borderRadius: '16px'}}>

                <Box alignSelf={'flex-start'} p={'%'} maxWidth={'100%'}>
                    <h1 style={{padding: '0%', margin: 0}}>{pageType} Information</h1>
                    <Divider flexItem sx={{marginRight: '30%'}}/>
                </Box>

                <Grid container spacing={2} pt={1}>
                    {schema.map((item, index) => (
                        <Grid xs={6} key={index} display={'flex'} flexDirection={'column'}>
                            <Stack direction={'column'} spacing={1} ml={'%'}>
                                <Typography variant="subtitle1" component="h3"
                                            sx={{
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                fontFamily: 'var(--header-font)'
                                            }}>
                                    {item.label}
                                </Typography>
                                {<TableCellRenderer value={data[item.field]}
                                                    column={item}/>}
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Paper>

        </>
    )


}