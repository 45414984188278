export async function handleItemUpdate(id, data, patchFn, openSnackbar, closeModal) {
    try {
        await patchFn(id, data);

        openSnackbar('Updated successfully');
        closeModal();

    } catch (error) {
        openSnackbar('Error updating release.')
    }
}


export async function handleItemCreate(data, createFn, openSnackbar, closeModal ) {


    try {
        const result = await createFn(data);
        console.log('ixn crud handler', result)
        if (result) {
            openSnackbar('Created successfully');
            closeModal();
        } else {
            openSnackbar(`Error creating release:`);
        }
    } catch (error) {
        if (error.error === 'Item already exists in the database') {
            openSnackbar('Error: Release already exists in the database.');
        } else {
            console.log(error)

            openSnackbar('Error creating release.');
        }
    }
}

