import {useState} from "react";
import {ReleaseService} from "../../Services/ReleaseService";

export function useCreateRelease() {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [response, setResponse] = useState(null);

    const createRelease = async (data) => {
        setLoading(true);
        setErrorMessage("");
        setSuccess(false);
        setResponse(null);
        try {
            const response = await ReleaseService.create(data)
            setLoading(false);
            setSuccess(true);
            setResponse(response.data);
            return response.data;
        } catch
            (error) {
            setLoading(false);
            const message = error.response ?? 'An unexpected error occurred';
            setErrorMessage(message);
            throw error;
        }
    }

    return {loading, errorMessage, success, createRelease, response};

}
