import { createContext, useContext, useState, useRef, useEffect } from "react";

const AudioContext = createContext();

export const useAudio = () => {
    return useContext(AudioContext);
};

export const AudioProvider = ({ children }) => {
    const audioRef = useRef(new Audio());
    const [isPlaying, setIsPlaying] = useState(false);
    const [isrc, setIsrc] = useState("");
    const [audioUrl, setAudioUrl] = useState("");

    const toggleAudio = (url = null, newIsrc= "") => {
        if (url && url !== audioUrl) {
            audioRef.current.src = url;
            setAudioUrl(url);
            setIsrc(newIsrc);
            audioRef.current.play();
            setIsPlaying(true);
        } else if (isPlaying) {
            audioRef.current.pause();
            setIsPlaying(false);
        } else {
            audioRef.current.play();
            setIsPlaying(true);
        }
    };

    useEffect(() => {
        const handlePlay = () => setIsPlaying(true);
        const handlePause = () => setIsPlaying(false);
        const handleEnded = () => setIsPlaying(false);

        const audio = audioRef.current;
        audio.addEventListener('play', handlePlay);
        audio.addEventListener('pause', handlePause);
        audio.addEventListener('ended', handleEnded);

        return () => {
            audio.removeEventListener('play', handlePlay);
            audio.removeEventListener('pause', handlePause);
            audio.removeEventListener('ended', handleEnded);
        };
    }, []);

    return (
        <AudioContext.Provider value={{ isPlaying, isrc, toggleAudio, audioUrl }}>
            {children}
        </AudioContext.Provider>
    );
};
