export const buildFileTree = (assets) => {
    const root = {};

    assets.forEach(asset => {
        const parts = asset.blob_name.split('/');
        let current = root;


        parts.forEach((part, index) => {
            if (index > 1) {
                if (!current[part]) {
                    current[part] = index === parts.length - 1 ? {_isFile: true, blob: asset.blob_name} : {};
                }
                current = current[part];
            }
        });

    });

    return root;
};
