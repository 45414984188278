import {request} from "../components/Handlers/RequestHandler";
import axios from "axios";

const postHeaders = {
    "Content-Type": "multipart/form-data"
};

export const UploadService = {
    create(id, asset) {
        return request("POST", `/upload/${id}`, asset, null, postHeaders);
    },

    getSignedUrl(blob) {
        return request("GET", `/upload/signed-url`, null, {blob: blob}, null);
    },




    // update(id, updatedTrack) {
    //     return request(
    //         "PUT",
    //         `/trackversions/${id}`,
    //         updatedTrack,
    //         null,
    //         postHeaders,
    //     );
    // },
    //
    // delete(id, params) {
    //     return request("DELETE", `/trackversions/${id}`, null, params);
    // },
};
