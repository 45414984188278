import { useState } from "react";
import { ReleaseService } from "../../Services/ReleaseService";

export function usePatchRelease() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const patchRelease = async (id, data) => {
    setLoading(true);
    setError("");
    try {
      const responseData = await ReleaseService.update(id, data);
      console.log("Response Data:", data);
      setSuccess(true);
    } catch (error) {
      console.log("ERROR during fetch:", error);
      setError(error.message || "An unexpected error occurred");
      throw error;
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, success, patchRelease };
}
