import {Skeleton, Stack} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

export function LoadingGrid() {


    return (

        <Grid container spacing={3}>
            {Array.from(new Array(12)).map((_, index) => (
                <Grid xs={3} md={2}>
                    <Skeleton variant="rounded" width={'5vh'} height={'5vh'}/>
                </Grid>
            ))}
        </Grid>


    )

};

