import {Releases} from "../Releases/Releases";
import {Tracks} from "../Tracks/Tracks";
import {Artists} from "../Artists/Artists";
import {Products} from "../Products/Products";
import {Box, Container, Typography} from "@mui/material";
import {PageTabs} from "../../../components/Tabs/PageTabs";
import {MastersHome} from "./Home";


export function ReleasesPage() {

    const tabs = [{label: 'Home', component: MastersHome, link: '/masters'}, {
        label: 'Artists',
        component: Artists,
        link: '/masters/artists'
    }, {
        label: 'Releases',
        component: Releases
    }, {label: 'Products', component: Products, link: '/masters/products'}, {
        label: 'Tracks',
        component: Tracks,
        link: '/masters/tracks'
    }];


    return (
        <Container maxWidth={'100%'}>
            {/*<Box className={'hspace'}>*/}
            {/*    <Typography variant="h1" sx={{marginTop: "1%", marginBottom: "0%"}}>Releases</Typography>*/}
            {/*</Box>*/}
            <Box marginTop={'1%'}/>

            <PageTabs tabs={tabs} defaultTab={2}/>
        </Container>
    )


}