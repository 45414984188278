import {ItemCard} from "../../../components/ItemCard";
import {useArtist} from "../../../Hooks/ArtistHooks/useArtist"
import {Box, Container, Divider, Unstable_Grid2 as Grid} from "@mui/material";
import {Releases} from "../Releases/Releases";
import {PageTabs} from "../../../components/Tabs/PageTabs";
import {Tracks} from "../Tracks/Tracks";
import {useParams} from "react-router-dom";
import {Products} from "../Products/Products";
import {ArtistForm } from "../../../components/Forms/ArtistForm";
import {useModal} from "../../../components/Context/ModalContext";
import {useSnackbar} from "../../../components/Context/SnackbarContext";
import {useDeleteArtist} from "../../../Hooks/ArtistHooks/useDeleteArtist";
import {useDelete} from "../../../components/DeleteHandler";
import {useEffect, useState} from "react";
import {Assets} from "../Assets/Assets";
import {ItemCardWrapper} from "../../../components/ItemCardWrapper";
import {Products_2} from "../Products/Products_2";
import {Tracks_2} from "../Tracks/Tracks_2";


export function Artist() {
    const {openModal, closeModal} = useModal();
    const {artistId} = useParams();
    const {data, loading, error} = useArtist(artistId);
    const [liveData, setLiveData] = useState([]);
    const {openSnackbar, closeSnackbar} = useSnackbar();
    const {loading: deleteStatus, error: deleteError, success: deleteSuccess, deleteArtist} = useDeleteArtist();
    const {handleDelete} = useDelete();
    const [assets, setAssets] = useState([]);


    useEffect(() => {
        setLiveData(data);
        setAssets(data.assets)

    }, [data]);


    const schema = {
        headerKey: 'name',
        imageKey: 'image_url',
        idKey: 'artist_id',
        data: [

            {label: 'Artist Id', field: 'artist_id'},
            {label: 'Created Date', field: 'created_datetime', type: 'date'},
            {label: 'Last Modified Date', field: 'last_modified_datetime', type: 'date'}]
    };
    const chips = [
        {label: 'Artist', color: '--var(light-orange)', from: 'literal'},
        // {label: 'description', color: '--var(light-blue)', from: 'data'}
    ];


    const pages = [{label: 'Releases', component: Releases, params: {artistId: artistId}}, {
        label: 'Products',
        // component: Products,
        component:Products_2,
        params: {artistId: artistId}

    }, {
        label: 'Tracks',
        component: Tracks_2
    }, {label: 'Assets', component: Assets}];

    const handleEdit = () => {
        openModal(<ArtistForm mode="edit"
                              artistId={artistId}
                              handleUpdate={setLiveData}
                              currentData={liveData}/>);
    };

    const kebabOptions = [{
        label: 'Edit', action: handleEdit
    }, {
        label: 'Delete', action: () => handleDelete(artistId, deleteArtist)
    }];

    const comp = <ItemCard data={liveData}
                           schema={schema}
                           chips={chips}
                           loading={loading}
                           kebabOptions={kebabOptions}/>

    return (

        <>
            <Container maxWidth={'100%'}  sx={{
                width:'100% !important',
                display: 'flex',
                flexDirection: 'row',
                mt: "1%",
                mb: '1',
                height: '100%',
                justifyContent: 'flex-start'
            }}>
                <ItemCardWrapper component={comp}/>

                <Box height={'100%'} width={'75vw'} marginLeft={'2%'} justifyContent={'space-between'}>
                    <PageTabs tabs={pages}/>
                </Box>
            </Container>


        </>

    )
        ;

}