import { useState } from "react";
import { ProductService } from "../../Services/ProductService";

export function useAddProduct() {
    const [status, setStatus] = useState({ loading: false, error: null, success: false });

    const addProduct = async (newProduct) => {
        setStatus({ loading: true, error: null, success: false });
        try {
            const response = await ProductService.create(newProduct);
            if (response.status === 201) {
                setStatus({ loading: false, error: null, success: true });
            } else {
                throw new Error(`Failed to create product: ${response.status}`);
            }
        } catch (error) {
            setStatus({ loading: false, error: error.toString(), success: false });
        }
    };

    return { ...status, addProduct };
}
