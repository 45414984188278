import React, {useState} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper,
    IconButton,
    Collapse,
    Box,
    Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TableCellRenderer from "../TableCellRenderer";

const CollapsibleRow = ({
                            row,
                            nestedRowName,
                            nestedRowHeaders,
                            rowSchema,
                            isNested,
                            extraDataHeader,
                        }) => {
    const [open, setOpen] = useState(false);
    const totalColumns =
        rowSchema.length + (isNested ? nestedRowHeaders.length : 0) + 1;

    return (
        <>
            <TableRow hover>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <ShowRow row={row} schema={rowSchema}/>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{paddingBottom: 0, paddingTop: 0}}
                    colSpan={totalColumns}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {/*<Box ml={'3vw'} mr={'3vw'} mt={'1%'}>*/}
                        <Box>
                            <Typography variant="h6" gutterBottom component="div">
                                {extraDataHeader}
                            </Typography>

                            {/*<h3>Track Versions</h3>*/}
                            <Table size="small" aria-label="purchases" sx={{width: '100%', borderRadius: '16px'}}>
                                <>
                                    <GenerateTableHeaders
                                        headers={nestedRowHeaders}
                                        isCollapse={false}
                                        sx={{alignItems:'flex-end'}}
                                    />

                                    {isNested && (
                                        <TableBody>
                                            {row[nestedRowName] &&
                                                row[nestedRowName].map((nestedRow, index) => (
                                                    <TableRow key={row}>
                                                        <ShowRow
                                                            row={nestedRow}
                                                            schema={nestedRowHeaders}
                                                        />
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    )}
                                    {!isNested && (
                                        <TableRow>
                                            <ShowRow row={row} schema={nestedRowHeaders}/>{" "}
                                        </TableRow>
                                    )}
                                </>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

function ShowRow({row, schema}) {
    return (
        <>
            {schema.map((c) => (
                <TableCell key={c.columnName}
                           size={'small'} sx={{lineHeight: 1, maxWidth: '6vw', overflow:'ellipsis'}}>
                    {<TableCellRenderer column={c}
                                        value={row[c.columnName]}
                                        row={row}/>}
                </TableCell>
            ))}
        </>
    );
}

function GenerateTableHeaders({headers, isCollapse, sx}) {
    return (
        <TableHead
            sx={{
                ...sx,
                "& th": {
                    fontWeight: "bold",
                    color: "white",
                    height: "3vh",
                    alignItems: "center",
                    backgroundColor:'black'
                },
                "& thead": {

                    minHeight: "40px",
                }
            }}
        >
            <TableRow>
                {isCollapse && <TableCell/>}
                {headers.map((h) => (
                    <TableCell key={h.id}> {h.columnLabel}</TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const CollapsibleTable = ({
                              data,
                              nestedRowName,
                              schema,
                              isNested,
                              extraDataHeader,
                          }) => {
    const primarySchema = schema
        .filter((item) => item.primary)
        .map((item) => ({
            columnName: item.id,
            columnLabel: item.label,
            type: item.type,
            linkEndpoint: item.linkEndpoint,
            linkValue: item.linkValue,
        }));

    const collapsibleRowSchema = schema
        .filter((item) => item.secondary)
        .map((item) => ({
            columnName: item.id,
            columnLabel: item.label,
            type: item.type,
            linkEndpoint: item.linkEndpoint,
            linkValue: item.linkValue,
        }));

    if (!data) return;

    return (

        <TableContainer sx={{borderRadius: '16px'}}>
            <Table aria-label="collapsible table" size={'small'} stickyHeader>
                <GenerateTableHeaders headers={primarySchema} isCollapse={true}/>
                {/*<TableBody>*/}
                {data.map((row) => (
                    <CollapsibleRow
                        key={row.id}
                        row={row}
                        nestedRowName={nestedRowName}
                        nestedRowHeaders={collapsibleRowSchema}
                        rowSchema={primarySchema}
                        isNested={isNested}
                        extraDataHeader={extraDataHeader}
                    />
                ))}
                {/*</TableBody>*/}
            </Table>
        </TableContainer>
    );
};

export default CollapsibleTable;
