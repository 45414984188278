import React, {useState} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper,
    IconButton,
    Collapse,
    Box,
    Typography, Container, Divider,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TableCellRenderer from "../TableCellRenderer";

const RegularRow = ({
                        row,
                        nestedRowName,
                        nestedRowHeaders,
                        rowSchema,
                        isNested,
                        extraDataHeader,
                    }) => {
    const [open, setOpen] = useState(false);
    const totalColumns =
        rowSchema.length + (isNested ? nestedRowHeaders.length : 0) + 1;

    return (
        <>
            <TableRow hover onClick={() => setOpen(!open)}>
                <ShowRow row={row} schema={rowSchema}/>
            </TableRow>
        </>
    )
        ;
};

function ShowRow({row, schema}) {
    return (
        <>
            {schema.map((c) => (
                <TableCell key={c.columnName}>
                    {<TableCellRenderer column={c} value={row[c.columnName]} row={row}/>}
                </TableCell>
            ))}
        </>
    );
}

function GenerateTableHeaders({headers}) {
    return (
        <TableHead
            sx={{
                "& th": {
                    fontWeight: "bold",
                    color: "primary",
                    height: "40px",
                    alignItems: "center",
                },
                "& thead": {

                    minHeight: "20px",
                }
            }}
        >
            <TableRow>
                {headers.map((h, index) => (
                    <TableCell key={index}> {h.columnLabel}</TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const RegularTable = ({
                          data,
                          nestedRowName,
                          schema,
                          pageType
                      }) => {
    const primarySchema = schema
        .filter((item) => item.primary)
        .map((item) => ({
            columnName: item.id,
            columnLabel: item.label,
            type: item.type,
            linkEndpoint: item.linkEndpoint,
            linkValue: item.linkValue,
            audioHandler: item.audioHandler,
        }));


    if (!data) return;

    return (

        <Paper
            elevation={10}
            sx={{
                flexGrow: 1,
                minHeight: '40vh',
                borderRadius: '16px',
                alignItems: 'center',
                p: '1%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
            }}
        >
            <Box alignSelf={'flex-start'}>
                <Box p={0} maxWidth={'100%'} display={'flex'}>
                    <h1 style={{padding: 0, margin: 0}}>{pageType}</h1>
                </Box>

                <Divider flexItem sx={{marginRight: '30%'}}/>
            </Box>
            <TableContainer component={Paper}
                            elevation={0}
                            sx={{minWidth: "", borderRadius: '16px', m: 2}}>
                <Table aria-label="collapsible table">
                    <GenerateTableHeaders headers={primarySchema}/>
                    <TableBody>
                        {data.map((row, index) => (
                            <RegularRow
                                key={index}
                                row={row}
                                nestedRowName={nestedRowName}
                                rowSchema={primarySchema}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default RegularTable;
