import { useState, useEffect } from "react";
import { ProductService } from "../../Services/ProductService";

export function useProduct(id) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchProduct = async (id = {}) => {
    setLoading(true);
    setError("");

    try {
      const response = await ProductService.getOne(id);
      setData(response.data);
    } catch (error) {
      setError(error.message || "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProduct(id);
  }, [id]);

  const refetchProduct= () => {
    fetchProduct(id);
  };
  return { data, loading, error, refetchProduct };
}
