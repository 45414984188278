import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const KebabMenu = ({ menuOptions }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
                sx={{alignSelf:'start'}}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                key={menuOptions}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 48 * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {menuOptions.map((option) => (
                    <MenuItem key={option.label} onClick={() => {
                        handleClose();
                        option.action();
                    }}>
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
