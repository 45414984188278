import {request} from "../components/Handlers/RequestHandler";

const postHeaders = {
    "Content-Type": "application/json",
};





export const ArtistService = {
    create(newArtist) {
        return request("POST", "/artists", newArtist, null, postHeaders);
    },

    getAll(params) {
        return request("GET", "/artists", null, params);
    },

    getOne(id) {
        return request("GET", `/artists/${id}`, null);
    },

    update(id, updatedArtist) {
        return request("PATCH", `/artists/${id}`, updatedArtist, null, postHeaders);
    },

    delete(id) {
        return request("DELETE", `/artists/${id}`, null, null);
    },
};
