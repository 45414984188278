import {useParams} from "react-router-dom";
import {useProduct} from "../../../Hooks/ProductHooks/useProduct";
import {InfoPage} from "../../../ProductData";
import {TrackList} from "../../Tracklist/TrackList";
import Grid from "@mui/material/Unstable_Grid2";
import {Stack} from "@mui/material";
import {BasePageTemplate} from "../../../components/BasePageTemplate";

export function CombinedProductWithTracklist({productId}) {

    console.log(productId, 'productId in comboned product with tracklist');
    const {data, refetchProduct} = useProduct(productId);

    console.log(data, 'data in combined product with tracklist');
    const schema = [
        {field: "product_artist_name", label: "Product Artist Name"},
        {field: "product_version", label: "Product Version"},
        {field: "product_id", label: "Product"},
        {field: "release_date", label: "Release Date", type: "date"},
        {field: "catalog_number", label: "Catalog Number"},
        {field: "upc", label: "UPC"},
        {field: "display_upc", label: "Display UPC"},
        {field: "product_type", label: "Product Type"},
        {field: "product_format", label: "Product Format"},
        {field: "is_explicit", label: "Is Explicit", type: "explicit"},
        {field: "is_reissue", label: "Is Reissue", type: "boolean"},
        {field: "genres", label: "Genres", type: "chiplist"},
        {field: "label", label: "Label"},
        {field: "c_line_year", label: "C Line Year"},
        {field: "c_line_owner", label: "C Line Owner"},
        {field: "p_line_year", label: "P Line Year"},
        {field: "p_line_owner", label: "P Line Owner"},
        // {field: "image_url", label: "Image URL"},
    ];

    const infoPage = <InfoPage data={data} schema={schema} refetchProduct={refetchProduct} pageType={'Product'}/>;
    const trackList = <TrackList productId={productId} artistId={data.release_artist_id}/>;


    return (<>

        <BasePageTemplate contentComponent={[{comp: infoPage, size: 4}, {comp: trackList, size: 8}]}/>

    </>);
}
