import {useDeleteProduct} from "../../Hooks/ProductHooks/useDeleteProduct";
import {useAddProduct} from "../../Hooks/ProductHooks/useAddProduct";
import {useEffect, useState} from "react";
import {useSnackbar} from "../Context/SnackbarContext";
import {Controller, useForm} from "react-hook-form";
import {usePatchProduct} from "../../Hooks/ProductHooks/usePatchProduct";
import {ArtistSelector} from "./ArtistSelector";
import {ReleaseSelector} from "./ReleaseSelector";
import {FormControlLabel, Step, StepLabel, Stepper, Switch} from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Unstable_Grid2";
import {TextField} from '@mui/material';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {SingleInputField} from "./Elements/SingleInputField";
import {DateInputField} from "./Elements/DateInputField";
import {GenrePicker} from "./Elements/GenrePicker";
import {useModal} from "../Context/ModalContext";
import {useRelease} from "../../Hooks/ReleaseHooks/useRelease";
import {LoadingButton} from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

export function ProductForm({productId, mode, currentData, releaseId, artistId}) {
    const artistField = mode === 'create' ? 'artist_id' : 'release_artist_id';
    const {data: releaseData, loading: loadingReleaseData} = useRelease(releaseId);
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Select Artist/Release', 'Product Details', 'Additional Information'];
    const {
        loading: processingDelete,
        error: deleteError,
        success: deleteSuccess,
        deleteProduct
    } = useDeleteProduct();
    const {loading: processingUpdate, error: updateError, success: updateSuccess, patchProduct} = usePatchProduct();
    const {loading: processingCreate, error: createError, success: createSuccess, addProduct} = useAddProduct();
    const {openSnackbar} = useSnackbar();
    const {closeModal} = useModal();

    const {control, handleSubmit, setValue, watch, getValues} = useForm({
        defaultValues: {

            [artistField]: artistId,
            release_id: releaseId,
            ...currentData,

        }
    });
    console.log(currentData,'currentData')
    useEffect(() => {

        console.log(getValues('release_id'), 'I AM THE RELEASE ID');
    }, [getValues]);

    useEffect(() => {
        if (createSuccess) {
            openSnackbar('Product created');
            closeModal();
        } else if (updateSuccess) {
            console.log('I AM HERE');
            openSnackbar('Product updated');
            closeModal();
        } else if (deleteSuccess) {
            openSnackbar('Product deleted');
            closeModal();
        }

        if (createError || updateError || deleteError) {
            openSnackbar(`Operation failed: ${createError || updateError || deleteError}`);
        }
    }, [createSuccess, updateSuccess, deleteSuccess, createError, updateError, deleteError, openSnackbar, closeModal]);

    const isLoading = processingUpdate || processingCreate || processingDelete;

    useEffect(() => {
        if (!loadingReleaseData && releaseData) {
            setValue('artist_id', releaseData.artist_id);
            setValue('release_id', releaseData.release_id);
        }
    }, [releaseData, loadingReleaseData]);
    const handleNext = (event) => {
        event.preventDefault();
        setTimeout(() => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }, 0);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const productTypes = {
        'Physical': ['12 inch vinyl', '7 inch vinyl', 'CD', 'Cassette', 'Other'],
        'Digital': ['Digital', 'Other']
    };


    useEffect(() => {
            console.log('Effect running')
            if (updateSuccess) {
                console.log('I AM HERE')
                openSnackbar('Product updated');
                closeModal();
            } else if (createSuccess) {
                openSnackbar('Product created');
                closeModal();
            } else if (updateError) {
                openSnackbar('Operation failed: ' + updateError);
            }
        }

        ,
        [updateSuccess, updateError, createSuccess, createError]
    )
    ;


    const onSubmit = async (data) => {
        console.log('this is the data I will do something with', data)
        console.log('THE MODE I AM IN IS', mode)

        if (mode === 'edit') {
            console.log('updating product')
            await patchProduct(productId, data)
            console.log('fin')
        } else if (mode === 'create') {
            await addProduct(data)
        }
    };


    const productType = watch('product_type');

    return (
        <>
            <>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            {activeStep === 0 && !loadingReleaseData && (
                                <>
                                    <ArtistSelector control={control}
                                                    setValue={setValue}
                                                    fieldName={artistField}
                                                    artistId={artistId}/>

                                    {watch([artistField]) && watch([artistField]) !== '' && (
                                        <ReleaseSelector
                                            artistId={getValues(artistField) || artistId}
                                            control={control}
                                            setValue={setValue}
                                            initialValue={releaseId || currentData?.release_id}
                                        />
                                    )
                                    }
                                </>)
                            }
                            {activeStep === 1 && (
                                <Grid container spacing={2}>

                                    <Grid xs={6}>
                                        <SingleInputField name={'product_version'}
                                                          label={'Product Version'}
                                                          control={control}
                                                          defaultValue={currentData?.product_version}/>
                                    </Grid>

                                    <Grid xs={6}>
                                        <SingleInputField name={'product_artist_name'} control={control}
                                                          label={'Artist Name'}
                                                          defaultValue={currentData?.product_artist_name}/>
                                    </Grid>
                                    <Grid xs={6}>
                                        <DateInputField name={'release_date'} label={'Release Date'}
                                                        required={true}
                                                        control={control}
                                                        defaultValue={currentData && currentData.release_date}/>
                                    </Grid>
                                    <Grid xs={6}>
                                        <Controller
                                            name="catalog_number"
                                            control={control}
                                            render={({field}) => <TextField {...field} label="Catalog Number"
                                                                            variant="outlined"
                                                                            fullWidth/>}
                                        />
                                    </Grid>
                                    <Grid xs={6}>
                                        <Controller
                                            name="upc"
                                            control={control}
                                            render={({field}) => <TextField {...field} label="UPC"
                                                                            variant="outlined"
                                                                            fullWidth/>}
                                        />
                                    </Grid>
                                    <Grid xs={6}>
                                        <Controller
                                            name="display_upc"
                                            control={control}
                                            render={({field}) => <TextField {...field} label="Display UPC"
                                                                            variant="outlined"
                                                                            fullWidth/>}
                                        />
                                    </Grid>
                                    <Grid xs={6}>
                                        <Controller
                                            name="product_type"
                                            control={control}
                                            render={({field}) => (
                                                <TextField {...field} select label="Product Type" variant="outlined"
                                                           required
                                                           fullWidth>

                                                    <MenuItem disabled value=''>None</MenuItem>
                                                    {Object.keys(productTypes).map(option => (
                                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </Grid>
                                    <Grid xs={6}>
                                        <Controller
                                            name="product_format"
                                            control={control}
                                            render={({field}) => (
                                                <TextField {...field} select label="Product Format"
                                                           variant="outlined"
                                                           required
                                                           fullWidth>
                                                    <MenuItem disabled value=''>None</MenuItem>
                                                    {productTypes[productType]?.map(option => (
                                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />

                                    </Grid>
                                    <Grid xs={6}>
                                        <GenrePicker control={control} name={'genres'}
                                                     defaultValue={currentData?.genres}/>
                                    </Grid>
                                    <Grid xs={6}>
                                        <Controller
                                            name="label"
                                            control={control}
                                            render={({field}) => <TextField {...field} label="Label"
                                                                            variant="outlined"
                                                                            fullWidth/>}
                                        />
                                    </Grid>
                                    <Grid xs={6}>
                                        <Controller
                                            name="is_explicit"
                                            control={control}
                                            render={({field}) => (
                                                <FormControlLabel
                                                    control={<Switch {...field} checked={field.value}/>}
                                                    label="Is Explicit?"
                                                />

                                            )}
                                        />
                                    </Grid>

                                    <Grid xs={6}>
                                        <Controller
                                            name="is_reissue"
                                            control={control}
                                            render={({field}) => (
                                                <FormControlLabel
                                                    control={<Switch {...field} checked={field.value}/>}
                                                    label="Is Reissue?"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>


                            )}

                        </div>
                        {activeStep === 2 && (
                            <Grid container spacing={2}>
                                <Grid xs={6}>

                                    <Controller
                                        name="c_line_year"
                                        control={control}
                                        render={({field}) => <TextField {...field} label="C Line Year"
                                                                        variant="outlined"
                                                                        fullWidth/>}
                                    />
                                </Grid>

                                <Grid xs={6}>
                                    <Controller
                                        name="c_line_owner"
                                        control={control}
                                        render={({field}) => <TextField {...field} label="C Line Owner"
                                                                        variant="outlined"
                                                                        fullWidth/>}
                                    />
                                </Grid>

                                <Grid xs={6}>
                                    <Controller
                                        name="p_line_year"
                                        control={control}
                                        render={({field}) => <TextField {...field} label="P Line Year"
                                                                        variant="outlined"
                                                                        fullWidth/>}
                                    />
                                </Grid>

                                <Grid xs={6}>
                                    <Controller
                                        name="p_line_owner"
                                        control={control}
                                        render={({field}) => <TextField {...field} label="P Line Owner"
                                                                        variant="outlined"
                                                                        fullWidth/>}
                                    />
                                </Grid>

                                <Grid xs={6}>
                                    <Controller
                                        name="image_url"
                                        control={control}
                                        render={({field}) => <TextField {...field} label="Image URL"
                                                                        variant="outlined"
                                                                        fullWidth/>}
                                    />
                                </Grid>


                            </Grid>
                        )}
                        <div>
                            <Button type='button' disabled={activeStep === 0} onClick={handleBack}>
                                Back
                            </Button>
                            {activeStep === steps.length - 1 ? (
                                <LoadingButton
                                    loading={isLoading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon/>}
                                    variant="outlined"
                                    type={'submit'}
                                >
                                    Save
                                </LoadingButton>
                            ) : (
                                <Button type='button' onClick={handleNext}>Next</Button>
                            )}
                        </div>
                    </form>

                </LocalizationProvider>
            </>


        </>

    )

}