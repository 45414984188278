import {Skeleton, Stack} from "@mui/material";

export function LoadingTable() {


    return (

        <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'start', justifyContent: 'center', marginTop:'2%'}}>
            <Stack spacing={1} alignSelf={'start'} justifySelf={'center'}>

                <Skeleton variant="rounded" width={'50vw'} height={'4vh'}/>
                <Skeleton variant="rounded" width={'50vw'} height={'3vh'}/>
                <Skeleton variant="rounded" width={'50vw'} height={'3vh'}/>
                <Skeleton variant="rounded" width={'50vw'} height={'3vh'}/>
                <Skeleton variant="rounded" width={'50vw'} height={'3vh'}/>
                <Skeleton variant="rounded" width={'50vw'} height={'3vh'}/>
                <Skeleton variant="rounded" width={'50vw'} height={'3vh'}/>
                <Skeleton variant="rounded" width={'50vw'} height={'3vh'}/>
                <Skeleton variant="rounded" width={'10vw'} height={'3vh'}/>


            </Stack>

        </div>)


};

