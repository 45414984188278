import {UploadService} from "../../Services/UploadService";
import {useState} from "react";

export function useCreateAsset() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const addAsset = async (id, data) => {
        setLoading(true);
        setError("");
        try {
            const resp = await UploadService.create(id, data);
            if (resp.status === 201) {
                setSuccess(true);
            } else {
                setError("An unexpected error occurred");
            }
        } catch (error) {
            console.log("ERROR during posting asset:", error);
            setError(error.error || "An unexpected error occurred");
        } finally {
            setLoading(false);
        }
    };


    return {loading, error, addAsset, success};
}
