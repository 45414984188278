import CollapsibleTable from "./CollapsibleTable";
import {Box, Container, Divider, Paper, TablePagination} from "@mui/material";
import {useEffect, useState} from "react";
import {LoadingSkeleton} from "../LoadingSkeleton";
import {LoadingTable} from "../LoadingTable";


export default function TableWithPagination({
                                                data,
                                                schema,
                                                recordCount,
                                                itemsPerPageCb,
                                                currentPageCb,
                                                itemsPerPage,
                                                currentPage,
                                                nestedRowName,
                                                isNested = false,
                                                extraDataHeader,
                                                title, filterComp, loading


                                            }) {

    const handleChangeRowsPerPage = (event) => {
        itemsPerPageCb(parseInt(event.target.value, 10));
        currentPageCb(0);

    }
    const handleChangePage = (event, newPage) => {
        currentPageCb(newPage);
    };

    useEffect(() => {
        itemsPerPageCb(itemsPerPage);
        currentPageCb(currentPage);
    }, [currentPage, itemsPerPage]);


    return (<>
        <Paper
            elevation={10}
            sx={{
                flexGrow: 1,
                minHeight: '40vh',
                borderRadius: '16px',
                alignItems: 'center',
                p: '1%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
            }}
        >
            <Box alignSelf={'flex-start'}>
                <Box p={0} maxWidth={'100%'} display={'flex'}>
                    <h1 style={{padding: 0, margin: 0}}>{title}</h1>
                    {filterComp}
                </Box>

                <Divider flexItem sx={{marginRight: '30%'}}/>
            </Box>

            {loading ? (
                <LoadingTable/>

            ) : (
                <>
                    <CollapsibleTable
                        data={data}
                        schema={schema}
                        isNested={isNested}
                        nestedRowName={nestedRowName}
                        extraDataHeader={extraDataHeader}
                    />
                    <TablePagination
                        count={recordCount}
                        page={currentPage}
                        rowsPerPage={itemsPerPage}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[5, 10, 25]}
                        sx={{alignSelf: 'start'}}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Paper>
    </>)
};