import React, {useEffect, useState, useMemo, useCallback} from "react";
import {
    MaterialReactTable,
    MRT_TableContainer as MRTTableContainer,
    useMaterialReactTable,
} from "material-react-table";
import {Paper} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {Modal, Box, Typography} from "@mui/material";

import _ from "lodash";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import {useTrackVersions} from "../../Hooks/TrackVersionHooks/useTrackVersions";
import {StyledLink} from "../StyledLink";
import {getSignedUrl} from "../AssetDirectory/FileNode";
import {useAudio} from "../Context/AudioContext";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {download, generateCsv, mkConfig} from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const csvOptions = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'Tracklist Data',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
});
export default function DraggableTracklistTable({
                                                    initialTracklistData = [],
                                                    hasBeenModified,
                                                    onTrackListUpdate,
                                                    artistId,
                                                    onCancel, title
                                                }
) {
    const [data, setData] = useState([]);
    const [signedUrl, setSignedUrl] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [showInputs, setShowInputs] = useState(false);
    const [autocompleteValue, setAutocompleteValue] = useState("");
    const [artistTracks, setArtistTracks] = useState([]);
    const [changed, setChanged] = useState(false);
    const [newRowInput, setNewRowInput] = useState({
        disc_number: "",
        track_name: "",
        isrc: "",
    });
    const preprocessDataForCSV = (data) => {
        return data.map(row => {
            const processedRow = {};
            for (const key in row) {
                if (typeof row[key] === 'object' && row[key] !== null) {
                    processedRow[key] = JSON.stringify(row[key]);
                } else {
                    processedRow[key] = row[key];
                }
            }
            return processedRow;
        });
    };
    const handleExportData = () => {
        const processedData = preprocessDataForCSV(data)
        const csv = generateCsv(csvOptions)(processedData);
        download(csvOptions)(csv);
    };

    const {isrc, toggleAudio, isPlaying, audioUrl} = useAudio();
    console.log(isrc);
    const fetchAndUseUrl = async (blob, isrc) => {
        try {
            const url = await getSignedUrl(blob);
            toggleAudio(url, isrc);
        } catch (error) {
            console.error('Error fetching signed URL:', error);
        }
    };

    const handlePlayPause = (blob, isrc) => {
        if (audioUrl && audioUrl.includes(blob)) {
            toggleAudio();  // Pause the current track
        } else {
            fetchAndUseUrl(blob, isrc);  // Play the new track
        }
    };

    const closeInputs = () => setShowInputs(false);

    const {tracks, refetchTracks} = useTrackVersions({limit: 10, artistId: artistId});

    useEffect(() => {
        console.log('loading initial tracks')
        if (artistId) {
            refetchTracks({artistId: artistId, limit: 10});
            setSearchResults(tracks)
        }
    }, [artistId]);

    useEffect(() => {
        if (!changed) return;
        hasBeenModified(changed);
    }, [changed]);

    const handleCancel = () => {
        setNewRowInput({
            disc_number: "",
            track_name: "",
            isrc: "",
            track_version: ""
        });
        setAutocompleteValue("");
        setShowInputs(false);
        setChanged(false);

    };


    useEffect(() => {
        console.log(artistId, 'artistid')
    }, [artistId]);

    useEffect(() => {
        handleCancel()

    }, [onCancel]);

    useEffect(() => {
        try {
            const mappedTracks = tracks.map(track => ({
                track_name: track.track_name,
                isrc: track.isrc,
                track_version: track.track_version,
            }));
            setArtistTracks(mappedTracks);
        } catch (error) {
            console.error("Failed to fetch tracks:", error);
        }

    }, [artistId, tracks]);

    const debouncedSearch = useCallback(_.debounce((searchValue) => {
        if (artistId) {
            refetchTracks({artistId, trackName: searchValue, limit: 10, offset: 0});
        }
    }, 500), [artistId]);

    useEffect(() => {
        if (searchTerm) {
            debouncedSearch(searchTerm);
        }
        return () => debouncedSearch.cancel();
    }, [searchTerm, debouncedSearch]);


    useEffect(() => {
        setData(
            [...initialTracklistData].sort((a, b) =>
                a.disc_number !== b.disc_number
                    ? a.disc_number - b.disc_number
                    : a.track_number - b.track_number,
            ),
        );
    }, [initialTracklistData]);


    const getNewRowData = (data, newRowInput) => {
        const newDiscNumber = parseInt(newRowInput.disc_number);
        const newIsrc = newRowInput.isrc;

        const tracksInDisc = data.filter(
            (row) => row.disc_number === newDiscNumber,
        );

        let newTrackNumber;

        if (tracksInDisc.length === 0) {
            newTrackNumber = 1;
        } else {
            const highestTrackNumber = Math.max(
                ...tracksInDisc.map((track) => track.track_number),
            );
            newTrackNumber = highestTrackNumber + 1;
        }

        return {
            disc_number: newDiscNumber,
            track_number: newTrackNumber,
            isrc: newIsrc,
            track_name: newRowInput.track_name,
            track_version: newRowInput.track_version,
        };
    };

    const handleAddRow = () => {
        if (
            !newRowInput.disc_number ||
            !newRowInput.track_name ||
            !newRowInput.isrc
        ) {
            alert("Please ensure all fields are filled correctly.");
            return;
        }

        const newRow = getNewRowData(data, newRowInput);
        const updatedData = [...data, newRow];
        setData(updatedData);

        const trimmedData = updatedData.map((track) => ({
            disc_number: track.disc_number,
            track_number: track.track_number,
            isrc: track.isrc,
        }));
        onTrackListUpdate(trimmedData);

        setNewRowInput({disc_number: "", track_name: "", isrc: ""});
        setAutocompleteValue("");
        closeInputs();
        setChanged(true)
    };


    const handleTrackSelection = (event, newValue, reason) => {
        const selectedTrack = tracks.find((track) => {
            const trackVersionExists =
                track.track_version && track.track_version.trim() !== "";
            const val = `${track.track_name} - ${track.isrc}${
                trackVersionExists ? ` - ${track.track_version}` : ""
            }`;

            return val === newValue;
        });

        console.log(selectedTrack)
        if (selectedTrack) {
            setNewRowInput((prev) => ({
                ...prev,
                track_name: selectedTrack.track_name,
                isrc: selectedTrack.isrc,
                track_version: selectedTrack.track_version,
            }));
            setAutocompleteValue(newValue);
        } else if (reason === "clear") {
            setNewRowInput((prev) => ({...prev, track_name: "", isrc: ""}));
        }
    };

    const handleDeleteRow = useCallback(
        (rowIndex) => {
            const updatedData = data.filter((_, index) => index !== rowIndex);

            const recalculatedData = updatedData.map((track, index, arr) => {
                if (index === 0 || track.disc_number !== arr[index - 1].disc_number) {
                    return {...track, track_number: 1};
                } else {
                    return {
                        ...track,
                        track_number: arr[index - 1].track_number + 1,
                    };
                }
            });
            setChanged(true);
            setData(recalculatedData);
            onTrackListUpdate(recalculatedData);
        },
        [data],
    );


    const baseUrl = window.location.origin;
    const columns = useMemo(
        () => [
            {
                accessorKey: "blob_name", size: 20, header: "", Cell: ({row}) => {
                    const blobName = row.original.blob_name;
                    const isrc1 = row.original.isrc;
                    return blobName ? (
                        <IconButton
                            onClick={() => handlePlayPause(blobName, isrc1)}
                            aria-label="play"
                            size="small"
                        >
                            {isPlaying && isrc === isrc1 ? <PauseIcon fontSize="small"/> :
                                <PlayArrowIcon fontSize="small"/>}
                        </IconButton>
                    ) : null;
                }
            },
            {accessorKey: "disc_number", header: "Disc", size: 20},
            {accessorKey: "track_number", header: "#", size: 20},
            {
                accessorKey: "track_name", header: "Track Name", Cell: ({row}) => {

                    const trackId = row.original.track_id;
                    return trackId ? (<StyledLink
                            to={`${baseUrl}/masters/tracks/${trackId}`}>{row.original.track_name}</StyledLink>) :
                        row.original.track_name
                }
            },
            {accessorKey: "track_version", header: "Track Version", size: 20},
            {
                accessorKey: "isrc",
                header: "ISRC",
                Cell: ({row}) => {
                    const isrcValue = row.original.isrc;
                    return isrcValue ? (
                        <StyledLink to={`${baseUrl}/masters/trackversions/${isrcValue}`}
                        >
                            {isrcValue}
                        </StyledLink>
                    ) : (
                        "No ISRC Data"
                    );
                },
            },

            {
                id: "delete",
                header: "Actions",
                Cell: ({row}) =>
                    <IconButton
                        onClick={() => handleDeleteRow(row.index)}
                        aria-label="delete row"
                        size="small"
                    >
                        <DeleteIcon fontSize="small"/>
                    </IconButton>
            },
        ],
        [handleDeleteRow, isPlaying, isrc],
    );

    const handleCancelEdit = () => {
        setShowInputs(false);
        setNewRowInput({disc_number: "", track_name: "", isrc: ""}); // Reset newRowInput state
    };

    const table = useMaterialReactTable({
        enablePagination: false,
        columns,
        data,
        positionToolbarAlertBanner: 'bottom',
        muiTablePaperProps: ({table}) => ({
            elevation: 0,
            style: {
                zIndex: table.getState().isFullScreen ? 10000000 : undefined,
                top: table.getState().isFullScreen ? '10vh' : undefined,

            },
        }),
        enableFilters: false,
        enableMultiSort: false,
        enableDensityToggle: false,
        renderTopToolbarCustomActions: ({table}) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    flexWrap: 'wrap',
                }}
            >
                <Button
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon/>}
                >
                    Export Tracklist
                </Button>
            </Box>

        ),
        enableRowOrdering: true,
        enableSorting: false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 100, pageIndex: 0},
            //     TODO: Address page size. This is unlikely to be an issue but could be if a product has > 100 tracks.
            columnPinning: {
                left: ['mrt-row-drag'],
                // right: ['mrt-row-actions'],
            }
        },
        enableStickyHeader: true,
        muiTableHeadCellProps: {sx: {fontSize: 'medium', fontWeight: 'bold', textAlign: 'center'}, draggable: true},
        displayColumnDefOptions: {
            'mrt-row-drag': {
                muiTableHeadCellProps: {
                    sx: {
                        color: 'transparent',
                        position: 'sticky'
                    }
                }
            }
        },
        muiRowDragHandleProps: ({table}) => ({
            onDragEnd: () => {
                const {draggingRow, hoveredRow} = table.getState();

                if (hoveredRow !== undefined && draggingRow !== undefined) {
                    const newData = [...data];
                    const [removed] = newData.splice(draggingRow.index, 1);
                    newData.splice(hoveredRow.index, 0, removed);

                    const groupedByDisc = newData.reduce((acc, item) => {
                        acc[item.disc_number] = acc[item.disc_number] || [];
                        acc[item.disc_number].push(item);
                        return acc;
                    }, {});

                    const discNumbersSorted = Object.keys(groupedByDisc).sort(
                        (a, b) => parseInt(a) - parseInt(b),
                    );

                    let recalculatedData = [];
                    discNumbersSorted.forEach((discNumber) => {
                        let trackNumber = 1;
                        groupedByDisc[discNumber].forEach((item) => {
                            recalculatedData.push({
                                ...item,
                                track_number: trackNumber++,
                            });
                        });
                    });

                    setChanged(true)
                    setData(recalculatedData);
                    onTrackListUpdate(recalculatedData);
                }
            },
        }),
    });

    return (
        <Paper container sx={{pl: 2, borderRadius: '16px'}}>
            <h1> {title}</h1>
            <MaterialReactTable table={table} className="MRT" sx={{borderRadius: '16px'}}/>
            {
                <div
                    className="inputs"
                    style={{marginBottom: "20px", fontSize: "small"}}
                >
                    {!showInputs ? (
                        <IconButton aria-label="add" onClick={() => setShowInputs(true)}>
                            <p style={{fontSize: "small", marginRight: "5px"}}>
                                Add Track{" "}
                            </p>
                            <AddCircleOutlineIcon fontSize="small"/>
                        </IconButton>
                    ) : (
                        <>
                            <Modal
                                open={showInputs}
                                onClose={closeInputs}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        width: 400,
                                        bgcolor: "background.paper",
                                        border: "2px solid #000",
                                        boxShadow: 24,
                                        p: 4,
                                    }}
                                >
                                    <Typography id="add-track-title" variant="h6" component="h2">
                                        Add Track to Product
                                    </Typography>

                                    <Grid container spacing={2} direction="column">
                                        <Grid>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                label="Disc Number"
                                                value={newRowInput.disc_number}
                                                onChange={(e) =>
                                                    setNewRowInput({
                                                        ...newRowInput,
                                                        disc_number: e.target.value,
                                                    })
                                                }
                                                variant="outlined"
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid>
                                            <Autocomplete
                                                freeSolo
                                                options={tracks.map((option) => {
                                                    const trackVersionExists =
                                                        option.track_version &&
                                                        option.track_version.trim() !== "";
                                                    return `${option.track_name} - ${option.isrc}${
                                                        trackVersionExists
                                                            ? ` - ${option.track_version}`
                                                            : ""
                                                    }`;
                                                })}
                                                inputValue={autocompleteValue}
                                                onInputChange={(event, newInputValue, reason) => {
                                                    setAutocompleteValue(newInputValue);
                                                    if (reason === "input") setSearchTerm(newInputValue);
                                                }}
                                                onChange={handleTrackSelection}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Search by track name..."
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid>
                                            <TextField
                                                fullWidth
                                                disabled
                                                label="ISRC"
                                                value={newRowInput.isrc}
                                                variant="outlined"
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid>
                                            <TextField
                                                fullWidth
                                                disabled
                                                label="Track Name"
                                                value={newRowInput.track_name}
                                                variant="outlined"
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid>
                                            <TextField
                                                fullWidth
                                                disabled
                                                label="Track Version"
                                                value={newRowInput.track_version}
                                                variant="outlined"
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Box
                                        sx={{display: "flex", justifyContent: "flex-end", mt: 2}}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleAddRow}
                                            sx={{mr: 2}}
                                        >
                                            Add Row
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={closeInputs}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                </Box>
                            </Modal>
                        </>
                    )}
                </div>
            }
        </Paper>
    );
}
;

