import {useState, useEffect} from "react";
import {ProductService} from "../../Services/ProductService";

export function useProductTracklist(id) {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const fetchProductTracklist = async (id = {}) => {
        setLoading(true);
        setError("");

        try {
            const response = await ProductService.getTracklist(id);
            setData(response.data);

            setLoading(false);
        } catch (error) {
            setError(error.message || "An unexpected error occurred");
        } finally {

            setLoading(false);
        }
    };


    useEffect(() => {
        fetchProductTracklist(id);
    }, [id]);

    const refetchTracklist = (id) => {
        fetchProductTracklist(id);
    };
    return {data, loading, error, refetchTracklist};
}
