import { ReleaseService } from "../../Services/ReleaseService";
import { useEffect, useState, useRef } from "react";

export function useReleases(initialParams = {}) {
  const [data, setData] = useState({
    releases: [],
    recordCount: 0,
    nextUrl: null,
    previousUrl: null,
  });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState(initialParams);
  const [error, setError] = useState("");

  const formatResponse = (response) => {
    return {
      releases: response.releases,
      recordCount: response.record_count,
      nextUrl: response.next_url,
      previousUrl: response.previous_url,
    };
  };

  useEffect(() => {
    const fetchReleases = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await ReleaseService.getAll(params);
        const formattedData = formatResponse(response.data);
        console.log("Formatted Data:", formattedData);
        setData(formattedData);
        setLoading(false);
      } catch (error) {
        console.log("ERROR during fetch:", error);
        setError(error.message || "An unexpected error occurred");
      }
    };

    fetchReleases();
  }, [params]);
  const refetchReleases = (newParams = {}) => {
    setParams(newParams);
  };

  const updateParams = (newParams = {}) => {
    console.log("calling update params");
    setParams(newParams);
  };

  return { ...data, loading, error, refetchReleases, updateParams };
}
