import {useSnackbar} from "./Context/SnackbarContext";
import {useNavigate} from "react-router-dom";


export function useDelete() {
        const { openSnackbar } = useSnackbar();
        const navigate = useNavigate();

        const handleDelete = async (id, deleteFn) => {
            if (window.confirm("Are you sure you want to delete this item?")) {
                openSnackbar('Deleting...');
                try {
                    await deleteFn(id);
                    openSnackbar('Deleted successfully');
                    navigate(-1);
                } catch (error) {
                    openSnackbar('Error deleting item. Click to retry.', () => handleDelete(id, deleteFn));
                }
            }
        };

        return { handleDelete };
    }