import axios from "axios";


let baseUrl = "http://localhost:8000/api/v1";
if (process.env.NODE_ENV === 'production') {

    baseUrl = "https://fastapi-backend-neo4j-7w6cie3avq-nw.a.run.app/api/v1";
}

export async function request(
    method,
    url,
    data = null,
    params = {},
    headers
) {
    try {
        return await axios({
            method: method,
            baseURL: baseUrl,
            url: url,
            data: data,
            params: params,
            headers: headers

        });
    } catch (error) {
        console.error("Request failed:", error);
        throw error.response
            ? error.response.data
            : new Error("An unexpected error occurred");
    }
}
