import {Artists} from "../Artists/Artists";
// import {BasicTabs} from "../../Components/Tabs/PageTabs";
import {PageTabs} from "../../../components/Tabs/PageTabs";
import {Releases} from "../Releases/Releases";
// import {Products} from "../Products/Products";
import {Tracks} from "../Tracks/Tracks";
import {Box, Container, Typography} from "@mui/material";
import {MastersHome} from "./Home";
import {Products} from "../Products/Products";
import MediaPlayer from "../../../components/MediaPlayer";


const tabs = [{label: 'Home', component: MastersHome, link: '/masters'}, {label: "Artists", component: Artists}, {
    label: "Releases",
    component: Releases,
    link: '/masters/releases'
},


    {label: "Products", component: Products, link: '/masters/products'},
    {label: 'Tracks', component: Tracks, link: '/masters/tracks'}];

export function ArtistsPage() {
    return (
        <Container maxWidth={'100%'}>
            {/*<Box className={'hspace'}>*/}
            {/*    <Typography variant="h1" sx={{marginTop: "1%", marginBottom: "0%"}}>Artists</Typography>*/}
            {/*</Box>*/}
            <Box marginTop={'1%'}/>

            <PageTabs tabs={tabs} defaultTab={1}/>
        </Container>
    );
}
