import React, {useState, useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import {styled, alpha} from "@mui/material/styles";
import {Box, Typography, List, ListItem, ListItemText, Paper, Popover, Avatar} from "@mui/material";
import {Link} from "react-router-dom";
import {debounce} from "lodash";
import axios from "axios";
import {MastersDropdown} from "./AppBarDropdowns/MastersDropdown";
import {PublishingDropdown} from "./AppBarDropdowns/PublishingDropdown";
import MediaPlayer from "./MediaPlayer";

const Search = styled("div")(({theme}) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: theme.spacing(1),
    width: "auto",
}));

const SearchIconWrapper = styled("div")(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "20ch",
    },
}));
let baseUrl = "http://localhost:8000/api/v1";
if (process.env.NODE_ENV === 'production') {

    baseUrl = "https://fastapi-backend-neo4j-7w6cie3avq-nw.a.run.app/api/v1";
}
const NavigationBar = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const debouncedSearch = useCallback(
        debounce(async (query) => {
            if (query) {
                try {
                    const response = await axios.get(`${baseUrl}/search?q=${query}`);
                    setSearchResults(response.data.results);
                } catch (error) {
                    console.error("Error fetching search results:", error);
                }
            } else {
                setSearchResults([]);
            }
        }, 300),
        []
    );

    useEffect(() => {
        if (searchQuery) {
            debouncedSearch(searchQuery);
        }
        return () => {
            debouncedSearch.cancel();
        };
    }, [searchQuery, debouncedSearch]);

    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        if (query) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const handleDeepSearch = (link) => {
        setAnchorEl(null);
        navigate(`masters/${link}`)
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const [mastersMenuAnchorEl, setMastersMenuAnchorEl] = useState(null);


    const handleMastersMenu = (event) => {
        setMastersMenuAnchorEl(event.currentTarget);
    };
    const handleCloseMenus = () => {
        setAnchorEl(null);
        setMastersMenuAnchorEl(null);
        // setPublishingMenuAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'search-popover' : undefined;

    return (
        <AppBar position="sticky" sx={{
            backgroundColor: "black",
            height: "6vh",
            display: 'flex',
            justifyContent: 'center',
            zIndex: 100000
        }}>
            <Toolbar>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Link to={"/"} style={{textDecoration: "none", color: "inherit"}}>
                        <img
                            src="/bfm_no_border.jpg"
                            alt="logo"
                            style={{height: "4vh"}}
                        />
                    </Link>
                    <Typography sx={{
                        fontFamily: 'var(--header-font)',
                        fontWeight: 'bold',
                        fontSize: "3vh",
                        marginLeft: "1vw"
                    }}>RMS</Typography>
                </div>
                <MediaPlayer/>
                <Box sx={{flexGrow: 1}}></Box>
                <MastersDropdown
                    menuHandler={handleMastersMenu}
                    menuAnchor={mastersMenuAnchorEl}
                    handleClose={handleCloseMenus}
                />                <PublishingDropdown/>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon/>
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        inputProps={{"aria-label": "search"}}
                        value={searchQuery}
                        onChange={handleSearchChange}
                        onFocus={(event) => setAnchorEl(event.currentTarget)}
                    />
                </Search>
            </Toolbar>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{zIndex: 1000000}}
                disableAutoFocus
                disableEnforceFocus
            >
                <Paper sx={{width: '300px', maxHeight: '400px', overflow: 'auto', boxShadow: 24}}>
                    <List>
                        {searchResults.map((result, index) => (
                            <ListItem button key={index} onClick={() => handleDeepSearch(result.link)} divider>
                                <Avatar src={result.image_url} alt={result.name} variant="square"/>
                                <Box sx={{ml: 2}}>
                                    <ListItemText
                                        primary={`${result.name} • ${result.type}`}
                                        sx={{fontFamily: 'var(--bfm-font)'}}
                                    />
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Popover>
        </AppBar>
    );
};

export default NavigationBar;
