import {Box, Paper, Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {LoadingItemCount} from "./LoadingItemCount";

export function ItemCount({itemCount, entityType, icon, background}) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (itemCount > 0) setLoading(false);
    }, [itemCount]);

    return (
            <Box
                sx={{
                    borderRadius: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 4,
                    background: 'black',
                    width: '100%',
                    boxSizing: 'border-box',
                }}
            >
                {loading ? (
                    <LoadingItemCount/>
                ) : (
                    <Stack spacing={1} alignItems={'center'}>
                        {icon}
                        <Stack spacing={1} alignItems={'center'} direction={'row'}>
                            <Typography variant={'h5'} sx={{fontFamily: 'var(--header-font)', color: 'white'}}>
                                {itemCount}
                            </Typography>
                            <Typography variant={'h5'} sx={{fontFamily: 'var(--header-font)', color: 'white'}}>
                                {entityType}
                            </Typography>
                        </Stack>
                    </Stack>
                )}
            </Box>
    );
}
