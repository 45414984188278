import { request } from "../components/Handlers/RequestHandler";

const postHeaders = {
  "Content-Type": "application/json",
};

export const TracksService = {
  create(newTrack) {
    return request("POST", "/tracks", newTrack, null, postHeaders);
  },

  getAll(params) {
    return request("GET", "/tracks", null, params, null);
  },

  getOne(id) {
    return request("GET", `/tracks/${id}`, null);
  },

  update(id, updatedTrack) {
    return request("PATCH", `/tracks/${id}`, updatedTrack, null, postHeaders);
  },

  delete(id, params) {
    return request("DELETE", `/tracks/${id}`, null, params);
  },
};
