import {Stack} from "@mui/material";
import {SocialIcon} from "react-social-icons";
import {CustomSocialIcon} from "../icons/CustomSocialIcon";

const socialPrefixes = {
    spotify: 'https://open.spotify.com/artist/',
    appleMusic: 'https://music.apple.com/gb/artist/',
    instagram: 'https://www.instagram.com/',
    facebook: 'https://www.facebook.com/',
    twitter: 'https://twitter.com/',
    youtube:'https://www.youtube.com/user/'

}
function getSocialLink(socialId, prefix) {
    return socialPrefixes[prefix] + socialId;
}


export function Socials({data}) {


    return (
        <Stack direction={'row'} spacing={1} alignSelf={'end'}>
            <SocialIcon url={getSocialLink(data.spotify_id, 'spotify')} style={{height: 25, width: 25}}/>
            <CustomSocialIcon url={getSocialLink(data.apple_music_id, 'appleMusic')} style={{height: 25, width: 25}}/>

            <SocialIcon url={getSocialLink(data.facebook_id, 'facebook')} style={{height: 25, width: 25}}/>
            <SocialIcon url={getSocialLink(data.instagram_id, 'instagram')} style={{height: 25, width: 25}}/>
            <SocialIcon url={getSocialLink(data.twitter_id, 'twitter')} style={{height: 25, width: 25}}/>
            <SocialIcon url={getSocialLink(data.youtube_id, 'youtube')} style={{height: 25, width: 25}}/>


        </Stack>
    )


}