import {Avatar, Paper, Typography, Chip, Stack, CardContent, Divider, Modal, Box} from "@mui/material";
import {Unstable_Grid2 as Grid} from "@mui/material";
import {LoadingSkeleton} from "./LoadingSkeleton";
import {KebabMenu} from "./KebabMenu";
import {Socials} from "./Socials";
import {Biography} from "./Biography";
import {useState} from "react";

function toDate(fieldSchema, value) {
    if (fieldSchema.type === "date") {
        const date = new Date(value);
        return date.toLocaleDateString();
    }
    return value;
}


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '16px',
};


export function ItemCard({data, schema, chips, loading, error, kebabOptions}) {
    const [open, setOpen] = useState(false);
    if (loading) return <LoadingSkeleton/>
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
       <>
        <Paper elevation={18} sx={{

            borderRadius: '16px',
            overflow: 'hidden',
            display: 'flex',
            height: '100%',
            width: '100%',
            flexDirection: 'column'
            // height: {xs: 'auto', sm: '18vh'}  // Make height auto on smaller screens
        }}
        >

            <div>
                <Avatar
                    src={data[schema ? schema.imageKey : null]}
                    alt={data[schema.idKey] || ''}
                    sx={{
                        width: '100%',
                        height: '20vh',
                        objectFit: 'cover',
                        cursor: 'pointer',  // Add cursor pointer to indicate clickability

                        // order: {xs: -1, sm: 1}
                    }}
                    onClick={handleOpen}
                    variant="square"
                />
            </div>
            <Stack spacing={1} sx={{p: 3, flexGrow: 1, minWidth: 0}}>
                <Stack direction="row" spacing={1} sx={{justifyContent: 'space-between'}}>
                    <Typography variant="h5" component="h3" sx={{fontWeight: 'bold'}}>
                        {data[schema.headerKey] || 'N/A'}
                    </Typography>
                    <KebabMenu menuOptions={kebabOptions}/>
                </Stack>

                <Typography variant="body2" sx={{color: 'text.secondary', mt: 1}} fontFamily={'var(--bfm-font)'}>
                    {data[schema.secondaryDataKey]}
                </Typography>
                <Stack direction="row" spacing={1}>
                    {chips.map((chip, index) => (
                        <Chip
                            key={index}
                            label={
                                <Typography variant={'button'}>
                                    {chip.from === 'literal' ? chip.label : data[chip.label]}
                                </Typography>
                            }
                                sx={{backgroundColor:'black', color:'white'}}
                                size={'small'}
                                />
                                ))}
                        </Stack>
                        <CardContent sx={{
                        pb: 1,
                        px: 0,
                        "&:last-child": {paddingBottom: 0},
                        display: 'flex',
                        flexDirection: 'column',

                    }}>

                    <Divider sx={{mb: '2%'}}/>
                    {schema.data.map((item, index) => (
                        <Grid container key={index} sx={{justifyContent: "space-between"}}>
                            <Typography variant="body2" color="text.primary" sx={{flexGrow: 1, fontSize: '80%'}}
                                        fontFamily={'var(--bfm-font)'}>
                                {item.label}
                            </Typography>
                            <Typography variant="body2" color="text.secondary"
                                        sx={{textAlign: "right", fontSize: '80%'}}
                                        fontFamily={'var(--bfm-font)'}

                            >
                                {data[item.field] ? toDate(item, data[item.field]) : "N/A"}
                            </Typography>
                        </Grid>
                    ))}
                </CardContent>

                <Divider/>
                <Socials data={data}/>
                <Biography data={data}/>

            </Stack>

        </Paper>
    <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
            <img
                src={data[schema ? schema.imageKey : null]}
                alt={data[schema.idKey] || ''}
                style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }}
            />
        </Box>
    </Modal>
    </>
);
}
