import React, {useState, useEffect} from "react";
import {useTracks} from "../../../Hooks/TrackHooks/useTracks";
// import {usePagination} from "../../Hooks/Utils/usePagination";
import TableWithPagination from "../../../components/Tables/TableWithPagination";
import {Link, useParams} from "react-router-dom";
import {Box, Chip, Icon, Menu, MenuItem, Paper, Stack, SvgIcon, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {FilterMenu} from "../../../components/FilterMenu";
import {LoadingSkeleton} from "../../../components/LoadingSkeleton";
import {ActionPanel} from "../../../components/ActionPanel";
import {ItemCount} from "../../../components/ItemCount";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import {Audiotrack, Copyright, Explicit, Label, Launch, MoreVert} from "@mui/icons-material";
import {InfoActionPanelWrapper} from "../../../components/InfoActionPanelWrapper";
import {BasePageTemplate} from "../../../components/BasePageTemplate";
import {TrackForm} from "../../../components/Forms/TrackForm";
import {useModal} from "../../../components/Context/ModalContext"
import {
    createMRTColumnHelper,
    MaterialReactTable, MRT_ShowHideColumnsButton,
    MRT_ToggleFullScreenButton,
    useMaterialReactTable
} from "material-react-table";
import AlbumIcon from "@mui/icons-material/Album";
import SoundRecordingIcon from "../../../components/SoundRecordingIcon";
import IconButton from "@mui/material/IconButton";

import {generateCsv, download, mkConfig} from 'export-to-csv';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Button from "@mui/material/Button";
import {LoadingTable} from "../../../components/LoadingTable";

const csvOptions = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'Products Data',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
});
const TypographyStyles = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "0.9rem",
    fontFamily: 'var(--bfm-font)'
}

export function Tracks_2() {
    const {openModal, closeModal} = useModal();
    const {artistId} = useParams();
    const [filters, setFilters] = useState({});
    const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 8});
    const [anchorEl, setAnchorEl] = useState(null);


    const initialParams = {

        artistId: artistId,
        offset: 0,
        limit: pagination.pageSize,
    }
    const {loading, error, tracks, recordCount, nextUrl, previousUrl, updateParams} = useTracks(initialParams);

    useEffect(() => {
        updateParams({
            artistId: artistId,
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
            ...filters
        });
    }, [pagination.pageIndex, pagination.pageSize, artistId, filters]);

    const filterSchema = [

        ...(!artistId ? [
            {
                name: 'artistName', label: 'Artist Name', type: 'text'
            }
        ] : []),
        {name: 'trackName', label: 'Track Name', type: 'text'},
        {name: 'isrc', label: 'ISRC', type: 'text'}];
    // {name: 'writer', label: 'Writer Name', type: 'text'}]

    const handleCreate = () => {
        openModal(<TrackForm mode={'create'}
                             artistId={artistId}/>)


    }
    const actionPanelComp = <ActionPanel

        itemName={'Track'}
        handleCreate={handleCreate}
    />

    const filterComp = <FilterMenu
        onApplyFilters={(params) => {
            setFilters(params);
            setPagination({...pagination, pageIndex: 0});
        }}
        filterSchema={filterSchema}
        initialFilters={filters}
    />

    const itemCountComp = <ItemCount
        itemCount={recordCount}
        entityType={'Tracks'}
        background={'lightgray'}
        icon={<Audiotrack fontSize={'large'} sx={{color: 'white'}}/>}
    />
    const columnHelper = createMRTColumnHelper(); // create a column helper
    function renderText(val) {
        return <Typography variant={'body1'} sx={{fontFamily: 'var(--bfm-font)'}}>{val}</Typography>
    }

    function expandedRowInfo(header, value, icon) {
        return (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                {icon && <Icon>{icon}</Icon>}
                {header && <Typography variant={'button'}>{header}:</Typography>}
                &nbsp;
                <Typography variant={'body2'}>{value}</Typography>
            </Box>
        )
    }

    function renderDuration(value) {
        const mins = parseInt(value / 60);
        const seconds = value % 60;
        if (String(seconds).length < 2) {
            return <Typography sx={{...TypographyStyles}}>{`${mins}:0${seconds}`}</Typography>
        }
        return <Typography sx={{...TypographyStyles}}>{`${mins}:${seconds}`}</Typography>
    }

    const preprocessDataForCSV = (data) => {
        return data.map(row => {
            const processedRow = {};
            for (const key in row) {
                if (typeof row[key] === 'object' && row[key] !== null) {
                    processedRow[key] = JSON.stringify(row[key]);
                } else {
                    processedRow[key] = row[key];
                }
            }
            return processedRow;
        });
    };

    const handleExportData = (data) => {
        const rowData = data.map((row) => row.original);
        const processedData = preprocessDataForCSV(rowData);
        const csv = generateCsv(csvOptions)(processedData);
        download(csvOptions)(csv);
    };

    const handleExportAllData = async () => {
        await updateParams({artistId: artistId, limit: 1000, offset: 0, ...filters});
        const processedData = preprocessDataForCSV(tracks);
        const csv = generateCsv(csvOptions)(processedData);
        download(csvOptions)(csv);

        pagination.pageSize = 8;
    };
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const table = useMaterialReactTable({
        data: tracks,
        columns: [
            ...(!artistId ? [
                columnHelper.accessor('artist_name', {
                    accessorFn: (row) => row.artist_name,
                    header: 'Artist Name',
                    grow: 1,
                    size: '250',
                    Cell: ({ renderedCellValue, row }) => (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                            }}
                        >
                            <img
                                alt="avatar"
                                height={30}
                                width={30}
                                src={row.original.artist_image_url}
                                loading="lazy"
                                style={{ borderRadius: '50%', objectFit: 'cover' }}
                            />
                            <Link to={`/masters/artist/${row.original.artist_id}`}>
                                {renderText(renderedCellValue)}
                            </Link>
                        </Box>
                    )
                })
            ] : []),
            columnHelper.accessor('primary_artists', {
                header: 'Primary Artists',
                size: '300',
                grow: 1,
                Cell: ({row}) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 1,
                        }}
                    >
                        {renderText(row.original.primary_artists)}

                    </Box>
                )

            }),


            columnHelper.accessor('track_name', {
                header: 'Track Name',
                size: '300',
                grow: 1,
                Cell: ({row}) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Link to={`/masters/tracks/${row.original.track_id}`}>
                            {renderText(row.original.name)}
                        </Link>
                    </Box>)

            }),

            columnHelper.accessor('writers', {
                header: 'Writers',
                Cell: ({row}) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 1,
                        }}
                    >
                        {row.original.writers.slice(0,5).map((writer, index) => (
                            <Chip
                                key={index}
                                label={<Typography variant="button">{writer}</Typography>}
                                sx={{backgroundColor: 'black', color: 'white', margin: '2px'}}
                                size="small"
                            />
                        ))}
                    </Box>
                )
            }),
            columnHelper.accessor('featuring', {
                header: 'Featuring',
                size: 250,
                grow: true,
                Cell: ({row}) => (
                    <Stack direction={'row'} spacing={1} width={'100%'} flexGrow={1}
                           sx={{overflow: 'auto'}}>

                        {row.original.featuring.map((feature) => (
                            <Chip
                                label={<Typography variant={'button'}>{feature}</Typography>}
                                sx={{backgroundColor: 'black', color: 'white'}}
                                size={'small'}
                            />
                        ))}
                    </Stack>
                )
            })

        ],
        manualPagination: true,
        enableFilters: false,
        rowCount: recordCount,
        enableDensityToggle: false,
        onPaginationChange: setPagination,
        state: {
            pagination,
            density: 'compact'
        },
        layoutMode: 'grid',
        enableStickyHeader: true,
        enableRowSelection: true,
        enableColumnDragging: true,

        muiPaginationProps: {
            rowsPerPageOptions: [8, 16, 32, 64, 1000]
        },

        muiTablePaperProps: ({table}) => ({
            elevation: 0,
            style: {
                zIndex: table.getState().isFullScreen ? 10000000 : undefined,
                top: table.getState().isFullScreen ? '10vh' : undefined,

            },
        }),
        renderTopToolbarCustomActions: ({table}) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                }}
            >


                <Button
                    disabled={
                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    onClick={() => handleExportData(table.getSelectedRowModel().rows)}
                    startIcon={<FileDownloadIcon/>}
                >
                    Export Selected Rows
                </Button>
            </Box>),
        positionToolbarAlertBanner: 'bottom',

        renderDetailPanel: ({row}) => {
            return (
                <Box sx={{padding: '1rem'}}>
                    <Typography variant={'h5'}>Track Versions</Typography>

                    <Stack spacing={0} direction={'column'} sx={{marginTop: '1%'}}>
                        <Stack direction={'column'} spacing={1} alignItems={'start'}>

                            {row.original.track_versions && row.original.track_versions.map((version) => (
                                <Stack direction={'row'} spacing={1}>
                                    <Link to={`/masters/trackversions/${version.isrc}`}>
                                        <IconButton size={'small'}>
                                            <Launch fontSize={'small'}/>
                                        </IconButton>
                                        <Chip
                                            label={<Typography variant={'button'}>{version.isrc}</Typography>}
                                            icon={<Audiotrack style={{color: 'white'}}/>}
                                            sx={{backgroundColor: '#309', color: 'white'}}
                                            size={'small'}
                                        />
                                    </Link>
                                    {version.track_length_seconds && expandedRowInfo('', renderDuration(version.track_length_seconds))}
                                    {version.track_version && expandedRowInfo('', version.track_version)}


                                </Stack>
                            ))}

                            {/*</Grid>*/}
                        </Stack>

                    </Stack>
                </Box>
            );
        }


    })


    const component = (
        <Paper sx={{borderRadius: '16px', overflow: 'hidden'}}>
            <Stack direction={'row'} spacing={2} display={'flex'} alignContent={'center'} p={'1%'}>
                <h1>Tracks</h1>
            </Stack>
            <Box sx={{flex: '', overflow: 'auto', flexGrow: 0, p: 2}}>
                {loading ? <LoadingTable/> : <MaterialReactTable table={table}/>}
            </Box>
        </Paper>
    )

    return (
        <BasePageTemplate
            itemCountComp={itemCountComp}
            actionPanelComp={actionPanelComp}
            filterComp={filterComp}
            contentComponent={[{comp: component}]}
        />
    )

}