import {ItemCard} from "../../../components/ItemCard";
import {useTrackVersion} from "../../../Hooks/TrackVersionHooks/useTrackVersion";
import PersonIcon from '@mui/icons-material/Person';
import {useParams} from "react-router-dom";
import {useDelete} from "../../../components/DeleteHandler";
import {useDeleteTrackVersion} from "../../../Hooks/TrackVersionHooks/useDeleteTrackVersion";
import {useModal} from "../../../components/Context/ModalContext";
import {Box, Container} from "@mui/material";
import {AppearsOn} from "./AppearsOn";
import {PageTabs} from "../../../components/Tabs/PageTabs";
import {Assets} from "../Assets/Assets";
import {CombinedTrackVersionInfoAndAppearsOn} from "./CombinedTrackVersionInfoAndAppearsOn";
import {ItemCardWrapper} from "../../../components/ItemCardWrapper";
import {TrackVersionForm} from "../../../components/Forms/TrackVersionForm";

export function TrackVersion() {
    const {isrc} = useParams();
    const {data, loading, error} = useTrackVersion(isrc);
    const {handleDelete} = useDelete();
    const {deleteTrackVersion} = useDeleteTrackVersion();
    const {openModal, closeModal} = useModal();


    const schema = {
        headerKey: 'track_name',
        imageKey: 'image_url',
        idKey: 'isrc',
        secondaryTextKey: 'track_version',
        data: [

            {label: 'ISRC', field: 'isrc'},
            {label: 'Created Date', field: 'created_datetime', type: 'date'},
            {label: 'Last Modified Date', field: 'last_modified_datetime', type: 'date'}]
    };
    const chips = [
        {label: 'Track Version', color: '--var(light-orange)', from: 'literal', icon: <PersonIcon/>},
        // {label: 'description', color: '--var(light-blue)', from: 'data'}
    ];


    const pages = [{label: 'Appears On', component: CombinedTrackVersionInfoAndAppearsOn, params: {data: data}}, {
        label: 'Assets',
        component: Assets,
        params: {data: data.assets}
    }];

    const handleEdit = () => {

        return (
            openModal(<TrackVersionForm isrc={isrc}
                                        mode={'edit'}
                                        currentData={data}
                />
            )
        )


    }


    const kebabOptions = [
        {label: 'Edit', action: handleEdit},
        {label: 'Delete', action: () => handleDelete(isrc, deleteTrackVersion)}];

    const comp = <ItemCard data={data}
                           schema={schema}
                           chips={chips}
                           kebabOptions={kebabOptions}
                           loading={loading}
                           error={error}
    />

    return (

        <>
            <>
                <Container maxWidth={'100%'} sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mt: "1%",
                    mb: '1',
                    height: '100%',
                    justifyContent: 'flex-start'
                }}>
                    <ItemCardWrapper component={comp}/>

                    <Box height={'100%'} width={'80vw'} marginLeft={'2%'}>
                        <PageTabs tabs={pages}/>
                    </Box>
                </Container>
            </>

        </>

    )


}