import React, {useState, useEffect} from "react";
import {useTracks} from "../../../Hooks/TrackHooks/useTracks";
// import {usePagination} from "../../Hooks/Utils/usePagination";
import TableWithPagination from "../../../components/Tables/TableWithPagination";
import {useParams} from "react-router-dom";
import {Paper, Stack} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {FilterMenu} from "../../../components/FilterMenu";
import {LoadingSkeleton} from "../../../components/LoadingSkeleton";
import {ActionPanel} from "../../../components/ActionPanel";
import {ItemCount} from "../../../components/ItemCount";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import {Audiotrack} from "@mui/icons-material";
import {InfoActionPanelWrapper} from "../../../components/InfoActionPanelWrapper";
import {BasePageTemplate} from "../../../components/BasePageTemplate";
import {TrackForm} from "../../../components/Forms/TrackForm";
import {useModal} from "../../../components/Context/ModalContext";

const schema = [

    {id: 'artist_image_url', label: '', primary: true, type: 'image'},
    {id: "artist_name", label: "Artist Name", primary: true, type: 'link', linkEndpoint: 'artists', linkValue: 'artist_id'},
    {id: "name", label: "Track Name", primary: true, type: 'link', linkEndpoint: 'tracks', linkValue: 'track_id'},
    {
        id: "writers",
        label: "Writers",
        primary: true,
        editable: true,
        type: "chiplist",
    },
    {
        id: "featuring",
        label: "Featuring",
        primary: true,
        editable: true,
        type: "chiplist",
    },

    {
        id: "isrc",
        label: "ISRC",
        primary: false,
        secondary: true,
        type: "link",
        linkEndpoint: "trackversions",
    },
    {id: "track_version", label: "Track Version", secondary: true},
    {
        id: "track_length_seconds",
        label: "Track Length Seconds",
        secondary: true,
        type: "track_length",
    },
    {id: "audio_language", label: "Audio Language", secondary: true},
];

export function Tracks() {
    const {openModal, closeModal} = useModal();
    const {artistId} = useParams();
    const [currentPage, setCurrentPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [filters, setFilters] = useState({});

    const initialParams = {

        artistId: artistId,
        offset: 0,
        limit: limit
    }
    const {loading, error, tracks, recordCount, nextUrl, previousUrl, updateParams} = useTracks(initialParams);
    const totalPages = Math.ceil(recordCount / limit);

    useEffect(() => {
        updateParams({artistId: artistId, ...filters, offset: currentPage * limit, limit: limit})
    }, [currentPage, limit, artistId, filters]);


    const filterSchema = [{name: 'trackName', label: 'Track Name', type: 'text'},
        {name: 'isrc', label: 'ISRC', type: 'text'},
        {name: 'writers', label: 'Writers', type: 'list'}]

    const handleCreate = () => {
        openModal(<TrackForm mode={'create'}
                             artistId={artistId}/>)


    }
    const actionPanelComp = <ActionPanel

        itemName={'Track'}
        handleCreate={handleCreate}
    />

    const filterComp = <FilterMenu
        onApplyFilters={(params) => {
            setFilters(params);
            setCurrentPage(0)
        }}
        filterSchema={filterSchema}
        initialFilters={filters}
    />

    const itemCountComp = <ItemCount
        itemCount={recordCount}
        entityType={'Tracks'}
        background={'lightgray'}
        icon={<Audiotrack fontSize={'large'} sx={{color: 'white'}}/>}
    />

    const tracksTable = <TableWithPagination data={tracks}
                                             schema={schema}
                                             recordCount={recordCount}
                                             itemsPerPageCb={setLimit}
                                             itemsPerPage={limit}
                                             currentPage={currentPage}
                                             currentPageCb={setCurrentPage}
                                             totalPages={totalPages}
                                             isNested={true}
                                             nestedRowName={'track_versions'}
                                             title={'Tracks'}
                                             filterComp={filterComp}
                                             extraDataHeader={'Track Versions'}
                                             loading={loading}/>


    return (
        <>
            <BasePageTemplate itemCountComp={itemCountComp}
                              actionPanelComp={actionPanelComp}
                              contentComponent={[{comp: tracksTable}]}/>
        </>
    );
}
