import React from "react";
import {Navigate, Outlet} from "react-router-dom";

const AuthenticatedRoute = ({user}) => {
    console.log('user', user)
    console.log('this is an authenticated route');
    if (user === undefined) {
        return null;
    }

    return user ? <Outlet/> : <p>You cannot perform this action</p>;
};

export default AuthenticatedRoute;
