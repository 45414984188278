import {useTracks} from "../../Hooks/TrackHooks/useTracks";
import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {CustomAutoComplete} from "./Elements/CustomAutoComplete";
import {SingleInputField} from "./Elements/SingleInputField";

export function TrackSelector({control, label, name}) {
    const {tracks, loading, error, refetchTracks} = useTracks({limit: 10, offset: 0});
    const [inputValue, setInputValue] = useState('');


    return (<SingleInputField control={control}
                              label={label}
                              name={name}
                              disabled={true}/>)



}