import { useState, useEffect } from "react";
import { ArtistService } from "../../Services/ArtistService";

export function useArtist(id) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchArtist = async (id = {}) => {
    setLoading(true);
    setError("");

    try {
      const responseData = await ArtistService.getOne(id);
      setData(responseData.data);
    } catch (error) {
      setError(error.message || "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArtist(id);
  }, [id]);

  const refetchArtist = () => {
    fetchArtist(id);
  };
  return { data, loading, error, refetchArtist };
}
