import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import {Stack, Unstable_Grid2 as Grid} from "@mui/material";
import {useContext} from "react";
import {NavigationContext} from "../Context/NavigationContext";

function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            key={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}

            style={{width: '100%', height: '100%'}}
            {...other}


        >
            {value === index && (
                <Box sx={{
                    p: 0,
                    display: 'flex',
                    flexDirection: 'column', // Use column to stack children vertically
                    // justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    mt: '1%',

                }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        key: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export function PageTabs({tabs = [], defaultTab, disableComponentRender = false}) {
    const {saveViewState, getViewState} = useContext(NavigationContext);
    const initialTabIndex = getViewState('pageTabsTabIndex') || defaultTab || 0;

    const [value, setValue] = React.useState(initialTabIndex);
    const [hoveredTab, setHoveredTab] = React.useState(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        saveViewState('pageTabsTabIndex', newValue);

    };

    return (
        <Box>
            <Box sx={{width: '100%'}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    TabIndicatorProps={{style: {backgroundColor: "var(--light-orange)"}}}
                >
                    {tabs.map((tab, index) => {
                        const tabStyles = {
                            color: value === index ? 'black' : hoveredTab === index ? 'white' : 'gray',
                            backgroundColor: value === index ? 'white' : hoveredTab === index ? 'var(--light-orange)' : 'inherit',
                            transition: 'background-color 0.5s',
                            textAlign: 'left'

                        };

                        return tab.link ?
                            <Link to={tab.link} style={{textDecoration: "none", color: "inherit"}} key={tab.link}>
                                <Tab
                                    label={tab.label}
                                    {...a11yProps(index)}
                                    key={index}
                                    style={tabStyles}
                                    onMouseEnter={() => setHoveredTab(index)}
                                    onMouseLeave={() => setHoveredTab(null)}
                                    sx={{textAlign: 'start'}}
                                />
                            </Link> :
                            <Tab
                                label={tab.label}
                                {...a11yProps(index)}
                                key={index + 'not-selected'}
                                style={tabStyles}
                                onMouseEnter={() => setHoveredTab(index)}
                                onMouseLeave={() => setHoveredTab(null)}
                            />
                    })}
                </Tabs>
            </Box>
            {tabs.map((tab, index) => (
                !disableComponentRender &&
                <CustomTabPanel value={value} index={index} key={index}>
                    {value === index &&
                        <tab.component {...tab.params}/>

                    }
                </CustomTabPanel>
            ))}

        </Box>
    )
        ;
}
