import {
    MaterialReactTable,
    createMRTColumnHelper,
    useMaterialReactTable, MRT_ToggleFullScreenButton, MRT_ShowHideColumnsButton,
} from 'material-react-table';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Icon,
    Paper,
    Stack,
    SvgIcon,
    Typography,
    Menu,
    MenuItem,
    IconButton
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import React, {useEffect, useState} from "react";
import {useAddProduct} from "../../../Hooks/ProductHooks/useAddProduct";
import {useModal} from "../../../components/Context/ModalContext";
import {useProducts} from "../../../Hooks/ProductHooks/useProducts";
import {ProductForm} from "../../../components/Forms/ProductForm";
import {ActionPanel} from "../../../components/ActionPanel";
import {ItemCount} from "../../../components/ItemCount";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import {FilterMenu} from "../../../components/FilterMenu";
import {BasePageTemplate} from "../../../components/BasePageTemplate";
import {Link} from "react-router-dom";
import {CheckBox, Copyright, EmergencyRecording, Explicit, Label, Print, MoreVert} from "@mui/icons-material";
import SoundRecordingIcon from "../../../components/SoundRecordingIcon";
import AlbumIcon from "@mui/icons-material/Album";
import {generateCsv, download, mkConfig} from 'export-to-csv';
import {LoadingTable} from "../../../components/LoadingTable";

// CSV configuration
const csvOptions = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'Products Data',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
});

export function Products_2({artistId, releaseId}) {
    const {addProduct} = useAddProduct();
    const [filters, setFilters] = useState({});
    const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 8});
    const [selectedRowIds, setSelectedRowIds] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

    const {openModal, closeModal} = useModal();

    const initialParams = {
        artistId: artistId,
        releaseId: releaseId,
        limit: pagination.pageSize,
        offset: 0,
    };






    const {
        products,
        recordCount,
        loading,
        error,
        refetchProducts,
        updateParams,
    } = useProducts(initialParams);

    useEffect(() => {
        updateParams({
            artistId: artistId,
            releaseId: releaseId,
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
            ...filters
        });
    }, [pagination.pageIndex, pagination.pageSize, artistId, releaseId, filters]);

    const handleCreate = () => {
        openModal(<ProductForm mode="create"
                               closeModal={closeModal}
                               releaseId={releaseId}
                               artistId={artistId}/>);
    }

    const preprocessDataForCSV = (data) => {
        return data.map(row => {
            const processedRow = {};
            for (const key in row) {
                if (typeof row[key] === 'object' && row[key] !== null) {
                    processedRow[key] = JSON.stringify(row[key]);
                } else {
                    processedRow[key] = row[key];
                }
            }
            return processedRow;
        });
    };

    const handleExportData = (data) => {
        const rowData = data.map((row) => row.original);
        const processedData = preprocessDataForCSV(rowData);
        const csv = generateCsv(csvOptions)(processedData);
        download(csvOptions)(csv);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const actionPanelComp = <ActionPanel
        handleCreate={handleCreate}
        itemName={'Product'}/>

    const itemCountComp = <ItemCount
        itemCount={recordCount}
        entityType={'products'}
        icon={<ShoppingBagIcon fontSize={'large'} sx={{color: 'white'}}/>}
    />

    function renderText(val) {
        return <Typography variant={'body1'} sx={{fontFamily: 'var(--bfm-font)'}}>{val}</Typography>
    }

    const columnHelper = createMRTColumnHelper();

    function expandedRowInfo(header, value, icon) {
        return (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                {icon && <Icon>{icon}</Icon>}
                {header && <Typography variant={'button'}>{header}:</Typography>}
                &nbsp;
                <Typography variant={'body2'}>{value}</Typography>
            </Box>
        )
    }

    const table = useMaterialReactTable({
            columns: [
                columnHelper.accessor('image_url', {
                    accessorFn: (row) => `${row.release_name}`,
                    header: 'Release Name',
                    size: 200,
                    Cell: ({renderedCellValue, row}) => (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                            }}
                        >
                            <img
                                alt="avatar"
                                height={30}
                                src={row.original.image_url}
                                loading="lazy"
                                style={{borderRadius: '50%'}}
                            />
                            <Link to={`/masters/releases/${row.original.release_id}`}>
                                {renderText(renderedCellValue)}
                            </Link>
                        </Box>
                    ),
                }),

                columnHelper.accessor('release_artist', {
                    header: 'Release Artist',
                    size: 150,
                    Cell: ({renderedCellValue, row}) => <Link
                        to={`/masters/artists/${row.original.release_artist_id}`}>{renderText(renderedCellValue)}</Link>
                }),

                columnHelper.accessor('upc', {
                    header: 'UPC',
                    size: 150,
                    accessorFn: (row) => row.upc,
                    Cell: ({renderedCellValue, row}) => (
                        <Link to={`/masters/products/${row.original.product_id}`}>
                            {renderText(renderedCellValue)}
                        </Link>
                    )
                }),
                columnHelper.accessor('release_date', {
                    header: 'Release Date',
                    size: 120,
                    Cell: ({renderedCellValue}) => renderText(renderedCellValue)
                }),
                columnHelper.accessor('catalog_number', {
                    header: 'Catalog Number',
                    size: 150,
                    Cell: ({renderedCellValue}) => renderText(renderedCellValue)
                }),
                columnHelper.accessor('product_artist_name', {
                    header: 'Product Artist Name',
                    size: 200,
                    Cell: ({renderedCellValue}) => renderText(renderedCellValue)
                }),
                columnHelper.accessor('product_version', {
                    header: 'Product Version',
                    size: 150,
                    Cell: ({renderedCellValue}) => renderText(renderedCellValue)
                }),
                columnHelper.accessor('product_format', {
                    header: 'Product Format',
                    size: 150,
                    Cell: ({renderedCellValue}) => renderText(renderedCellValue)
                }),
                columnHelper.accessor('product_type', {
                    header: 'Product Type',
                    size: 150,
                    Cell: ({renderedCellValue}) => renderText(renderedCellValue)
                }),
            ],
            data: products,
            manualPagination: true,
            enableFilters: false,
            rowCount: recordCount,
            enableDensityToggle: false,
            onPaginationChange: setPagination,
            state: {
                pagination,
                density: 'compact'
            },

            enableStickyHeader: true,
            enableRowSelection: true,
            enableColumnDragging: true,

            renderTopToolbarCustomActions: ({table}) => (
                <Box
                    sx={{
                        display: 'flex',
                        gap: '16px',
                        padding: '8px',
                        flexWrap: 'wrap',
                    }}
                >


                    <Button
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        //only export selected rows
                        onClick={() => handleExportData(table.getSelectedRowModel().rows)}
                        startIcon={<FileDownloadIcon/>}
                    >
                        Export Selected Rows
                    </Button>
                </Box>),
            muiTablePaperProps: ({table}) => ({
                elevation: 0,
                style: {
                    zIndex: table.getState().isFullScreen ? 10000000 : undefined,
                    top: table.getState().isFullScreen ? '10vh' : undefined,

                },
            }),
            positionToolbarAlertBanner: 'bottom',


            muiPaginationProps: {
                rowsPerPageOptions: [8, 16, 32, 64, 1000]
            },

            renderDetailPanel: ({row}) => {
                return (
                    <Box sx={{padding: '1rem'}}>
                        <Typography variant={'h5'}>Additional Product Info</Typography>
                        <Stack spacing={0} direction={'column'}>
                            <Stack direction={'row'} spacing={1}>
                                <AlbumIcon/>
                                {row.original.genres && row.original.genres.map((genre) => (
                                    <Chip
                                        label={<Typography variant={'button'}>{genre}</Typography>}
                                        sx={{backgroundColor: 'black', color: 'white'}}
                                        size={'small'}
                                    />
                                ))}
                            </Stack>
                            <Stack direction={'column'} spacing={1}>
                                {expandedRowInfo('', row.original.label || 'Unknown label', <Label/>)}
                                {expandedRowInfo('', `${row.original.c_line_year} ${row.original.c_line_owner}`,
                                    <Copyright/>)}
                                {expandedRowInfo('', `${row.original.p_line_year} ${row.original.p_line_owner}`,
                                    <SoundRecordingIcon/>)}
                                {row.original.is_explicit && <Explicit/>}
                            </Stack>
                        </Stack>
                    </Box>
                );
            }
        })
    ;

    const formats = ['Digital', '12 inch vinyl', '7 inch vinyl', 'CD', 'Cassette', 'Other'];

    const filterSchema = [
        {name: 'upc', label: 'UPC', type: 'text'},
        {name: 'releaseName', label: 'Release Name', type: 'text'},
        {name: 'catalogNumber', label: 'Catalog Number', type: 'text'},
        {
            name: 'productFormat',
            label: 'Product Format',
            type: 'dropdown',
            options: formats
        }
    ];

    const filterComp = <FilterMenu
        onApplyFilters={(params) => {
            setFilters(params);
            setPagination({...pagination, pageIndex: 0});
        }}
        filterSchema={filterSchema}
        initialFilters={filters}
    />

    const component = (
        <Paper sx={{borderRadius: '16px', overflow: 'hidden'}}>
            <Stack direction={'row'} spacing={2} display={'flex'} alignContent={'center'} p={'1%'}>
                <h1>Products</h1>
            </Stack>
            <Box sx={{flex: '', overflow: 'auto', flexGrow: 0, p: 2}}>
                {loading  ? <LoadingTable/> : <MaterialReactTable table={table}/>}
            </Box>
        </Paper>
    )

    return (
        <BasePageTemplate
            itemCountComp={itemCountComp}
            actionPanelComp={actionPanelComp}
            filterComp={filterComp}

            contentComponent={[{comp: component}]}
        />
    )
}
