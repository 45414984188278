import {useModal} from "../Context/ModalContext";
import {usePatchRelease} from "../../Hooks/ReleaseHooks/usePatchRelease";
import {useDeleteRelease} from "../../Hooks/ReleaseHooks/useDeleteRelease";
import {useSnackbar} from "../Context/SnackbarContext";
import {useForm, Controller} from 'react-hook-form';
import {
    TextField,
    Button,
    Typography,
    Paper,
    Snackbar,
    ButtonGroup,
    Select,
    InputLabel,
    FormControl
} from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";
import {useCreateRelease} from "../../Hooks/ReleaseHooks/useCreateRelease";
import MenuItem from "@mui/material/MenuItem";
import {ArtistSelector} from "./ArtistSelector";
import {SingleInputField} from "./Elements/SingleInputField";
import {handleItemUpdate, handleItemCreate} from "./Handlers/CrudHandler";
import {SingleDropdown} from "./Elements/SingleDropdown";
import {LoadingButton} from "@mui/lab";
import {CustomLoadingButton} from "../CustomLoadingButton";


export function ReleaseForm({releaseId, mode, currentData, artistId, callbackToPage}) {

    const {loading: processingUpdate, error: updateError, success: updateSuccess, patchRelease} = usePatchRelease();
    const {
        loading: processingCreate,
        errorMessage: createError,
        success: createSuccess,
        createRelease
    } = useCreateRelease();
    const {openSnackbar, closeSnackbar} = useSnackbar();

    const {control, handleSubmit, setValue, getValues, register} = useForm({
        defaultValues: {
            artist_id: mode === 'edit' ? currentData.artist_id : artistId || null,
            name: mode === 'edit' ? currentData.name : '',
            image_url: mode === 'edit' ? currentData.image_url : '',
            release_type: mode === 'edit' ? currentData.release_type : ''
        }
    });


    const {openModal, closeModal} = useModal();
    const handleUpdate = (data) => {
        handleItemUpdate(releaseId, data, patchRelease, openSnackbar, closeModal)
    }
    const handleCreate = (data) => {
        handleItemCreate(data, createRelease, openSnackbar, closeModal, createError)
    }


    const releaseTypes = ["Single", "Album", "EP", "Compilation", "Live", "Remix", "Mixtape", "Demo", "Other"];

    return (
        <>
            <Paper elevation={0}>
                <Typography variant={'h6'}>{mode === 'edit' ? 'Edit Release' : 'Create Release'}</Typography>
            </Paper>

            <form onSubmit={handleSubmit(mode === 'edit' ? handleUpdate : mode === 'create' ? handleCreate : null)}>

                <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                        <ArtistSelector control={control}
                                        setValue={setValue}
                                        initialValue={artistId}
                                        fieldName={'artist_id'}/>

                    </Grid>
                    <Grid item xs={12}>
                        <SingleInputField name={'name'}
                                          label={'Release Name'}
                                          control={control}/>

                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="outlined-required"
                            label="Image URL"
                            {...register("image_url")}
                            defaultValue={currentData ? currentData.image_url : ''}
                            variant="outlined"/>
                    </Grid>

                    <Grid item xs={12}>
                        <SingleDropdown options={releaseTypes}
                                        control={control}
                                        label={'Release Type'}
                                        name={'release_type'}
                                        defaultValue={currentData ? currentData.release_type : null}
                                        required={true}
                        />
                    </Grid>
                </Grid>

                <ButtonGroup
                    style={{width: '100%', justifyContent: 'space-between', marginTop: '1%', alignItems: 'baseline'}}>
                    <Button variant='contained' color='error' onClick={closeModal}>Cancel</Button>
                    <CustomLoadingButton loading={processingUpdate || processingCreate}/>
                </ButtonGroup>
            </form>
        </>
    )


}