import React, {useCallback, useEffect, useState, useRef} from 'react';
import {useReleases} from "../../Hooks/ReleaseHooks/useReleases";
import _ from "lodash";
import {CustomAutoComplete} from "./Elements/CustomAutoComplete";

export const ReleaseSelector = ({artistId, control, setValue, initialValue}) => {


    const {releases, loading, error, updateParams} = useReleases({
        artistId: artistId,
        releaseId: initialValue,
        limit: 10,
        offset: 0
    });
    const [inputValue, setInputValue] = useState(null);

    const debouncedSearch = useCallback(_.debounce((searchValue) => {
        console.log('search value', searchValue)
        updateParams({releaseName: searchValue, artistId: artistId, limit: 10, offset: 0});
    }, 500), [artistId]);

    useEffect(() => {
        if (inputValue) {
            debouncedSearch(inputValue);
        }
        return () => debouncedSearch.cancel();
    }, [inputValue]);

    useEffect(() => {
        updateParams({artistId: artistId, releaseId: initialValue, limit: 10, offset: 0});
    }, [artistId]);


    console.log('hello there i am here and I am the release selector', releases);
    console.log('and I am the artist', artistId )

    return (
        artistId && (
            <CustomAutoComplete options={releases}
                                control={control}
                                setValue={setValue}
                                setInputValue={setInputValue}
                                name="release_id"
                                label="Select Release"
                                labelKey={'name'}
                                valueKey={'release_id'}/>)
    )

};
