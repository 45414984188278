import RegularTable from "../../../components/Tables/RegularTable";

export function AppearsOn({data}) {

    const appearsOnSchema = [{label: 'Release Name', id: 'release_name', primary: true},

        // {label: 'Product Id', id: 'product_id', primary: true, type: 'link', linkEndpoint: 'products', linkValue: 'product_id'},
        {label: 'Product Version', id: 'product_version', primary: true, type: 'link', linkEndpoint: 'products', linkValue: 'product_id'},
        {label: 'Disc Number', id: 'disc_number', primary: true},
        {label: 'Track Number', id: 'track_number', primary: true},
        {label: 'Release Date', id: 'release_date', primary: true, type: 'date'},
        {label: 'Catalog Number', id: 'catalog_number', primary: true},

    ];


    return (

        <>
            <RegularTable data={data}
                          schema={appearsOnSchema}
                          nestedRowName={''}
                          pageType={'Appears On'}/>

        </>
    )


}