import {Box, Paper, Stack} from "@mui/material";
import React from "react";
import {InfoActionPanelWrapper} from "./InfoActionPanelWrapper";
import Grid from "@mui/material/Unstable_Grid2";

export function BasePageTemplate({actionPanelComp, itemCountComp, contentComponent, filterComp}) {
    return (
        <Paper
            elevation={3}
            sx={{
                padding: {xs: 2, md: 4},
                marginBottom: 2,
                borderRadius: '16px',
                background: '#333',
                overflow: 'hidden',
                justifyContent: 'center'
            }}
        >
            <Box>
                <Box display="flex" flexDirection="column" gap={2}>
                    {actionPanelComp &&  (
                        <Box display="flex" flexDirection="column" gap={2}>
                            <Box>{actionPanelComp}</Box>
                        </Box>
                    )}
                    <Grid container spacing={3}>
                        {itemCountComp &&
                            <>
                                <Grid xs={12} md={3} spacing={2}
                                      sx={{display: 'flex', flexDirection: 'column', height: '100%'}}

                                >
                                    <InfoActionPanelWrapper itemCountComponent={itemCountComp}
                                                            filterComponent={filterComp}/>

                                </Grid>

                            </>

                        }

                        {/*<Grid xs={12} md={9}>*/}
                        {/*    <Grid container spacing={2}>*/}
                                {contentComponent.map((comp, index) => (
                                    <Grid xs={12} key={index} md={comp.size|| 9}>
                                        {comp.comp}
                                    </Grid>
                                ))}
                            </Grid>
                        {/*</Grid>*/}

                    {/*</Grid>*/}
                </Box>
            </Box>
        </Paper>
    )
        ;
}
