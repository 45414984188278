import {request} from "../components/Handlers/RequestHandler";

const postHeaders = {
    "Content-Type": "application/json",
};

export const ProductService = {
    create(newProduct) {
        return request("POST", "/products", newProduct, null, postHeaders);
    },

    getAll(params) {
        return request("GET", "/products", null, params);
    },

    getOne(id) {
        return request("GET", `/products/${id}`, null);
    },

    update(id, updatedProduct) {
        return request(
            "PATCH",
            `/products/${id}`,
            updatedProduct,
            null,
            postHeaders,
        );
    },

    getTracklist(id) {
        return request("GET", `/products/${id}/tracklist`, null);
    },

    updateTracklist(id, tracklist) {
        return request(
            "PATCH",
            `/products/${id}/tracklist`,
            {tracklist: tracklist},
            null,
            postHeaders,
        );
    },

    delete(id, params) {
        return request("DELETE", `/products/${id}`, null, params);
    },
};
