import {ItemCard} from "../../../components/ItemCard";
import {useTrack} from "../../../Hooks/TrackHooks/useTrack";
import {useParams} from "react-router-dom";
import {Box, Container} from "@mui/material";
import {PageTabs} from "../../../components/Tabs/PageTabs";
import {Assets} from "../Assets/Assets";
import {CombinedTrackInfoAndVersions} from "./CombinedTrackInfoAndVersions";
import {useModal} from "../../../components/Context/ModalContext";
import {memo, useEffect} from "react";
import {ItemCardWrapper} from "../../../components/ItemCardWrapper";
import {TrackForm} from "../../../components/Forms/TrackForm";
import {useDeleteTrack} from "../../../Hooks/TrackHooks/useDeleteTrack";
import {useSnackbar} from "../../../components/Context/SnackbarContext";
import {useNavigate} from "react-router-dom";
import {useDelete} from "../../../components/DeleteHandler";
import {useCreate} from "../../../Hooks/useAdd";
import {TracksService} from "../../../Services/TracksService";
import {TrackVersionService} from "../../../Services/TrackVersionService";


const Track = memo(function Track() {
    const {navigate} = useNavigate();
    const {trackId} = useParams();
    const {data, loading, error} = useTrack(trackId);
    const {openModal, closeModal} = useModal();
    const {openSnackbar} = useSnackbar();
    const {deleteTrack, loading: deletionStatus, error: deletionError, success: deleteSuccess} = useDeleteTrack();
    const {
        loading: tvCreateLoading,
        success: tvCreateSuccess,
        error: tvCreateError,
        create
    } = useCreate(TrackVersionService)
    const {handleDelete} = useDelete();


    const schema = {
        headerKey: 'name',
        imageKey: 'artist_image_url',
        idKey: 'track_id',
        secondaryDataKey: 'release_type',
        data: [

            {label: 'Track Id', field: 'track_id'},
            {label: 'Created Date', field: 'created_datetime', type: 'date'},
            {label: 'Last Modified Date', field: 'last_modified_datetime', type: 'date'}]
    };
    const chips = [
        {label: 'Track', color: '--var(light-orange)', from: 'literal'},
    ];
    useEffect(() => {
        console.log('Track Component Mounted');
        return () => {
            console.log('Track Component Unmounted');
        };
    }, []);

    useEffect(() => {
        if (deleteSuccess) {
            openModal('Track Deleted Successfully');
            closeModal();
        } else if (deletionError) {
            openSnackbar('Failed to delete track: ' + deletionError);
        }
    }, [deletionError, deletionError])




    const kebabOptions = [{
        label: 'Edit', action: () => openModal(<TrackForm mode={'edit'}
                                                          currentData={data}/>)
    }, {
        label: 'Delete', action: () => handleDelete(trackId, deleteTrack)
    }];

    const pages = [{
        label: 'Track Versions',
        component: CombinedTrackInfoAndVersions,
        params: {data: data, parentTrackId: trackId}
    },
        {label: 'Assets', component: Assets}];

    const comp = <ItemCard data={data}
                           schema={schema}
                           chips={chips}
                           loading={loading}
                           kebabOptions={kebabOptions}/>

    return (


        <Container maxWidth={'100%'} sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: "1%",
            mb: '1',
            height: '100%',
            justifyContent: 'flex-start'
        }}>
            <ItemCardWrapper component={comp}/>
            <Box height={'100%'} width={'75vw'} marginLeft={'2%'}>
                <PageTabs tabs={pages}/>
            </Box>
        </Container>

    );

})

export {Track}