import { useState, useEffect } from "react";
import { ArtistService } from "../../Services/ArtistService";

export function useArtists(initialParams = {}) {
  const [data, setData] = useState({
    artists: [],
    recordCount: 0,
    nextUrl: null,
    previousUrl: null,
  });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState(initialParams);
  const [error, setError] = useState("");

  console.log(initialParams);
  const formatResponse = (response) => {
    return {
      artists: response.artists,
      recordCount: response.record_count,
      nextUrl: response.next_url,
      previousUrl: response.previous_url,
    };
  };



  const fetchArtists = async (queryParameters = {}) => {
    setLoading(true);
    setError("");
    // await setDelay(1000);

    try {
      const response = await ArtistService.getAll(queryParameters);


      setData(formatResponse(await response.data));
    } catch (error) {
      setError(error.message || "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArtists(params);
  }, [params]);

  const refetchArtists = (newParams = {}) => {
    setParams(newParams);
  };

  const updateParams = (newParams = {}) => {
    setParams(() => ({ ...newParams }));
  };

  return { ...data, loading, error, refetchArtists, updateParams };
}
