import {Skeleton, Stack} from "@mui/material";
import "../../src/Styles/InfoCard.css"

export function LoadingItemCount() {
    return (
        <Stack spacing={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>

            <div className="lds-ripple">
                <div></div>
                <div></div>
            </div>

        </Stack>)


}