import { useEffect, useState, useRef } from "react";
import { TracksService } from "../../Services/TracksService";

export function useTracks(initialParams = {}) {
  const [data, setData] = useState({
    tracks: [],
    recordCount: 0,
    nextUrl: null,
    previousUrl: null,
  });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState(initialParams);
  const [error, setError] = useState("");

  const formatResponse = (response) => {
    return {
      tracks: response.tracks,
      recordCount: response.record_count,
      nextUrl: response.next_url,
      previousUrl: response.previous_url,
    };
  };

  useEffect(() => {
    const fetchTracks = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await TracksService.getAll(params);
        console.log('formatting response')
        const formattedData = formatResponse(response.data);
        console.log("Formatted Data:", formattedData); // Log the formatted data
        setData(formattedData);
        setLoading(false);
        console.log('here')
      } catch (error) {
        console.log("ERROR during fetch:", error);
        setError(error.message || "An unexpected error occurred");
      }
    };

    fetchTracks();
  }, [params]);
  const refetchTracks = (newParams = {}) => {
    setParams(newParams);
  };

  const updateParams = (newParams = {}) => {
    console.log("calling update params");
    setParams(newParams);
  };

  return { ...data, loading, error, refetchTracks, updateParams };
}
