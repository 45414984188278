import {AssetService} from "../../Services/AssetService";
import {useState} from "react";

export function useDeleteAssets() {
    const [status, setStatus] = useState({loading: false, error: null, success: false});


    const deleteAssets = async (id, data) => {
        setStatus({loading: true, error: null, success: false});
        try {
            const responseData = await AssetService.delete(id, data)
            if (responseData.status === 202) {
                setStatus({loading: false, error: null, success: true});
                console.log(responseData.data)
            } else {
                throw new Error(responseData.message || "An unexpected error occurred");
            }
        } catch (error) {
            setStatus({loading: false, error: error.toString(), success: false});
        }
    };

    return {deleteAssets, ...status}
}