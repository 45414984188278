import {useState, useEffect} from "react";
import {TrackVersionService} from "../../Services/TrackVersionService";

export function useTrackVersion(isrc) {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    function setDelay(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

    const fetchTrackVersion = async (isrc = {}) => {
        setLoading(true);
        setError("");
        await setDelay(1000);

        try {
            const responseData = await TrackVersionService.getOne(isrc);
            setData(responseData.data);
        } catch (error) {
            setError(error.message || "An unexpected error occurred");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTrackVersion(isrc);
    }, [isrc]);


    const refetchTrackVersion = () => {
        fetchTrackVersion(isrc);
    };


    return {data, loading, error, refetchTrackVersion};
}
