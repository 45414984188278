import './App.css';
import AppRoutes from "./AppRouter/AppRoutes";
import NavigationBar from "./components/AppBar";
import Breadcrumbs from "./components/Breadcrumbs";
import {getAuth, onAuthStateChanged} from "firebase/auth";


import {createTheme, ThemeProvider} from '@mui/material/styles';
import {SnackbarProvider} from "./components/Context/SnackbarContext";
import {ModalProvider} from "./components/Context/ModalContext";
import {useEffect, useState} from "react";
import {LoadingSkeleton} from "./components/LoadingSkeleton";
import SignInScreen from "./components/SignInScreen";
import {AudioProvider} from "./components/Context/AudioContext";
import {BrowserRouter} from "react-router-dom";
import {NavigationProvider} from "./components/NavigationProvider";

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,  // small phone
            sm: 600,  // tablets
            md: 900,  // small laptop
            lg: 1200,  // desktop
            xl: 1536,  // large screens
            xxl: 1920  // ultra-wide monitors
        },
    },
});


function App() {
    const [user, setUser] = useState(undefined);


    useEffect(() => {
        if (process.env.REACT_APP_AUTHENTICATE === "true") {
            const auth = getAuth();
            const unregisterAuthObserver = onAuthStateChanged(auth, setUser);
            return () => unregisterAuthObserver();
        } else {
            setUser({name: "Local User", email: "local@example.com"});
        }
    }, []);

    if (user === undefined) {
        return <LoadingSkeleton/>;
    }


    return (
        <div className="App">


            <AudioProvider>
                <NavigationProvider>
                    <BrowserRouter>

                        {user ? (

                            <SnackbarProvider>
                                <ModalProvider>
                                    <ThemeProvider theme={theme}>
                                        <NavigationBar/>
                                        <Breadcrumbs/>

                                        <AppRoutes user={user}/>

                                    </ThemeProvider>
                                </ModalProvider>


                            </SnackbarProvider>
                        ) : <SignInScreen/>
                        }


                    </BrowserRouter>
                </NavigationProvider>

            </AudioProvider>
        </div>
    );
}

export default App;
