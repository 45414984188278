import Typography from "@mui/material/Typography";

export function Biography({data}) {


    return (
        <>
            {data.bio && (
                <>
                    <Typography variant={'h6'}>Biography</Typography>
                    <Typography variant={'body1'} fontFamily={'var(--bfm-font)'}
                                fontSize={'small'}>{data.bio}</Typography>
                </>)
            }

        </>

    )
}