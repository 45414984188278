import { request } from "../components/Handlers/RequestHandler";

const postHeaders = {
  "Content-Type": "application/json",
};

export const ReleaseService = {
  create(newRelease) {
    return request("POST", "/releases", newRelease, null, postHeaders);
  },

  getAll(params) {
    return request("GET", "/releases", null, params, null);
  },

  getOne(id) {
    return request("GET", `/releases/${id}`, null);
  },

  update(id, updatedRelease) {
    return request("PATCH", `/releases/${id}`, updatedRelease, null, postHeaders);
  },

  delete(id, params) {
    return request("DELETE", `/releases/${id}`, null, params);
  },
};
