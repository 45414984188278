import {Artists} from "../Artists/Artists";
import {Releases} from "../Releases/Releases";
import {Tracks} from "../Tracks/Tracks";
import {PageTabs} from "../../../components/Tabs/PageTabs";
import {Products} from "../Products/Products";
import {SpotifyPlaylist} from "../../../components/SpotifyPlaylist";
import {Box, Container, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import MediaPlayer from "../../../components/MediaPlayer";

export function MastersHome() {

    const tabs = [
        {label: 'Home'},
        {
            label: "Artists",
            component: Artists,
            link: '/masters/artists'
        },
        {
            label: "Releases",
            component: Releases,
            link: '/masters/releases'
        },

        {label: 'Products', component: Products, link: '/masters/products'},
        {
            label: 'Tracks',
            component: Tracks,
            link: '/masters/tracks'
        }];


    return (
        <>

            <Container maxWidth={'100%'} >
                {/*<Box className={'hspace'}>*/}
                {/*    <Typography variant="h1" sx={{marginTop: "1%", marginBottom: "0%"}}>Home</Typography>*/}
                {/*</Box>*/}
                <Box marginTop={'1%'}/>

                <PageTabs tabs={tabs} disableComponentRender={true}/>
                <div style={{marginBottom:'2%'}}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <h1>Playlist</h1>
                        <SpotifyPlaylist/>
                    </Grid>
                </Grid>
            </Container>

        </>


    )


}


