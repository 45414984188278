import React from "react";
import {Routes, Route} from "react-router-dom";
import {MastersHome} from "../pages/Masters/MainPages/Home";
import {Artist} from "../pages/Masters/Artists/Artist";
import {Release} from "../pages/Masters/Releases/Release";
import {ArtistsPage} from "../pages/Masters/MainPages/ArtistsPage";
import {Product} from "../pages/Masters/Products/Product";
import {ProductsPage} from "../pages/Masters/MainPages/ProductsPage";
import {TracksPage} from "../pages/Masters/MainPages/TracksPage";
import {ReleasesPage} from "../pages/Masters/MainPages/ReleasesPage";
import {Track} from "../pages/Masters/Tracks/Track";
import {TrackVersion} from "../pages/Masters/TrackVersions/TrackVersion";
import AuthenticatedRoute from "../components/AuthenticatedRoute";


const AppRoutes = ({user}) => {

    return (
        <>
                <Routes>
                    <Route element={<AuthenticatedRoute user={user}/>}>
                        <Route path="/masters" element={<MastersHome/>}/>
                        <Route path="/masters/artists" element={<ArtistsPage/>}/>
                        <Route path="/masters/artists/:artistId" element={<Artist/>}/>
                        <Route path="/masters/releases" element={<ReleasesPage/>}/>
                        <Route path="/masters/releases/:releaseId" element={<Release/>}/>
                        <Route path="/masters/products" element={<ProductsPage/>}/>
                        <Route path="/masters/products/:productId" element={<Product/>}/>
                        <Route path="/masters/tracks" element={<TracksPage/>}/>
                        <Route path="/masters/trackversions/:isrc" element={<TrackVersion/>}/>
                        <Route path="/masters/tracks/:trackId" element={<Track/>}/>
                    </Route>
                </Routes>
        </>
    )
};

export default AppRoutes;
