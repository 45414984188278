import React, {useState} from 'react';
import {
    Avatar,
    Button,
    Checkbox,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import {
    Download as DownloadIcon,
    Folder as FolderIcon,
    FolderOpen as FolderOpenIcon,
    InsertDriveFile as FileIcon,
    PauseCircleFilled as PauseIcon,
    PlayCircleFilled as PlayIcon
} from '@mui/icons-material';
import {useAudio} from '../Context/AudioContext';
import {UploadService} from "../../Services/UploadService";
import {imageFetcher, ImageFetcher} from "../ImageFetcher";
import {ImagePreviewer} from "../ImagePreviewer";

export async function getSignedUrl(blob) {
    const data = await UploadService.getSignedUrl(blob);
    return data.data.signed_url;
}


export const FileNode = ({name, children, path, setSelected, selected}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [signedUrl, setSignedUrl] = useState(null);
    const isFile = children?._isFile;
    const {toggleAudio, isPlaying, audioUrl} = useAudio();
    const isAudio = children.blob && children.blob.match(/\.(mp3|wav|ogg)$/);
    const isImage = children.blob && children.blob.match(/\.(jpeg|jpg|tif)$/);


    const url = isImage && imageFetcher(children.blob);

    const handleToggle = () => {
        if (!isFile) {
            setIsOpen(!isOpen);
        }
    };


    const fetchAndUseUrl = async (action) => {
        try {
            const url = signedUrl || await getSignedUrl(children.blob);
            console.log(url);
            setSignedUrl(url);

            if (action === 'play') {
                toggleAudio(url);
            } else if (action === 'download') {
                window.location.href = url;
            }
        } catch (error) {
            console.error('Error fetching signed URL:', error);
        }
    };

    const handleSelect = () => {
        if (selected.includes(path)) {
            setSelected(selected.filter(item => item !== path));
        } else {
            setSelected([...selected, path]);
        }
    };

    const handlePlayPause = (event) => {
        event.stopPropagation();
        fetchAndUseUrl('play');
    };

    const handleDownload = (event) => {
        event.stopPropagation();
        fetchAndUseUrl('download');
    };

    return (
        <>
            <TableRow onClick={handleToggle}>
                <TableCell>
                    {isFile && (
                        <Checkbox
                            checked={selected.includes(path)}
                            onClick={handleSelect}
                        />
                    )}
                    <IconButton size="small">
                        {isFile ? <FileIcon/> : (isOpen ? <FolderOpenIcon/> : <FolderIcon/>)}
                    </IconButton>
                    {name}
                </TableCell>
                {isFile ? (
                    <TableCell align="right">
                        <Button
                            size="small"
                            onClick={handleDownload}
                            startIcon={<DownloadIcon/>}
                        >
                            Download
                        </Button>
                        {isAudio && (
                            <IconButton onClick={handlePlayPause} size="small">
                                {(isPlaying && audioUrl === signedUrl) ? <PauseIcon/> : <PlayIcon/>}
                            </IconButton>
                        )}
                        {isImage && (
                            <ImagePreviewer imagePath={children.blob}/>)
                        }
                    </TableCell>
                ) : (
                    <TableCell/>
                )}
            </TableRow>
            {!isFile && isOpen && (
                <TableRow>
                    <TableCell style={{paddingLeft: 20}} colSpan={2}>
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            <Table size="small" component="div">
                                <TableBody component="div">
                                    {Object.keys(children).some(key => children[key]._isFile) && (
                                        <TableRow>
                                            <TableCell><Typography variant="overline">Filename</Typography></TableCell>
                                            <TableCell align="right"><Typography variant="overline">Action</Typography></TableCell>
                                        </TableRow>
                                    )}
                                    {Object.keys(children).map(key => (
                                        <FileNode key={key} name={key} children={children[key]}
                                                  path={`${path}/${key}`} setSelected={setSelected}
                                                  selected={selected}/>
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )
            }
        </>
    )
        ;
};
