import {Skeleton, Stack} from "@mui/material";

export function LoadingSkeleton() {


    return (
        <Stack spacing={1}>
            <Skeleton variant="rounded" width={'20vw'} height={'20vh'}/>
            <Skeleton variant="rounded" width={'15vw'} height={'3vh'}/>
            <Stack direction="row" spacing={1}>
                <Skeleton variant="rounded" width={'3vw'} height={'2vh'}/>
                <Skeleton variant="rounded" width={'3vw'} height={'2vh'}/>
            </Stack>
            <Skeleton variant="rounded" width={'15vw'} height={'1vh'}/>
            <Skeleton variant="rounded" width={'15vw'} height={'3vh'}/>
            <Skeleton variant="rounded" width={'15vw'} height={'3vh'}/>
            <Skeleton variant="rounded" width={'15vw'} height={'3vh'}/>
            <Skeleton variant="rounded" width={'15vw'} height={'1vh'}/>

        </Stack>)


};

