import React from "react";
import {getAuth} from "firebase/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import "firebase/compat/auth";
import app from "../FirebaseConfig"
import {Box, Container, Paper, Typography} from "@mui/material";
// import app from "../components/firebase/FirebaseConfig";

const uiConfig = {
    signInFlow: "popup",
    signInOptions: ["google.com"],
    signInSuccessUrl: "/home",
};

function SignInScreen() {
    return (
        <>
            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.default',
                }}
            >
                <Container component="main" maxWidth="sm">
                    <Paper elevation={3} sx={{p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Box
                            component="img"
                            src="/bfm_no_border.jpg"
                            alt="Bella Figura Music RMS Logo"
                            sx={{width: 100, height: 100, mb: 3}}
                        />
                        <Typography component="h1" variant="h4" gutterBottom>
                            Welcome to the Bella Figura RMS
                        </Typography>
                        <Typography variant="body1" color="text.secondary" sx={{mb: 4}}>
                            Please sign in to continue
                        </Typography>
                        <Box sx={{width: '100%'}}>
                            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth(app)}/>
                        </Box>
                    </Paper>
                </Container>
            </Box>>
        </>
    );
}

export default SignInScreen;
