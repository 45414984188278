import {ArtistSelector} from "./ArtistSelector";
import {useForm} from "react-hook-form";
import {useCreate} from "../../Hooks/useAdd";
import {TracksService} from "../../Services/TracksService";
import {SingleInputField} from "./Elements/SingleInputField";
import {CustomLoadingButton} from "../CustomLoadingButton";
import {useSnackbar} from "../Context/SnackbarContext";
import {usePatch} from "../../Hooks/usePatch";
import {useModal} from "../Context/ModalContext";
import {useEffect} from "react";
import {MultiChipInput} from "./Elements/MultiChipInput";
import Grid from "@mui/material/Unstable_Grid2";
import {ButtonGroup} from "@mui/material";
import Button from "@mui/material/Button";

export function TrackForm({mode, currentData, artistId}) {

    const {loading, error, success, create} = useCreate(TracksService);
    const {loading: processingUpdate, error: updateError, success: updateSuccess, patch} = usePatch(TracksService);
    const {openSnackbar} = useSnackbar();
    const {closeModal} = useModal();


    const {control, handleSubmit, setValue, getValues, watch, reset} = useForm({
        defaultValues: mode === 'edit' ? currentData : {artist_id: artistId}

    });

    useEffect(() => {
        if (updateSuccess) {
            openSnackbar('Track Updated Successfully');
            closeModal();
        } else if (updateError) {
            openSnackbar('Failed to update track: ' + updateError);
        }
    }, [updateSuccess, updateError]);

    useEffect(() => {
        if (success) {
            openSnackbar('Track Created Successfully');
            closeModal();
        } else if (error) {
            openSnackbar('Failed to create track: ' + error);
        }
    }, [success, error]);

    const handleCreate = async (data) => {

        openSnackbar('Creating Track...')
        try {
            await create(data);
        } catch (error) {
            openSnackbar('Failed to create track: ' + error)
        }
    }

    const handleEdit = async (data) => {
        openSnackbar('Updating Track...')
        try {
            await patch(currentData.track_id, data);
        } catch (error) {
            openSnackbar('Failed to update track: ' + error)
        }
    };

    console.log('currentData', currentData)
    return (
        <>

            <form onSubmit={handleSubmit(mode === 'create' ? handleCreate : handleEdit)}>
                <Grid container spacing={2}>
                    <Grid xs={6}>
                        <ArtistSelector control={control}
                                        setValue={setValue}
                                        artistId={artistId}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <SingleInputField control={control}
                                          label="Primary Artists"
                                          name="primary_artists"
                                          defaultValue={currentData?.primary_artists}/>
                    </Grid>
                    <Grid xs={6}>
                        <SingleInputField control={control}
                                          label="Track Title"
                                          name="name"
                                          defaultValue={currentData?.name}
                                          required={true}/>
                    </Grid>

                    <Grid xs={6}>
                        <MultiChipInput control={control}
                                        label={'Writers'}
                                        name={'writers'}/>
                    </Grid>

                    <Grid xs={6}>
                        <MultiChipInput control={control}
                                        label={'Featuring'}
                                        name={'featuring'}
                        />
                    </Grid>

                </Grid>
                <ButtonGroup>
                    <Button type={'cancel'} onClick={() => {
                        closeModal();
                        reset();
                    }}>Cancel</Button>
                    <CustomLoadingButton loading={loading || processingUpdate}/>
                </ButtonGroup>


            </form>


        </>
    )


}