import {getDownloadURL, getStorage, ref} from "firebase/storage";
import {useState} from "react";

const BUCKET_NAME = 'gs://bfm-digital-master-store';

export function imageFetcher(imagePath) {
    const storage = getStorage();
    const starsRef = ref(storage, imagePath)
    return getDownloadURL(starsRef)

}


