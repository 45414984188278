import React, {useCallback, useEffect, useState} from 'react';
import {useArtists} from "../../Hooks/ArtistHooks/useArtists";
import _ from 'lodash';
import {CustomAutoComplete} from "./Elements/CustomAutoComplete";

export const ArtistSelector = ({control, setValue, artistId, fieldName = 'artist_id', disabled}) => {
    const [inputValue, setInputValue] = useState('');
    const {artists, updateParams, loading, refetchArtists} = useArtists({artistId: artistId, limit: 10, offset: 0});

    const debouncedSearch = useCallback(_.debounce((searchValue) => {
        refetchArtists({artistName: searchValue, limit: 10, offset: 0});
    }, 500), [inputValue]);

    useEffect(() => {
        if (inputValue) {
            debouncedSearch(inputValue);
        }
        return () => debouncedSearch.cancel();
    }, [inputValue]);


    console.log('artistId in artist selector', artistId)
    return (
        <CustomAutoComplete setValue={setValue}
                            options={artists}
                            control={control}
                            name={fieldName}
                            label="Select Artist"
                            labelKey={'name'}
                            valueKey={'artist_id'}
                            setInputValue={setInputValue}
                            disabled={disabled}/>

    );
};
