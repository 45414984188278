import {Grid, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {Controller} from "react-hook-form";

const genres = ['Ambient',
    'Classical',
    'Country',
    'Disco',
    'EDM',
    'Folk',
    'Funk',
    'Gospel',
    'Grime',
    'Grunge',
    'Heavy Metal',
    'Hip-Hop & Rap',
    'House',
    'Indie Rock',
    'Jazz',
    'Latin Music',
    'Pop',
    'Psychedelic Rock',
    'Punk Rock',
    'R&B',
    'Reggae',
    'Rock',
    'Soul',
    'Techno',
    'Trap']

export function GenrePicker({control, name, defaultValue, required = false}) {

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue || []} // Ensure defaultValue is an array
            render={({field: { onChange, value }, fieldState: { error } }) => (
                <Autocomplete
                    multiple
                    options={genres}
                    value={value || []} // Ensure value is an array
                    onChange={(event, newValue) => {
                        onChange(newValue);
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            error={!!error}
                            helperText={error ? error.message : null}
                            label="Genre"
                            variant="outlined"
                        />
                    }
                />
            )}
        />
    )

}