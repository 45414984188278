import { useEffect, useState, useRef } from "react";
import {TrackVersionService} from "../../Services/TrackVersionService";

export function useTrackVersions(initialParams = {}) {
    const [data, setData] = useState({
        tracks: [],
        recordCount: 0,
        nextUrl: null,
        previousUrl: null,
    });
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState(initialParams);
    const [error, setError] = useState("");

    const formatResponse = (response) => {

        return {
            tracks: response.tracks,
            recordCount: response.record_count,
            nextUrl: response.next_url,
            previousUrl: response.previous_url,
        };
    };

    useEffect(() => {
        const fetchTracks = async () => {
            setLoading(true);
            setError("");
            // try {
                const responseData = await TrackVersionService.getAll(params);
                const formattedData = formatResponse(responseData.data);

                setData(formattedData);

                setLoading(false);
            // } catch (error) {
            //     console.log("ERROR during fetch:", error);
            //     setError(error.message || "An unexpected error occurred");
            // }
        };

        fetchTracks();
    }, [params]);
    const refetchTracks = (newParams = {}) => {
        setParams(newParams);
    };

    const updateParams = (newParams = {}) => {
        console.log("calling update params");
        setParams((prevParams) => ({ ...prevParams, ...newParams }));
    };

    return { ...data, loading, error, refetchTracks, updateParams };
}
