import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

export function SingleDropdown({ label, name, control, options, defaultValue, required = false, handleChange }) {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
                <FormControl fullWidth>
                    <InputLabel id={`${name}-label`}>{label}</InputLabel>
                    <Select
                        {...field}
                        labelId={`${name}-label`}
                        label={label}
                        required={required}
                        onChange={(event) => {
                            field.onChange(event);
                            if (handleChange) {
                                handleChange(event);
                            }
                        }}
                        variant="outlined"
                        fullWidth
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {options.map((option, index) => (
                            <MenuItem key={index} value={option.value || option}>
                                {option.label || option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        />
    );
}
