import React from "react";
import {Checkbox, Chip, Stack, Typography} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import ExplicitIcon from '@mui/icons-material/Explicit';
import Grid from "@mui/material/Unstable_Grid2";
import {StyledLink} from "./StyledLink";
import IconButton from "@mui/material/IconButton";
import {PlayCircle} from "@mui/icons-material";


let dev = "http://localhost:3000/masters/";
if (process.env.NODE_ENV === 'production') {
    dev = 'https://bfm-sandbox.web.app/masters/'
}


const TableCellRenderer = ({column, value, row}) => {


    const TypographyStyles = {
        // overflow: "hidden",
        // textOverflow: "ellipsis",
        // whiteSpace: "nowrap",
        fontSize: "0.9rem",
        fontFamily: 'var(--bfm-font)'
    }


    switch (column.type) {


        case "boolean":
            return <Checkbox disabled checked={value} color="primary" sx={{alignSelf: 'start'}}/>;
        case "date":
            return <Typography sx={{...TypographyStyles}}>{dayjs(value).format("DD/MM/YYYY")}</Typography>;
        case "image":
            return (
                <img
                    src={value}
                    alt={column.id}
                    style={{width: 40, height: 40, borderRadius: "50%"}}
                />
            );

        case "list":
            return (
                <>
                    {value?.map((v, index) => (
                        <li key={index}>{v}</li>
                    ))}
                </>
            );
        case 'chiplist':
            return (
                <>
                    <Grid container spacing={1} className="custom-scrollbar" sx={{display: 'flex'}}>
                        {value?.map((v, index) => (
                            <Grid item key={index} xs="auto" sx={{flexShrink: 0}}>
                                <Chip
                                    label={
                                        <Typography variant="button" sx={{color: 'white'}}>
                                            {v}
                                        </Typography>
                                    }
                                    variant="filled"
                                    size="small"
                                    sx={{color: 'white', backgroundColor: 'black'}}


                                />
                            </Grid>
                        ))}
                        {/*<div*/}
                        {/*    style={{*/}
                        {/*        whiteSpace: 'nowrap',*/}
                        {/*        overflow: 'hidden',*/}
                        {/*        textOverflow: 'ellipsis',*/}
                        {/*        flexGrow: 1*/}
                        {/*    }}*/}
                        {/*></div>*/}
                    </Grid>
                </>
            )
        case "link":

            const val = column?.linkValue ? row[column?.linkValue] : value;
            return (
                <StyledLink
                    to={`${dev}${column?.linkEndpoint}/${val}`}
                >
                    {value || null}
                </StyledLink>

                // <Link
                //     type={'button'}
                //
                //     maxWidth={'10px'}
                //     href={`${dev}${column.linkEndpoint}/${column.linkValue ? column.linkValue : value}`}
                //     underline="hover"
                //     color="primary"
                //     overflow={"hidden"}
                //     textOverflow={"ellipsis"}
                //     whiteSpace={
                //         "nowrap"}
                //     style={
                //         {
                //             ...TypographyStyles,
                //             maxWidth: '5vw !important',
                //
                //
                //             cursor:
                //                 "pointer",
                //             ":hover":
                //                 {
                //                     textDecoration: "underline",
                //                     color:
                //                         "var(--light-orange)",
                //                 }
                //             ,
                //         }
                //     }
                // >
                //     {
                //         value
                //     }
                // </Link>
            )
                ;
        case
        "explicit"
        :

            return (value ? <ExplicitIcon fontSize={"small"}/> : null)
        case
        "track_length"
        :
            const mins = parseInt(value / 60);
            const seconds = value % 60;
            if (String(seconds).length < 2) {
                return <Typography sx={{...TypographyStyles}}>{`${mins}:0${seconds}`}</Typography>
            }
            return <Typography sx={{...TypographyStyles}}>{`${mins}:${seconds}`}</Typography>

        case
        "audio"
        :
            return (
                value &&
                <Stack direction="row" spacing={1}>
                    <IconButton
                        onClick={() => column.audioHandler(value)}>
                        <PlayCircle/>


                    </IconButton>
                    {/*<Chip*/}
                    {/*    label="Play"*/}

                    {/*    color="primary"*/}
                    {/*/>*/}
                </Stack>

            );

        default:
            return <Typography
                sx={TypographyStyles}
            >
                {value}
            </Typography>

    }
};

export default TableCellRenderer;
