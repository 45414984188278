import Grid from "@mui/material/Unstable_Grid2";
import {TrackInfo} from "./TrackInfo";
import {TrackVersions} from "../TrackVersions/TrackVersions";
import {DirTree} from "../../../components/AssetDirectory/DirTree";
import {BasePageTemplate} from "../../../components/BasePageTemplate";
import {ActionPanel} from "../../../components/ActionPanel";

export function CombinedTrackInfoAndVersions({data, parentTrackId}) {

    const {actionPanelComp, tableComp} = TrackVersions({data, parentTrackId});
    const infoSchema = [
        {label: 'Writer(s)', field: 'writers', type: 'chiplist'},
        {label: 'Featuring', field: 'featuring', type: 'chiplist'}]


    const trackInfoComp = <TrackInfo data={data} schema={infoSchema}/>
    return (
        <BasePageTemplate

            actionPanelComp={actionPanelComp}
            contentComponent={[
                {comp: trackInfoComp, size: 4},
                {comp: tableComp,size: 8},
            ]}


        />
    )


}
