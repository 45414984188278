import {Autocomplete, TextField} from "@mui/material";
import {Controller} from "react-hook-form";


export function CustomAutoComplete({
                                       options,
                                       control,
                                       name,
                                       label,
                                       setValue,
                                       labelKey,
                                       valueKey,
                                       setInputValue,
                                       disabled
                                   }) {
    return (
        <Controller
            name={name}
            control={control}
            render={({field}) => (
                <Autocomplete
                    fullWidth
                    disabled={disabled}
                    {...field}
                    options={options}
                    getOptionLabel={(option) => option[labelKey] || ''}
                    value={options.find(option => option[valueKey] === field.value) || null}
                    onChange={(_, newValue) => {
                        console.log('new value', newValue)
                        console.log('value key', valueKey)
                        setValue(name, newValue ? newValue[valueKey] : null);
                    }}
                    onInputChange={(_, newInputValue, reason) => {
                        if (reason === 'input' && newInputValue.trim().length > 0) {
                            setInputValue(newInputValue);
                        }
                    }}
                    renderInput={(params) => <TextField {...params} label={label} disabled={disabled}/>}
                />
            )}
        />
    );
}