import React, {useEffect, useState} from 'react';
import {Table, TableBody} from '@mui/material';
import {buildFileTree} from "./fileTree";
import {FileNode} from "./FileNode";


export const DirTree = ({assets, setSelected, selected}) => {
    const fileTree = buildFileTree(assets.assets);
    // const [selected, setSelected] = useState([]);


    return (

        <Table>
            <TableBody>
                {Object.keys(fileTree).map(key => (


                    <FileNode
                        key={key}
                        name={key}
                        children={fileTree[key]}
                        path={key}
                        setSelected={setSelected}
                        selected={selected}
                    />
                ))}
            </TableBody>
        </Table>
    );
};