import {useArtists} from "../../../Hooks/ArtistHooks/useArtists";

import Person from "@mui/icons-material/Person";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import {useLocation} from "react-router-dom";
import {GridPage} from "../../../components/GridPage";
import React, {useEffect, useState} from "react";
import {useModal} from "../../../components/Context/ModalContext";
import {ArtistForm} from "../../../components/Forms/ArtistForm";
import {ActionPanel} from "../../../components/ActionPanel";
import {ItemCount} from "../../../components/ItemCount";
import {BasePageTemplate} from "../../../components/BasePageTemplate";
import {FilterMenu} from "../../../components/FilterMenu";


export function Artists() {

    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [filters, setFilters] = useState({});

    const imageKey = "image_url";
    const primaryTextKey = "name";
    const secondaryTextKey = "artist_id";
    const primaryIcon = <Person sx={{fontSize: "small"}}/>;
    const secondaryIcon = <Grid3x3Icon sx={{fontSize: "small"}}/>;
    const location = useLocation();

    const params = {limit: limit, offset: offset};

    const {
        artists,
        loading,
        error,
        recordCount,
        nextUrl,
        previousUrl,
        refetchArtists,
        updateParams,
    } = useArtists(params);
    const schema = {
        imageKey: imageKey,
        primaryTextKey: primaryTextKey,
        primaryIcon: primaryIcon,
        secondaryTextKey: secondaryTextKey,
        secondaryIcon: secondaryIcon,
        linkKey: "artist_id",
        endpoint: "artists",
    };

    const filterSchema = [
        {name: 'artistName', label: 'Artist Name', type: 'text'},
        {namr: 'artistId', label: 'Artist Id', type: 'text'}

    ];
    const {openModal, closeModal} = useModal();

    const handleCreateArtist = () => {
        openModal(<ArtistForm mode="create" closeModal={closeModal}/>);
    }

    const handleParamsChange = (newParams) => {
        setOffset(newParams.offset)
        setLimit(newParams.limit)
    }

    useEffect(() => {

        updateParams({limit: limit, offset: offset, ...filters})
    }, [limit, offset, filters]);

    const actionPanelComp = <ActionPanel handleCreate={handleCreateArtist}
                                         itemName={'Artist'}
    />
    const filterComp = <FilterMenu onApplyFilters={(data) => {
        setFilters(data)
    }}
                                   initialFilters={filters}
                                   filterSchema={filterSchema}/>


    const itemCountComp = <ItemCount
        itemCount={recordCount}
        entityType={'artists'}
        background={'lightgray'}
        icon={<Person fontSize={'large'} sx={{color: 'white'}}/>}
    />

    const artistGridPage = <GridPage
        data={artists}
        schema={schema}
        loading={loading}
        error={error}
        recordCount={recordCount}
        handleParamsChange={handleParamsChange}
        initialOffset={offset}
        initialLimit={limit}
        title={'Artists'}
    />

    return (
        <>
            <BasePageTemplate itemCountComp={itemCountComp}
                              actionPanelComp={actionPanelComp}
                              filterComp={filterComp}
                              contentComponent={[{comp: artistGridPage, size:9}]}/>

        </>
    )
        ;
}
