import {ItemCard} from "../../../components/ItemCard";
import {useRelease} from "../../../Hooks/ReleaseHooks/useRelease";
import {Box, Container, Unstable_Grid2 as Grid} from "@mui/material";
import {PageTabs} from "../../../components/Tabs/PageTabs";
import {Products} from "../Products/Products";
import {useParams} from "react-router-dom";
import {useDelete} from "../../../components/DeleteHandler";
import {useDeleteRelease} from "../../../Hooks/ReleaseHooks/useDeleteRelease";
import {useModal} from "../../../components/Context/ModalContext";
import {ReleaseForm} from "../../../components/Forms/ReleaseForm";
import {Assets} from "../Assets/Assets";
import {ItemCardWrapper} from "../../../components/ItemCardWrapper";
import {Products_2} from "../Products/Products_2";

export function Release() {
    const {releaseId} = useParams();
    const {data, loading, error} = useRelease(releaseId);
    const {loading: deleteStatus, error: deleteError, success: deleteSuccess, deleteRelease} = useDeleteRelease();
    const {handleDelete} = useDelete();
    const {openModal, closeModal} = useModal();


    const schema = {
        headerKey: 'name',
        imageKey: 'image_url',
        idKey: 'release_id',
        data: [

            {label: 'Release Id', field: 'release_id'},
            {label: 'Created Date', field: 'created_datetime', type: 'date'},
            {label: 'Last Modified Date', field: 'last_modified_datetime', type: 'date'}]
    };
    const chips = [
        {label: 'Release', color: '--var(light-orange)', from: 'literal'},
        {label: 'release_type', color: '--var(light-orange)'},
    ];

    const showEdit = () => {
        openModal(<ReleaseForm releaseId={releaseId}
                               mode={'edit'}
                               currentData={data}
                               setLiveData={data}/>)
    }

    const pages = [
            {
                label: 'Products',
                component: Products_2,
                params:
                    {
                        releaseId: releaseId
                    }
            }
            ,
            {
                label: 'Assets', component: Assets, params: {releaseId: releaseId}
            }
        ]
    ;
    const kebabOptions = [{label: 'Edit Release', action: showEdit}, {
        label: 'Delete',
        action: () => handleDelete(releaseId, deleteRelease)
    }]

    const comp = <ItemCard data={data}
                           schema={schema}
                           chips={chips}
                           loading={loading}
                           kebabOptions={kebabOptions}/>

    return (

        <Container maxWidth={'100%'} sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: "1%",
            mb: '1',
            height: '100%',
            justifyContent: 'flex-start'
        }}>
            <ItemCardWrapper component={comp}/>
            <Box height={'100%'} width={'75vw'} marginLeft={'2%'}>
                <PageTabs tabs={pages}/>
            </Box>
        </Container>

    )
        ;

}