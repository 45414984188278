import Grid from "@mui/material/Unstable_Grid2";
import {Divider, Paper, Typography} from "@mui/material";
import {Fragment} from "react";

export function InfoActionPanelWrapper({itemCountComponent, filterComponent}) {
    return (
        <>
            <Paper sx={{borderRadius:'16px'}}>
                {/*<Grid container spacing={3} sx={{ display: 'flex', flexWrap: 'nowrap'}}>*/}
                <Grid item xs={12} md={12} display="flex" justifyContent="center" alignItems="center">
                    {itemCountComponent}
                </Grid>
                <Divider/>

                <Grid item xs={12} md={12} display="flex" justifyContent="flex-start" alignItems="center">
                    {filterComponent}
                </Grid>
            </Paper>

            {/*</Grid>*/}
        </>
    );
}
