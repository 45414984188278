import React, {useEffect, useRef, useState} from "react";
import {useForm} from 'react-hook-form';
import {TextField, Button, Typography, Paper, ButtonGroup} from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";
import {useSnackbar} from "../Context/SnackbarContext";
import {useModal} from "../Context/ModalContext";
import {useAddArtist} from "../../Hooks/ArtistHooks/useAddArtist";
import {usePatchArtist} from "../../Hooks/ArtistHooks/usePatchArtist";
import {CustomLoadingButton} from "../CustomLoadingButton";
import {SingleInputField} from "./Elements/SingleInputField";
import {LargeTextInput} from "./Elements/LargeTextInput";

export function ArtistForm({artistId, mode, currentData, setLiveData}) {
    const {loading: createLoading, error: createError, success: createSucess, addArtist} = useAddArtist();
    const {loading: patchLoading, error: patchError, success: patchSuccess, patchArtist} = usePatchArtist();
    const {openSnackbar} = useSnackbar();
    const {closeModal} = useModal();
    const {control, handleSubmit, setValue, watch, reset, register} = useForm({
        defaultValues: {
            ...currentData,

        }
    });
    const [isLoading, setIsLoading] = useState(false);
    const isMounted = useRef(true);

    useEffect(() => {
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        if (createSucess) {
            openSnackbar('Artist created successfully');
            closeModal();
        } else if (patchSuccess) {
            openSnackbar('Artist updated successfully');
            closeModal();
        } else if (createError) {
            openSnackbar('Error creating artist');
        } else if (patchError) {
            openSnackbar('Error updating artist');
        }


    }, [createSucess, patchSuccess, createError, patchError, openSnackbar, closeModal])


    const onSubmit = async (data) => {
        setIsLoading(true);
        setTimeout(async () => {
            try {
                if (mode === 'edit') {
                    await patchArtist(artistId, data);
                    if (isMounted.current) setLiveData(data);
                    openSnackbar('Artist updated successfully');
                } else {
                    await addArtist(data);
                    if (isMounted.current) reset();
                    openSnackbar('Artist created successfully');
                }
                closeModal();
            } catch (error) {
                const errorMessage = error?.error || "An unexpected error occurred";
                openSnackbar(`Error: ${errorMessage}`);
            }
            setIsLoading(false);
        }, 2000);
    };

    useEffect(() => {
        setIsLoading(createLoading || patchLoading);


    }, [createLoading, patchLoading])

    return (
        <Paper elevation={3} style={{padding: 20}}>
            <Typography variant="h6">{mode === 'edit' ? 'Edit Artist' : 'Create Artist'}</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SingleInputField label={'Artist Name'}
                                          name={'name'}
                                          control={control}
                                          required/>

                    </Grid>
                    <Grid item xs={12}>

                        <SingleInputField label={'Image Url'}
                                          name={'image_url'}
                                          control={control}
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        <SingleInputField label={'Spotify ID'}
                                          name={'spotify_id'}
                                          control={control}
                        />


                    </Grid>

                    <Grid xs={12} md={6}>
                        <SingleInputField label={'Instagram ID'}
                                          name={'instagram_id'}
                                          control={control}
                        />


                    </Grid>


                    <Grid xs={12} md={6}>
                        <SingleInputField label={'Apple ID'}
                                          name={'apple_music_id'}
                                          control={control}
                        />


                    </Grid>
                    <Grid xs={12} md={6}>
                        <SingleInputField label={'Facebook ID'}
                                          name={'facebook_id'}
                                          control={control}
                        />


                    </Grid>
                    <Grid xs={12} md={6}>
                        <SingleInputField label={'Youtube ID'}
                                          name={'youtube_id'}
                                          control={control}
                        />


                    </Grid>

                    <Grid xs={12} md={6}>
                        <SingleInputField label={'Twitter ID'}
                                          name={'twitter_id'}
                                          control={control}
                        />


                    </Grid>
                    <Grid xs={12}>
                        <LargeTextInput label={'Bio'} control={control} name={'bio'}/>

                    </Grid>
                </Grid>
                <ButtonGroup fullWidth style={{marginTop: 20}}>
                    <Button variant="contained" color="error" onClick={closeModal}>
                        Cancel
                    </Button>
                    <CustomLoadingButton loading={isLoading}/>
                </ButtonGroup>
            </form>
        </Paper>
    );
}
