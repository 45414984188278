import React from "react";
import {Box, Card, CardContent, CardMedia, Container, Paper, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import "../Styles/InfoCard.css";

const InfoCard = ({
                      image,
                      primaryText,
                      primaryIcon,
                      secondaryText,
                      secondaryIcon,
                      linkEndpoint,
                      additionalText,
                      additionalIcon,
                  }) => (

    <>

            <Link to={linkEndpoint} className="link-no-decoration">
                <Container sx={{display: 'flex', flexDirection: 'column', maxWidth:'12vw', alignItems:'baseline', justifyContent:'start'}}>
                <Card sx={{
                    width: "8vw",
                    height: "8vw",
                    position: 'relative',
                    overflow: 'hidden'
                }}>
                    <CardMedia
                        component={Paper}
                        image={image}
                        alt={primaryText}
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}
                    />
                </Card>
                <CardContent sx={{

                    // display: 'inline-block',
                    minHeight: '20%',
                    maxHeight:'8vw',
                    maxWidth:'8vw',
                    // maxWidth: '172px',
                    padding: '0px',
                    marginTop: '4%',
                    // marginLeft: "4%",
                    '&:last-child': {paddingBottom: 0.5},
                    // overflow: 'hidden',

                }}>


                    <div className="infocard-text-container">
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            align="left"
                            component="span"
                        >
                            {primaryIcon}&nbsp;
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            fontFamily={'var(--bfm-font)'}
                            align="left"
                            // component="span"
                            display={'inline-block'}
                            className="infocard-primary-text"
                            overflow={'hidden'}
                            textOverflow={'ellipsis'}
                            whiteSpace={'nowrap'}
                            // maxWidth={'172px'}
                        >
                            {primaryText}
                        </Typography>
                    </div>
                    <div className="infocard-text-container">
                        {secondaryText && (
                            <>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    align="left"
                                    component="span"
                                >
                                    {secondaryIcon}&nbsp;
                                </Typography>
                                <Typography variant="body2" color="text.secondary" align="left"
                                            fontFamily={'var(--bfm-font)'}>
                                    <span className="infocard-secondary-text">{secondaryText}</span>
                                </Typography>
                            </>
                        )}
                    </div>
                    <div className="infocard-text-container">
                        {additionalText && (
                            <>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    align="left"
                                    component="span"
                                >
                                    {additionalIcon}&nbsp;
                                </Typography>
                                <Typography variant="body2" color="text.secondary" align="left"
                                            fontFamily={'var(--bfm-font)'}>
                                    <span className="infocard-secondary-text">{additionalText}</span>
                                </Typography>
                            </>
                        )}
                    </div>
                </CardContent>

                </Container>
            </Link>
    </>
);

export default InfoCard;