import Button from "@mui/material/Button";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React, {useEffect, useState} from "react";
import {useModal} from "../../../components/Context/ModalContext";
import {AssetForm2} from "../../../components/Forms/AssetForm2";
import {useParams} from "react-router-dom";
import {useAssets} from "../../../Hooks/AssetHooks/useAssets";
import {DirTree} from "../../../components/AssetDirectory/DirTree";
import Grid from "@mui/material/Unstable_Grid2";
import {ActionPanel} from "../../../components/ActionPanel";
import {Box, CircularProgress, Container, Divider, Paper, Stack} from "@mui/material";
import {BasePageTemplate} from "../../../components/BasePageTemplate";
import {Delete} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {useDeleteAssets} from "../../../Hooks/UploadHooks/useDeleteAssets";
import {useSnackbar} from "../../../components/Context/SnackbarContext";


export function Assets() {
    const [assets, setAssets] = useState([]);
    const [formData, setFormData] = useState({});
    const params = useParams();
    const {openSnackbar} = useSnackbar();
    const [selected, setSelected] = useState([])
    const entity = params.artistId || params.releaseId || params.trackId || params.isrc || params.productId;
    const {loading: deleting, error: deletionError, success: deletionSuccess, deleteAssets} = useDeleteAssets();

    const {data, loading, error, refetchAssets} = useAssets(entity);
    const handleClick = () => {
        openModal(<AssetForm2 entity={entity}
                              // handleFormData={setFormData}
                              handleRefresh={() => {refetchAssets(entity)}}/>);
        setAssets([...assets, {key: assets.length}]);
    };
    const {openModal, closeModal} = useModal();

    useEffect(() => {
        if (deletionSuccess) {
            openSnackbar('Successfully deleted assets');
            refetchAssets(entity)


        } else if (deletionError) {
            openSnackbar('Failed to delete assets');
        }
    }, [deletionSuccess, deletionError]);


    const handleDeleteAssets = async () => {
        if (window.confirm('Are you sure you wish to delete these assets? Any deleted asset will need to be uploaded again')) {

            openSnackbar('Deleting assets')
            const data = {assets: selected};
            await deleteAssets(entity, data);
            setSelected([]);
        }
    }

    const actionPanelComp = <ActionPanel handleCreate={handleClick} itemName={'Asset'}/>
    const assetComp = <>

        <Container sx={{
            p: 0,
            ml: 0,
            mr: 0,
            alignItems: 'start',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '2%',
            // maxWidth: '10vw'
            width: '30vw',


        }} component={Paper}
                   elevation={10}
        >

            <Box alignSelf={'flex-start'} p={'1%'} maxWidth={'100%'}>
                <h1 style={{padding: 0, margin: 0}}>Assets</h1>
                <Divider flexItem sx={{marginRight: '30%'}}/>
            </Box>
            <Stack direction={'row'} justifyContent={'space-between'} display={'flex'} alignItems={'center'}
                   width={'100%'}>

                <ActionPanel handleCreate={handleClick} itemName={'Asset'}/>
                {selected.length > 0 && <IconButton disabled={loading} onClick={handleDeleteAssets}>
                    {deleting ? <CircularProgress/> :


                        <Delete> Delete</Delete>}
                </IconButton>
                }

            </Stack>
            <Divider/>
            <DirTree assets={data} setSelected={setSelected} selected={selected}/>

        </Container>
    </>


    return (

        <>

            <BasePageTemplate
                actionPanelComp={actionPanelComp}
                contentComponent={[{comp: assetComp}]}/>


        </>
    )

}


