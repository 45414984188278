import {useState} from "react";

export function usePatch(service) {
    const [status, setStatus] = useState({loading: false, error: null, success: false});

    const patch = async (id, data) => {
        setStatus({loading: true, error: null, success: false});
        try {
            const response = await service.update(id, data);
            if (response.status === 202) {
                setStatus({loading: false, error: null, success: true})
            } else {
                throw new Error(`Failed to update item: ${response.status}`);
            }
        } catch (error) {
            console.log("API Error:", error);
            setStatus({loading: false, error: error.toString(), success: false});
        }
    }

    return {...status, patch};


}