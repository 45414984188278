import { IconButton, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const MenuItemMemo = React.memo(({ option, handleClose }) => {
    return option.link ? (
        <Link to={option.link} style={{ textDecoration: "none", color: "inherit" }}>
            <MenuItem key={option.optionName} onClick={handleClose}>
                {option.optionName}
            </MenuItem>
        </Link>
    ) : (
        <MenuItem key={option.optionName} onClick={handleClose}>
            {option.optionName}
        </MenuItem>
    );
});

export function MenuDropdown({
                                 menuName,
                                 menuOptions,
                                 menuHandler,
                                 menuAnchor,
                                 handleClose,
                             }) {
    return (
        <>
            <IconButton
                color="inherit"
                onClick={menuHandler}
                sx={{ mx: 1, fontSize:'1.2rem',fontFamily: "var(--header-font)" }}

            >
                {menuName}
                <KeyboardArrowDownIcon/>
            </IconButton>
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={handleClose}
            >
                {menuOptions.map((option) => (
                    <MenuItemMemo
                        key={option.optionName}
                        option={option}
                        handleClose={handleClose}
                    />
                ))}
            </Menu>
        </>
    );
}
