import React, {useState, useEffect} from 'react';
import {Avatar} from '@mui/material';
import {imageFetcher} from "./ImageFetcher";

export const ImagePreviewer = ({imagePath}) => {

    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const fetchImageUrl = async () => {
            console.log(imagePath, 'i am image path')
            try {
                const url = await imageFetcher(imagePath);
                setImageUrl(url);
            } catch (error) {
                console.error('Error fetching image URL:', error);
            }
        };

        fetchImageUrl();
    }, [imagePath]);

    if (!imageUrl) {
        return <Avatar/>;
    }

    return <Avatar src={imageUrl}/>;
};

// gsutil -m acl ch -r -u service-976350951517@gcp-sa-firebasestorage.iam.gserviceaccount.com gs://bfm-digital-master-store
