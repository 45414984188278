import React from 'react';
import { TextField, Chip, Autocomplete } from '@mui/material';
import { Controller } from "react-hook-form";

export function MultiChipInput({ control, name, label }) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Autocomplete
                    multiple
                    freeSolo
                    id={`${name}-autocomplete`}
                    disableClearable
                    options={[]}
                    value={value || []}
                    onChange={(event, newValue) => onChange(newValue)}
                    onInputChange={(event, newInputValue, reason) => {
                        if (reason === 'reset') {
                            event.preventDefault();
                        }
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            variant="outlined"
                            error={!!error}
                            helperText={error ? error.message : "Press 'Enter' to add items"}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                        />
                    )}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                key={index}
                                label={option}
                                onDelete={() => {
                                    onChange(value.filter((chip) => chip !== option));
                                }}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                />
            )}
        />
    );
}
