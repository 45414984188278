import {useState} from "react";
import {ProductService} from "../../Services/ProductService";

export function usePatchProduct() {
    const [status, setStatus] = useState({loading: false, error: null, success: false});

    const patchProduct = async (id, data) => {
        setStatus({loading: true, error: null, success: false});
        try {
            const response = await ProductService.update(id, data);
            setStatus({loading: false, success: response.status === 202});
            return response;
        } catch (error) {
            console.log("API Error:", error);
            setStatus({loading: false, error: error.toString(), success: false});
            return false;
        }
    }

    return {...status, patchProduct};

}
