import {ArtistSelector} from "./ArtistSelector";
import {useForm} from "react-hook-form";
import {TrackSelector} from "./TrackSelector";
import {useEffect} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import {SingleInputField} from "./Elements/SingleInputField";
import {LoadingButton} from "@mui/lab";
import {CustomLoadingButton} from "../CustomLoadingButton";
import TimePicker, {DurationPicker} from "./Elements/DurationPicker";
import {TrackVersionService} from "../../Services/TrackVersionService";
import {useCreate} from "../../Hooks/useAdd";
import {usePatch} from "../../Hooks/usePatch";
import {useSnackbar} from "../Context/SnackbarContext";
import {useModal} from "../Context/ModalContext";

export function TrackVersionForm({mode, currentData, parentTrackId}) {
    const {success: createSuccess, error: createError, loading: createLoading, create} = useCreate(TrackVersionService);
    const {success: patchSuccess, error: patchError, loading: patchLoading, patch} = usePatch(TrackVersionService)
    const loading = createLoading || patchLoading;
    const {openSnackbar} = useSnackbar();
    const {closeModal} = useModal();


    useEffect(() => {
        console.log('createSuccess:', createSuccess, 'patchSuccess:', patchSuccess, 'createError:', createError, 'patchError:', patchError);

        if (createSuccess) {
            openSnackbar('Track Version Created Successfully', 'success');
            closeModal();
        } else if (patchSuccess) {
            openSnackbar('Track Version Updated Successfully', 'success');
            closeModal();
        }

        if (createError) {
            openSnackbar('Error creating Track Version', 'error');
        } else if (patchError) {
            openSnackbar('Error updating Track Version', 'error');
        }
    }, [createSuccess, patchSuccess, createError, patchError, openSnackbar, closeModal]);

    const {handleSubmit, control, setValue, getValues, watch} = useForm({
        defaultValues: {
            ...currentData,
            parent_track_id: parentTrackId,
        }

    });


    const onSubmit = async (data) => {
        if (mode === 'edit') {
            await patch(currentData.isrc, data)
        }
         else if (mode === 'create') {
            await create(data)
        }
    };


    return (<>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    {/*<Grid xs={12}>*/}
                    {/*    <ArtistSelector control={control}*/}
                    {/*                    setValue={setValue}*/}
                    {/*                    disabled={true}*/}
                    {/*    />*/}
                    {/*</Grid>*/}

                    {/*{watch('artist_id') &&*/}

                    <Grid xs={12}>
                        <TrackSelector control={control}
                                       name={mode === 'edit' ? 'track_name' : 'name'}
                                       label="Parent Track"
                        />

                    </Grid>
                    {/*}*/}
                    <Grid xs={12}>
                        <SingleInputField control={control}
                                          label="ISRC"
                                          name="isrc"
                                          required={true}/>
                    </Grid>
                    <Grid xs={12}>
                        <SingleInputField control={control}
                                          label="Track Version"
                                          name="track_version"/>
                    </Grid>
                    <Grid xs={12}>
                        <SingleInputField control={control}
                                          label="Audio Language"
                                          name="audio_language"/>
                    </Grid>
                    <Grid xs={12}>
                        <DurationPicker control={control}
                                        name={'track_length_seconds'}/>

                    </Grid>


                </Grid>
                <CustomLoadingButton loading={loading}/>
            </form>
        </>

    )

}