import {Releases} from "../Releases/Releases";
import {Tracks} from "../Tracks/Tracks";
import {Artists} from "../Artists/Artists";
import {Products} from "../Products/Products";
import {Box, Container, Typography} from "@mui/material";
import {MastersHome} from "./Home";
import {PageTabs} from "../../../components/Tabs/PageTabs";
import {Products_2} from "../Products/Products_2";


export function ProductsPage() {

    const tabs = [{label: 'Home', component: MastersHome, link: '/masters'}, {
        label: 'Artists',
        component: Artists,
        link: '/masters/artists'
    }, {
        label: 'Releases',
        component: Releases,
        link: '/masters/releases'
    }, {
        label: 'Products', component: Products_2
    },
        {
            label: 'Tracks',
            component: Tracks,
            link: '/masters/tracks'
        }];


    return (
        <Container maxWidth={'100%'} sx={{}}>
            {/*<Box className={'hspace'}>*/}
            {/*    <Typography variant="h1" sx={{marginTop: "1%", marginBottom: "0%"}}>Products</Typography>*/}
            {/*</Box>*/}
            <Box marginTop={'1%'}/>

            <PageTabs tabs={tabs} defaultTab={3}/>
        </Container>
    )


}