import {useState, useEffect} from "react";
import {ProductService} from "../../Services/ProductService";

export function useUpdateProductTracklist() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const updateProductTracklist = async (id, tracklistData) => {
        setLoading(true);
        setError("");

        try {
            const response = await ProductService.updateTracklist(id, tracklistData);

            setLoading(false);
        } catch (error) {
            setError(error.message || "An unexpected error occurred");
        } finally {
            setLoading(false);
        }
    };


    return {loading, error, updateProductTracklist};
}
