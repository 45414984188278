import {styled} from "@mui/material/styles";
import {Link as RouterLink} from "react-router-dom";

export const StyledLink = styled(RouterLink)({
    maxWidth: '100%',
    cursor: "pointer",
    "&:hover": {
        textDecoration: "underline",
        color: "var(--light-orange)",
    },
    textOverflow: "ellipsis",
    fontSize: "0.9rem",
    fontFamily: 'var(--bfm-font)',
    display: 'inline-block'
});