import { useProductTracklist } from "../../Hooks/ProductHooks/useProductTracklist";
import { useParams } from "react-router-dom";
import DraggableTracklistTable from "../../components/Tables/DraggableTable";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { useProduct } from "../../Hooks/ProductHooks/useProduct";
import { useUpdateProductTracklist } from "../../Hooks/ProductHooks/useUpdateProductTracklist";
import { Paper, Stack, Typography } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingSkeleton } from "../../components/LoadingSkeleton";

export function TrackList({productId, artistId}) {
    const { data: tracklistData, loading, error, refetchTracklist } = useProductTracklist(productId);
    const { data: product, loading: productLoading, refetchProduct } = useProduct(productId);
    const { updateProductTracklist } = useUpdateProductTracklist();
    const [updatedTrackList, setUpdatedTrackList] = useState([]);
    const [isEdited, setIsEdited] = useState(false);

    const handleTrackListChange = (updatedTrackList) => {
        console.log("TrackList Updated:", updatedTrackList);
        setUpdatedTrackList(updatedTrackList);
    };

    console.log(product, 'product');
    //
    // useEffect(() => {
    //     console.log(product, 'product inside useEffect');
    //      product &&
    //         setArtistId(product.release_artist_id);
    //
    // }, [product]); // Use `product` as dependency

    console.log(artistId, 'artistId');
    useEffect(() => {
        console.log(artistId, 'artistId');
    }, [artistId]);

    const handleUpdate = async () => {
        await updateProductTracklist(productId, updatedTrackList);
        setIsEdited(false);
    };

    const handleCancel = () => {
        refetchTracklist(productId);
        setIsEdited(null);
    };

    if (loading || productLoading) return <LoadingSkeleton />;
    if (error) return <div>Error: {error}</div>;
    console.log(tracklistData.tracklist);

    return (
        <Stack direction={'column'} spacing={2} sx={{ padding: 0, height: 'fit-content' }}>
            {tracklistData && (
                <DraggableTracklistTable
                    initialTracklistData={tracklistData.tracklist}
                    hasBeenModified={setIsEdited}
                    editState={isEdited}
                    artistId={artistId}
                    onCancel={handleCancel}
                    onTrackListUpdate={handleTrackListChange}
                    title={'Tracklist'}
                />
            )}
            <div className='edit-button'>
                {isEdited && (
                    <div className='button-group-horizontal' style={{ display: 'flex', flexDirection: 'column' }}>
                        <Fab onClick={handleUpdate} size='small'>
                            <SaveIcon fontSize='small' />
                        </Fab>
                        <div style={{ height: '1vh' }} />
                        <Fab onClick={handleCancel} size='small'>
                            <CancelIcon fontSize='small' />
                        </Fab>
                    </div>
                )}
            </div>
        </Stack>
    );
}
