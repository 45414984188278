import React, {useEffect, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";

export const DurationPicker = ({ control, name }) => {
    // Utility functions for conversion
    const convertSecondsToDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return { minutes, seconds: secs };
    };

    const convertDurationToSeconds = (minutes, seconds) => minutes * 60 + seconds;

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={0} // default value can be adjusted as per the initial data or state
            render={({ field: { onChange, value, ref } }) => {
                const { minutes, seconds } = convertSecondsToDuration(value);

                const handleMinutesChange = (e) => {
                    const newTotalSeconds = convertDurationToSeconds(parseInt(e.target.value, 10), seconds);
                    onChange(newTotalSeconds);
                };

                const handleSecondsChange = (e) => {
                    const newTotalSeconds = convertDurationToSeconds(minutes, parseInt(e.target.value, 10));
                    onChange(newTotalSeconds);
                };

                return (
                    <Grid container spacing={2}>
                        <Grid xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Minutes</InputLabel>
                                <Select
                                    value={minutes}
                                    onChange={handleMinutesChange}
                                    inputRef={ref}
                                    label="Minutes"
                                >
                                    {Array.from({ length: 60 }, (_, i) => (
                                        <MenuItem key={i} value={i}>{i}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Seconds</InputLabel>
                                <Select
                                    value={seconds}
                                    onChange={handleSecondsChange}
                                    inputRef={ref}
                                    label="Seconds"
                                >
                                    {Array.from({ length: 60 }, (_, i) => (
                                        <MenuItem key={i} value={i}>{i}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
};