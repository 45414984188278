import {initializeApp} from "firebase/app";
import "firebase/compat/auth";
import {getStorage} from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyDcPqbFUIZ36bNg3oX41GliHqhfBizpKKk",
    authDomain: "bfm-sandbox.firebaseapp.com",
    projectId: "bfm-sandbox",
    // storageBucket: "bfm-sandbox.appspot.com",
    storageBucket: 'bfm-digital-master-store',
    messagingSenderId: "976350951517",
    appId: "1:976350951517:web:f4dc7fc1a349ac2b38f5b4",
};

const app = initializeApp(firebaseConfig);
export default app;
