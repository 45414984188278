import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import {FilterMenu} from "./FilterMenu";
import {Box, Stack} from "@mui/material";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Unstable_Grid2";

export function ActionPanel({filterSchema, handleCreate, updateFilters, itemName}) {


    return (
        <Box marginTop={'%'} display={'flex'}>
            <Stack direction="row" spacing={1} sx={{ p: 0}} alignItems={'center'} display={'flex'}>
                <Fab
                    variant="extended"
                    color="primary"
                    aria-label="add"
                    onClick={handleCreate}
                    size="small"
                    sx={{backgroundColor: 'black'}}
                >
                    Create {itemName}&nbsp;
                    <AddCircleOutlineRoundedIcon fontSize="small"/>
                </Fab>
                {filterSchema &&
                    <FilterMenu
                        filterSchema={filterSchema}
                        onApplyFilters={(newParams) => {
                            updateFilters(newParams)
                        }}
                    />
                }
            </Stack>
        </Box>
    )

}


