import {Controller} from "react-hook-form";
import {TextField} from "@mui/material";

export function LargeTextInput({control, name, label}) {
    return (
        <Controller
            name={name}
            control={control}
            render={({field}) => (
                <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={4}
                    label={label}
                />
            )}
        />
    );


}