import InfoCard from "../components/InfoCard";
import Grid from '@mui/material/Unstable_Grid2';
import {Box, Container, Divider, Paper, TablePagination, Typography} from "@mui/material"
import {LoadingSkeleton} from "../components/LoadingSkeleton";
import {useEffect, useState} from "react";
import {LoadingGrid} from "./LoadingGrid";
import useMediaQuery from '@mui/material/useMediaQuery';


export function GridPage({
                             data,
                             loading,
                             error,
                             recordCount,
                             initialLimit,
                             initialOffset,
                             schema,
                             handleParamsChange,
                             domain = 'masters',
                             title,
                             filterComp


                         }) {

    const [currentPage, setCurrentPage] = useState(0);
    const [limit, setLimit] = useState(initialLimit);
    const [offset, setOffset] = useState(initialOffset);


    useEffect(() => {
        handleParamsChange({limit, offset});
    }, [limit, offset]);

    useEffect(() => {
        // setCurrentPage(0
        // )

    }, [data]);



    function handleRowsPerPageChange(event) {
        setLimit(event.target.value);
        setCurrentPage(0);
    }


    function handlePageChange(event, page) {
        setOffset(page * limit);
        setCurrentPage(page);
    }

    const itemCount = data.length;
    const gridWidth = itemCount >= 4 ? 3 : 12 / itemCount;

    return (
        <>


            <Paper
                elevation={10}
                square
                sx={{
                    // flexGrow: 1,
                    minHeight: '40vh',
                    borderRadius: '16px',
                    alignItems: 'center',
                    p: '1%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',

                }}
            >

                    <>
                        <Box alignSelf={'flex-start'} marginBottom={'2%'}>
                            <Box
                                alignSelf={'start'} justifyContent={'fit-content'} marginBottom={'2%'} display={'flex'}>

                                <h1 style={{padding: '0%', margin: '0%'}}>{title}</h1>
                                {filterComp}

                            </Box>
                            <Divider flexItem sx={{marginRight: '30%'}}/>
                        </Box>

                        {loading ? <LoadingGrid/> :
                        <Grid container spacing={2}
                              sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  width: '100%',
                                  justifyContent: 'center'
                              }}
                        >


                            {data && data.map(item => (
                            <Grid key={item[schema.idKey]} xs={12} sm={2.2}
                                  sx={{
                                      alignContent: 'center',
                                      display: 'flex',
                                      alignSelf: 'center',
                                      justifyContent:'center'
                                  }}
                            >
                                <InfoCard
                                    image={item[schema.imageKey]}
                                    primaryText={item[schema.primaryTextKey]}
                                    primaryIcon={schema.primaryIcon}
                                    secondaryText={item[schema.secondaryTextKey]}
                                    secondaryIcon={schema.secondaryIcon}
                                    linkEndpoint={`/${domain}/${schema.endpoint}/${item[schema.linkKey]}`}
                                    additionalText={item[schema.additionalTextKey]}
                                    additionalIcon={schema.additionalIcon}
                                />
                            </Grid>
                            ))}
                        </Grid>}
                        <TablePagination count={recordCount}
                                         page={currentPage}
                                         rowsPerPage={limit}
                                         onPageChange={handlePageChange}
                                         onRowsPerPageChange={handleRowsPerPageChange}
                                         rowsPerPageOptions={[8, 12, 16, 32]}
                                         sx={{alignSelf: 'baseline'}}
                        />

                    </>

            </Paper>


        </>
    );
}