import {ProductService} from "../../Services/ProductService";
import {useEffect, useState, useRef} from "react";

export function useProducts(initialParams = {}) {
    const [data, setData] = useState({
        products: [],
        recordCount: 0,
        nextUrl: null,
        previousUrl: null,
    });
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState(initialParams);
    const [error, setError] = useState("");

    const formatResponse = (response) => {
        return {
            products: response.products,
            recordCount: response.record_count,
            nextUrl: response.next_url,
            previousUrl: response.previous_url,
        };
    };

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            setError("");
            try {
                const response = await ProductService.getAll(params);
                const formattedData = formatResponse(response.data);
                console.log("Formatted Data:", formattedData); // Log the formatted data
                setData(formattedData);
                setLoading(false);
            } catch (error) {
                console.log("ERROR during fetch:", error);
                setError(error.message || "An unexpected error occurred");
            }
        };

        fetchProducts();
    }, [params]);
    const refetchProducts = (newParams = {}) => {
        setParams(newParams);
    };

    const updateParams = (newParams={}) => {
        console.log("calling update params");
        setParams(newParams);
    };

    return {...data, loading, error, refetchProducts, updateParams};
}
