import {request} from "../components/Handlers/RequestHandler";

const postHeaders = {
    "Content-Type": "application/json",
};

export const AssetService = {
    upload(files) {
        return request("POST", "/upload", files, null, postHeaders);
    },

    getAll(id) {
        return request("GET", `/assets/${id}`, null, null);
    },
    //
    // getOne(id) {
    //     return request("GET", `/artists/${id}`, null);
    // },
    //
    // update(id, updatedArtist) {
    //     return request("PATCH", `/artists/${id}`, updatedArtist, null, postHeaders);
    // },
    //
    delete(id, data) {
        return request("DELETE", `/assets/${id}`, data, null);
    },
};
