import {ItemCard} from "../../../components/ItemCard";
import {Box, Container, Unstable_Grid2 as Grid} from "@mui/material";
import {useProduct} from "../../../Hooks/ProductHooks/useProduct";
import {CombinedProductWithTracklist} from "./CombinedProductWithTracklist";
import {useParams} from "react-router-dom";
import {useDelete} from "../../../components/DeleteHandler";
import {useDeleteProduct} from "../../../Hooks/ProductHooks/useDeleteProduct";
import {useModal} from "../../../components/Context/ModalContext";
import {ProductForm} from "../../../components/Forms/ProductForm";
import {PageTabs} from "../../../components/Tabs/PageTabs";
import {ItemCardWrapper} from "../../../components/ItemCardWrapper";
import {Assets} from "../Assets/Assets";

export function Product() {
    const {productId} = useParams();
    const {data, error, loading} = useProduct(productId);
    const {handleDelete} = useDelete();
    const {deleteProduct, loading: deletionStatus, error: deletionError, success: deleteSuccess} = useDeleteProduct();
    const {openModal} = useModal();


    const schema = {
        headerKey: 'release_name',
        imageKey: 'image_url',
        idKey: 'product_id',
        secondaryDataKey: 'product_version',
        data: [

            {label: 'Product Id', field: 'product_id'},
            {label: 'Created Date', field: 'created_datetime', type: 'date'},
            {label: 'Last Modified Date', field: 'last_modified_datetime', type: 'date'}]
    };
    const chips = [
        {label: 'Product', color: '--var(light-orange)', from: 'literal'},
        {label: 'product_type', color: '--var(light-blue)'},
        {label: 'product_format', color: '--var(light-blue)'}
    ];


    const handleEdit = () => {

        return (
            openModal(<ProductForm productId={productId}
                                   mode={'edit'}
                                   currentData={data}
                />
            )
        )
    }

    const pages = [{
        label: 'Product Info',
        component: CombinedProductWithTracklist,
        params: {productId: productId}
    }, {label: 'Assets', component: Assets}];
    const kebabOptions = [{label: 'Edit', action: handleEdit}, {
        label: 'Delete',
        action: () => handleDelete(productId, deleteProduct)
    }]

    const comp = <ItemCard data={data}
                           schema={schema}
                           chips={chips}
                           loading={loading}
                           kebabOptions={kebabOptions}/>

    return (

        <Container maxWidth={'100%'} sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: "1%",
            mb: '1',
            height: '100%',
            justifyContent: 'flex-start'
        }}>
            <ItemCardWrapper component={comp}/>
            <Box height={'100%'} width={'75vw'} marginLeft={'2%'}>
                <PageTabs tabs={pages}/>
            </Box>
        </Container>

    )
        ;

}