import { useState } from "react";
import { ProductService } from "../../Services/ProductService";

export function useDeleteProduct() {
    const [status, setStatus] = useState({ loading: false, error: null, success: false });

    const deleteProduct = async (id) => {
        setStatus({ loading: true, error: null, success: false });
        try {
            const responseData = await ProductService.delete(id);
            if (responseData.status === 202) {
                setStatus({ loading: false, error: null, success: true });
            } else {
                throw new Error(responseData.message || "An unexpected error occurred");
            }
        } catch (error) {
            setStatus({ loading: false, error: error.toString(), success: false });
        }
    };

    return { ...status, deleteProduct };
}
