import {ArtistService} from "../../Services/ArtistService";
import {useState} from "react";

export function usePatchArtist() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);


    const patchArtist = async (id, data) => {


            setLoading(true);
            setError("");
            try {
                const response = await ArtistService.update(id, data);
                if (response.status === 202) {
                    console.log("Response Data:", response);
                    setLoading(false)
                    setSuccess(true);
                    return response
                } else {
                    console.log("Response Data:", response);
                    setError(response.message || "An unexpected error occurred");
                }

            } catch (error) {
                console.log("ERROR during fetch:", error);
                setError(error.message || "An unexpected error occurred");
                throw error;
            } finally {
                setLoading(false);
            }
        }
    ;
    return {loading, error, success, patchArtist};
}
