import { request } from "../components/Handlers/RequestHandler";

const postHeaders = {
  "Content-Type": "application/json",
};

export const TrackVersionService = {
  create(newTrack) {
    return request("POST", "/trackversions", newTrack, null, postHeaders);
  },

  getAll(params) {
    return request("GET", "/trackversions", null, params, null);
  },

  getOne(id) {
    return request("GET", `/trackversions/${id}`, null);
  },

  update(id, updatedTrack) {
    return request(
      "PATCH",
      `/trackversions/${id}`,
      updatedTrack,
      null,
      postHeaders,
    );
  },

  delete(id, params) {
    return request("DELETE", `/trackversions/${id}`, null, params);
  },
};
