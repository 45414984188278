import React, {useEffect, useState} from "react";
import {useProducts} from "../../../Hooks/ProductHooks/useProducts";
import {Checkbox} from "@mui/material";
import TableWithPagination from "../../../components/Tables/TableWithPagination";
import {LoadingSkeleton} from "../../../components/LoadingSkeleton";
import Grid from "@mui/material/Unstable_Grid2";
import {ActionPanel} from "../../../components/ActionPanel";
import {useAddProduct} from "../../../Hooks/ProductHooks/useAddProduct";
import {ProductForm} from "../../../components/Forms/ProductForm";
import {useModal} from "../../../components/Context/ModalContext";
import {Link} from "react-router-dom";
import {ItemCount} from "../../../components/ItemCount";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import {InfoActionPanelWrapper} from "../../../components/InfoActionPanelWrapper";
import {BasePageTemplate} from "../../../components/BasePageTemplate";
import {FilterMenu} from "../../../components/FilterMenu";

export function Products({artistId, releaseId}) {


    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const {addProduct} = useAddProduct();
    const [filters, setFilters] = useState({});

    const {openModal, closeModal} = useModal();

    const initialParams = {
        artistId: artistId,
        releaseId: releaseId,
        limit: limit,
        offset: 0,
    };
    const {
        products,
        recordCount,
        loading,
        error,
        refetchProducts,
        updateParams,
    } = useProducts(initialParams);

    useEffect(() => {
        updateParams({

            artistId: artistId,
            releaseId: releaseId,
            offset: (currentPage) * limit,
            limit: limit,
            ...filters
        });
    }, [currentPage, limit, artistId, releaseId, filters]);

    const schema = [
            {
                id: "image_url",
                label: "",
                align: "center",
                primary: true,
                type: "image",
            },
            {
                id: "release_artist",
                label: "Release Artist",
                primary: true,
                type: "link",
                linkEndpoint: "artists",
                linkValue: 'release_artist_id'
            },
            {
                id: "release_name",
                label: "Release Name",
                primary: true,
                type: "link",
                linkEndpoint: "releases",
                linkValue: "release_id",
            },
            {
                id: "upc",
                label:
                    "UPC",
                primary:
                    true,
                editable:
                    true,
                type: 'link',
                linkEndpoint: 'products',
                linkValue: 'product_id'
            }
            ,
            {
                id: "product_version",
                label:
                    "Product Version",
                primary:
                    true,
                editable:
                    true,
            }
            ,

            {
                id: "product_artist_name",
                label:
                    "Product Artist Name",
                primary:
                    true,
                editable:
                    true,
            }
            ,
            {
                id: "release_date",
                label:
                    "Release Date",
                primary:
                    true,
                editable:
                    true,
                type:
                    "date",
            }
            ,
            {
                id: "catalog_number",
                label:
                    "Catalog Number",
                primary:
                    true,
                editable:
                    true,
            }
            ,
            {
                id: "product_type",
                label:
                    "Product Type",
                primary:
                    true,
                editable:
                    true,
            }
            ,
            {
                id: "product_format",
                label:
                    "Product Format",
                primary:
                    true,
                editable:
                    true,
            }
            ,
            {
                id: "is_explicit",
                label:
                    "Is Explicit",
                secondary:
                    true,
                editable:
                    true,
                type:
                    "explicit",
            }
            ,
            {
                id: "is_reissue",
                label:
                    "Is Reissue",
                secondary:
                    true,
                type:
                    "boolean"

            },
            {
                id: "c_line_year",
                label:
                    "C Line Year",
                secondary:
                    true,
                editable:
                    true,
            }
            ,
            {
                id: "c_line_owner",
                label:
                    "C Line Owner",
                secondary:
                    true,
                editable:
                    true,
            }
            ,
            {
                id: "p_line_year",
                label:
                    "P Line Year",
                secondary:
                    true,
                editable:
                    true,
            }
            ,
            {
                id: "p_line_owner",
                label:
                    "P Line Owner",
                secondary:
                    true,
                editable:
                    true,
            }
            ,
            {
                id: "genres",
                label:
                    "Genres",
                secondary:
                    true,
                editable:
                    true,
                type:
                    "chiplist",

            }
            ,
            {
                id: "label", label:
                    "Label", secondary:
                    true, editable:
                    true
            }
            ,
        ]
    ;
//
    const formats = ['Digital', '12 inch vinyl', '7 inch vinyl', 'CD', 'Cassette', 'Other'];

    const filterSchema = [{name: 'upc', label: 'UPC', type: 'text'},
        {name: 'releaseName', label: 'Release Name', type: 'text'},
        {name: 'catalogNumber', label: 'Catalog Number', type: 'text'},

        {
            name: 'productFormat',
            label: 'Product Format',
            type: 'dropdown',
            options: formats
        }];


    const handleCreate = () => {
        openModal(<ProductForm mode="create"
                               closeModal={closeModal}
                               releaseId={releaseId}
                               artistId={artistId}/>);

    }

    const actionPanelComp = <ActionPanel
        handleCreate={handleCreate}
        itemName={'Product'}/>


    const itemCountComp = <ItemCount
        itemCount={recordCount}
        entityType={'products'}
        icon={<ShoppingBagIcon fontSize={'large'} sx={{color: 'white'}}/>}
    />

    const filterComp = <FilterMenu
        onApplyFilters={(params) => {
            setFilters(params);
            setCurrentPage(0)
        }}
        filterSchema={filterSchema}
        initialFilters={filters}
    />


    const productsTable = <TableWithPagination
        data={products}
        schema={schema}
        recordCount={recordCount}
        currentPageCb={setCurrentPage}
        itemsPerPageCb={setLimit}
        itemsPerPage={limit}
        currentPage={currentPage}
        extraDataHeader={'Additional Info'}
        title={'Products'}
        filterComp={filterComp}
        loading={loading}
    />


    return (
        <>
            <BasePageTemplate itemCountComp={itemCountComp}
                              actionPanelComp={actionPanelComp}
                              contentComponent={[{comp: productsTable}]}/>

        </>
    )
}
