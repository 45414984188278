import React from 'react';
import { useAudio } from './Context/AudioContext';
import IconButton from "@mui/material/IconButton";
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Container, Paper, Typography } from "@mui/material";

export const MediaPlayer = () => {
    const { isPlaying, audioUrl, toggleAudio, trackName } = useAudio();

    const handlePlayPause = () => {
        if (audioUrl) {
            toggleAudio(audioUrl, trackName);
        }
    };

    function findTrackName(url) {
        if (url) {
            const regex = /Audio\/Master\/([^/?]+)/;
            const match = url.match(regex);
            if (match && match[1]) {
                const tn = String(match[1]);
                const trackName = decodeURI(tn.replace(/\+/g, ' '));
                return trackName;
            }
        }
        return "Unknown Track";
    }

    return (
        <div style={{ marginLeft: '2%' }}>
            <Paper elevation={3}>
                <Container sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    backgroundColor: '#333',
                    alignItems: 'center',
                    width: '10vw'
                }}>
                    <IconButton onClick={handlePlayPause}>
                        {isPlaying ? <PauseIcon sx={{ color: 'white' }} /> : <PlayArrowIcon sx={{ color: 'white' }} />}
                    </IconButton>
                    <div className="scroll-container">
                        <Typography className="scroll-text" fontFamily={'var(--bfm-font)'} color={'white'}>
                            {isPlaying ? `Now Playing: ${findTrackName(audioUrl)}` : "No track playing"}
                        </Typography>
                    </div>
                </Container>
            </Paper>
        </div>
    );
};

export default MediaPlayer;
