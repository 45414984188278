import React from 'react';
import { SvgIcon } from '@mui/material';

const SoundRecordingIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <text x="3" y="20" fontSize="1.5rem" fontFamily="Arial, sans-serif">℗</text>
        </SvgIcon>
    );
};

export default SoundRecordingIcon;