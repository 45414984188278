import React from "react";
import {useLocation, Link as RouterLink} from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {capitalize} from "@mui/material/utils";
import {Album, Home} from "@mui/icons-material";
import {Box, Stack} from "@mui/material";

export default function DynamicBreadcrumbs() {
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            separator={'>'}

            sx={{
                display: 'flex',
                backgroundColor: 'var(--light-orange)',
                padding: '0.2%',
                paddingLeft: '1%',
                position:'sticky',
                top: '6vh',
                zIndex:1000,
                borderBottom: '0.05px solid var(--light-orange)',
                // borderRadius: '5px',
                alignItems: 'center',

            }}
        >

            <Link
                component={RouterLink}
                underline={'hover'}
                to="/"
                sx={{
                    display: 'flex',
                    color: 'black',
                    fontFamily: 'var(--bfm-font)',
                    textDecorationColor: 'black',
                    textDecoration: 'none',
                    alignItems: 'center',

                    '&:hover': {
                        fontWeight: 'bold',
                        color: 'black',

                    },
                    alignSelf: 'center',
                }}
            >
                <Home fontSize={'small'}/>
            </Link>
            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join("/")}`;

                return last ? (
                    <Typography key={to} sx={{color: 'black', textDecoration: 'none'}} fontFamily={'var(--bfm-font)'}>
                        {capitalize(value)}
                    </Typography>
                ) : value === 'trackversions' ?

                    <Typography key={to} sx={{color: 'black', textDecoration: 'none'}} fontFamily={'var(--bfm-font)'}>
                        {capitalize(value)}
                    </Typography> :

                    (
                        <Link
                            component={RouterLink}
                            to={to}
                            key={to}
                            underline={'hover'}
                            sx={{

                                display: 'flex',
                                color: 'black',
                                textDecorationColor: 'black',
                                alignItems: 'center',
                                padding: 0,
                                fontFamily: 'var(--bfm-font)',

                                '&:hover': {
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                    color: 'black',
                                },
                            }}
                        >
                            {capitalize(value)}
                        </Link>
                    );
            })}
        </Breadcrumbs>
    );
}