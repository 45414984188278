import { useState } from "react";
import { ArtistService } from "../../Services/ArtistService";

export function useAddArtist() {
    const [status, setStatus] = useState({
        loading: false,
        error: null,
        success: false,
        responseData: null
    });

    const addArtist = async (data) => {
        setStatus(prevState => ({ ...prevState, loading: true, error: null, success: false }));
        try {
            const response = await ArtistService.create(data);
            if (response.status === 201) {
                setStatus({
                    loading: false,
                    error: null,
                    success: true,
                    responseData: response.data
                });
                return response.data;
            } else {
                throw new Error(`Failed to add artist: Status ${response.status}`);
            }
        } catch (error) {
            setStatus({
                loading: false,
                error: error.toString(),
                success: false,
                responseData: null
            });
            throw error;
        }
    };

    return { ...status, addArtist };
}
