import React, {useEffect, useState} from 'react';
import {
    Container,
    Button,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Select, FormControl, InputLabel, ButtonGroup
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from "@mui/material/MenuItem";
import {useModal} from "../Context/ModalContext";
import {useSnackbar} from "../Context/SnackbarContext";
import {useCreateAsset} from "../../Hooks/UploadHooks/useCreateAsset";
import {CustomLoadingButton} from "../CustomLoadingButton";

const assetTypes = {
    'Audio': ["Master", "Stem", "Instrumental", 'Acapella', "Other"],
    'Video': ["Music Video", "Clip", 'Other'],
    'Images': ["Album Art", "Artist Photo"],
    'Other': ["Other"]
};


export function AssetForm2({entity, handleRefresh}) {
    const [formFields, setFormFields] = useState([]);
    const {openModal, closeModal} = useModal();
    const {openSnackbar, closeSnackbar} = useSnackbar();
    const {addAsset, loading, error, success} = useCreateAsset();
    console.log(entity);

    const addFormField = () => {
        const newField = {
            assetType: '',
            assetSubtype: '',
            file: null
        };
        setFormFields([...formFields, newField]);
    };

    const handleFormChange = (index, event) => {
        const newFormFields = [...formFields];
        if (event.target.name === 'file') {
            newFormFields[index][event.target.name] = event.target.files[0];
        } else {
            newFormFields[index][event.target.name] = event.target.value;
        }
        setFormFields(newFormFields);
    };

    useEffect(() => {
        if (success) {
            openSnackbar('Asset added successfully');
            closeModal();
        } else if (error) {
            openSnackbar('Error adding asset');
        }

    }, [success, error]);


    const handleSubmit = async (event) => {

        event.preventDefault();
        for (const field of formFields) {
            openSnackbar('Processing form data');

            await addAsset(entity, field);
        }
        handleRefresh();


    };


    return (
        <Container maxWidth="sm">
            <Typography variant="h4">Upload Asset</Typography>
            <form onSubmit={handleSubmit}>
                {formFields.map((field, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>Asset Entry {index + 1}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            <FormControl fullWidth margin="normal">
                                <InputLabel>Asset Type</InputLabel>
                                <Select
                                    name="assetType"
                                    value={field.assetType}
                                    onChange={(e) => handleFormChange(index, e)}
                                    label="Asset Type"
                                >
                                    {Object.keys(assetTypes).map((type) => (
                                        <MenuItem key={type} value={type}>{type}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Asset Subtype</InputLabel>
                                <Select
                                    name="assetSubtype"
                                    value={field.assetSubtype}
                                    onChange={(e) => handleFormChange(index, e)}
                                    label="Asset Subtype"
                                    disabled={!field.assetType}
                                >
                                    {field.assetType ? assetTypes[field.assetType].map((subtype) => (
                                        <MenuItem key={subtype} value={subtype}>{subtype}</MenuItem>
                                    )) : []}
                                </Select>
                            </FormControl>

                            <input
                                accept="*/*"
                                style={{display: 'none'}}
                                id={`file-button-${index}`}
                                type="file"
                                name="file"
                                onChange={(e) => handleFormChange(index, e)}
                            />
                            <label htmlFor={`file-button-${index}`}>
                                <Button variant="contained" component="span">
                                    Select File
                                </Button>
                            </label>
                        </AccordionDetails>
                    </Accordion>
                ))}
                <ButtonGroup>
                    <Button type="button" onClick={addFormField} variant="contained" color="primary">

                        Add Asset
                    </Button>
                    <CustomLoadingButton loading={loading}/>
                    {/*<Button type="submit" variant="contained" color="primary">*/}
                    {/*    Submit*/}
                    {/*</Button>*/}
                </ButtonGroup>
            </form>
        </Container>
    );
}

