import {InfoPage} from "../../../ProductData";

export function TrackVersionInfo({data}) {

    const trackVersionSchema = [
        {label: 'Track Version', field: 'track_version', primary: true},
        {label: 'ISRC', field: 'isrc', primary: true},
        {label: 'Track Length', field: 'track_length_seconds', primary: true, type: 'track_length'},
        {label: 'Audio Language', field: 'audio_language', primary: true}];
    console.log(data)
    return (
        <>
            <InfoPage data={data} schema={trackVersionSchema} pageType={'Version'}/>
        </>)


}