import {Releases} from "../Releases/Releases";
import {Tracks} from "../Tracks/Tracks";
import {Artists} from "../Artists/Artists";
import {Products} from "../Products/Products";
import {Box, Container, Typography} from "@mui/material";
import {PageTabs} from "../../../components/Tabs/PageTabs";
import {MastersHome} from "./Home";
import {Tracks_2} from "../Tracks/Tracks_2";


export function TracksPage() {

    const tabs = [{label: 'Home', component: MastersHome, link: '/masters'}, {
        label: 'Artists',
        component: Artists,
        link: '/masters/artists'
    }, {
        label: 'Releases',
        component: Releases,
        link: '/masters/releases'
    }, {
        label: 'Products', component: Products, link: '/masters/products'
    },
        {
            label: 'Tracks',
            component: Tracks_2,
        }];


    return (
        <Container maxWidth={'100%'}>
            {/*<Box className={'hspace'}>*/}
            {/*    <Typography variant="h1" sx={{marginTop: "1%", marginBottom: "0%"}}>Tracks</Typography>*/}
            {/*</Box>*/}
           <Box marginTop={'1%'}/>
            <PageTabs tabs={tabs} defaultTab={4}/>
        </Container>
    )


}