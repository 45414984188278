import { MenuDropdown } from "../MenuDropdown";

const menuOptions = [
  { optionName: "Artists", link: "/masters/artists" },
  { optionName: "Releases", link: "/masters/releases" },
  { optionName: "Products", link: "/masters/products" },
  { optionName: "Tracks", link: "/masters/tracks" },
];

export function MastersDropdown({ menuHandler, menuAnchor, handleClose }) {
  return (
    <MenuDropdown
      menuName="Masters"
      menuOptions={menuOptions}
      menuHandler={menuHandler}
      menuAnchor={menuAnchor}
      handleClose={handleClose}

    />
  );
}
