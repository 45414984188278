import SaveIcon from "@mui/icons-material/Save";
import {LoadingButton} from "@mui/lab";

export function CustomLoadingButton({loading}) {

    return (<LoadingButton

        loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon/>}
                variant="outlined"
                type={'submit'}
    >
        Save
</LoadingButton>)

}