import RegularTable from "../../../components/Tables/RegularTable";
import Grid from "@mui/material/Unstable_Grid2";
import {TrackInfo} from "../Tracks/TrackInfo";
import {getSignedUrl} from "../../../components/AssetDirectory/FileNode";
import {useState} from "react";
import {useAudio} from '../../../components/Context/AudioContext'
import {ActionPanel} from "../../../components/ActionPanel";
import {useCreate} from "../../../Hooks/useAdd";
import {TrackVersionService} from "../../../Services/TrackVersionService";
import {useSnackbar} from "../../../components/Context/SnackbarContext";
import {useModal} from "../../../components/Context/ModalContext";
import {TrackVersionForm} from "../../../components/Forms/TrackVersionForm";
import {Stack} from "@mui/material";

export function TrackVersions({data, parentTrackId}) {
    const [signedUrl, setSignedUrl] = useState(null);
    const {isStopped, trackName, toggleAudio, isPlaying, audioUrl} = useAudio();
    const {success, error, loading, create} = useCreate(TrackVersionService)
    const {openSnackbar} = useSnackbar();
    const {openModal} = useModal();

    const fetchAndUseUrl = async (blob) => {
        try {
            const url = signedUrl || await getSignedUrl(blob)
            setSignedUrl(url)
            toggleAudio(url);


        } catch (error) {
            console.error('Error fetching signed URL:', error);
        }
    };

    function handlePlayPause(blob) {
        // event.stopPropagation();
        fetchAndUseUrl(blob);
    };


    const trackVersionSchema = [
        {label: 'ISRC', id: 'isrc', primary: true, type: 'link', linkEndpoint: 'trackversions'},
        {label: 'Track Version', id: 'track_version', primary: true},
        {label: 'Track Length', id: 'track_length_seconds', primary: true, type: 'track_length'},
        {label: 'Audio Language', id: 'audio_language', primary: true},
        {
            label: 'Has master uploaded',
            id: 'has_master',
            type: 'audio',
            primary: true,
            audioHandler: handlePlayPause
        }
    ];


    const handleCreate = () => {
        openModal(<TrackVersionForm currentData={data}
                                    parentTrackId={parentTrackId}
                                    mode={'create'}/>)

    }
    const actionPanelComp = <ActionPanel itemName={'Version'} handleCreate={handleCreate}/>
    const tableComp = <RegularTable data={data.track_versions}
                      schema={trackVersionSchema}
                      nestedRowName={'track_version'}
                      pageType={'Track Versions'}/>

    return {actionPanelComp, tableComp}






}